import * as React from 'react'
import styled from 'styled-components'
import { HttpStatusEnum } from 'common/enums/HttpStatusEnum'
import { ButtonCP } from 'common/components/button/ButtonCP'
import { RoutingHelper } from 'common/helpers/RoutingHelper'
import { HomeIconCP } from 'common/components/icons/HomeIconCP'
import { TextCP } from 'common/components/text/TextCP'
import { FallbackScreenContentCP } from 'common/components/fallback-screen-content/FallbackScreenContent'

/**
 * Pagina 404 - Pagina nao encontrada.
 *
 * @author renatofs
 * @author hjcostabr
 */
export function Error404Screen(): JSX.Element {
    return (
        <FallbackScreenContentCP
            status={HttpStatusEnum.NOT_FOUND}
            title={'Oops! Página não encontrada!'}
            bottomContent={
                <ButtonWrapperSCP>
                    <ButtonCP
                        type={'primary'}
                        onClick={() => RoutingHelper.historyReplace('login')}
                    >
                        <HomeIconCP/>
                        <TextCP text={'Voltar ao início'} style={{ marginLeft: 4 }}/>
                    </ButtonCP>
                </ButtonWrapperSCP>
            }
        />
    )
}

const ButtonWrapperSCP = styled.div`
    display: flex;
    justify-content: center;
`
