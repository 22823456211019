import { Typography } from 'antd'
import styled from 'config/theme/styledWithTheme'
import { ThemeConfig } from 'config/theme/ThemeConfig'
import { FontSizeTP } from 'config/theme/ThemeTypes'
import React from 'react'
import { TooltipCP } from 'common/components/tooltip/TooltipCP'

type SharedPropsTP = {
    size?: FontSizeTP,
    center?: boolean,
    color?: string,
    wrap?: boolean,
}

type AntPropsTP = {
    underline?: boolean,
    elipsis?: boolean,
    delete?: boolean,
    strong?: boolean,
    copyable?: boolean,
}

type CustomPropsTP = {
    text: string | number,
    onCopy?: () => void,
    style?: React.CSSProperties,
}

interface ITextCPProps extends SharedPropsTP, CustomPropsTP, AntPropsTP {
    tooltip?: JSX.Element | string
}

/**
 * COMPONENTE
 * Normaliza exbicao de texto (wrapper do componente de tipografia do antd).
 */
export function TextCP(props: ITextCPProps): JSX.Element {

    function getTypography(): JSX.Element {
        return (
            <Typography.Text
                underline={props.underline}
                ellipsis={!!props.elipsis}
                delete={props.delete}
                strong={props.strong}
                copyable={!!props.onCopy ? { onCopy: props.onCopy } : !!props.copyable}
            >
                {props.text}
            </Typography.Text>
        )
    }

    return (
        <WrapperSCP
            wrap={props.wrap}
            color={props.color}
            size={props.size}
            center={!!props.center}
            style={props.style}
        >
            {
                props.tooltip
                    ?
                    <TooltipCP text={props.tooltip} placement={'topRight'}>
                        { getTypography() }
                    </TooltipCP>
                    :
                    getTypography()
            }
        </WrapperSCP>
    )
}

const WrapperSCP = styled.div<SharedPropsTP>`

    overflow: hidden;
    text-overflow: ellipsis;
    overflow-wrap: normal;
    white-space: ${props => (props.wrap === false ? 'nowrap' : 'normal')};
    display: flex;

    .ant-typography {

        flex: 1;
        color:  ${props => (!!props.color ? props.color : 'unset')};
        font-size: ${props => ThemeConfig.fontSizes[props.size ?? 'normal']};
        text-align: ${props => (!!props.center ? 'center' : 'inherit')};

        strong {
            font-weight: 700;
        }
    }
`
