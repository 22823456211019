import * as React from 'react'
import { LoadingOverlayCP } from 'common/components/loaders/loading-overlay/LoadingOverlayCP'
import { ContentCP } from 'common/components/screen-layout/content/ContentCP'

/**
 * TELA de carregamento:
 * Serve como 'placeholder' durante transicoes de tela ou carregamentos.
 *
 * @todo: Fazer ficar bonito
 *
 * @author hjcostabr
 */
export function LoadingScreen(): JSX.Element {
    return (
        <ContentCP>
            <LoadingOverlayCP show={true}/>
        </ContentCP>
    )
}
