import React, { useEffect, useState } from 'react'
import { LayoutCP } from 'common/components/screen-layout/layout/LayoutCP'
import { ThemeConfig } from 'config/theme/ThemeConfig'
import { SiderCP } from 'common/components/screen-layout/sider/SiderCP'
import { styled } from 'config/theme/styledWithTheme'
import { NotificationHelper } from 'common/helpers/NotificationHelper'
import { NewDocumentWizardCP } from 'modules/document-signature/components/new-document-wizard/NewDocumentWizardCP'
import { NewDocumentConfirmModalCP } from 'modules/document-signature/components/new-document-confirm-modal/NewDocumentConfirmModalCP'
import { DocumentContentFormValidator } from 'modules/document-signature/components/new-document-wizard/inner/DocumentContentFormValidator'
import { IFormStateManager } from 'common/form-state-manager/interfaces/IFormStateManager'
import { AssigneesContentFormValidator } from 'modules/document-signature/components/new-document-wizard/inner/AssigneesContentFormValidator'
import { useRequest } from 'common/request-manager/use-request/UseRequest'
import { RequestUtils } from 'common/request-manager/RequestUtils'
import { ICreateDocumentRequestDTO } from 'modules/document/services/dtos/request/ICreateDocumentRequestDTO'
import { AppStateUtils } from 'common/utils/AppStateUtils'
import { DocumentRequests } from 'modules/document/services/DocumentRequests'
import { DocumentTypeEnum } from 'modules/document/enums/DocumentTypeEnum'
import { RoutingHelper } from 'common/helpers/RoutingHelper'
import { DocumentRoutes } from 'modules/document/routers/DocumentRoutes'
import { useScreenSize } from 'common/responsiveness/use-screen-size/UseScreenSize'
import { NewDocumentScreenContentCP } from 'modules/document-signature/components/new-document-screen-content/NewDocumentScreenContentCP'

/**
 * TELA: Criar novo documento para assinatura.
 */
export function NewDocumentScreen(): JSX.Element | null {

    const screenSize = useScreenSize()

    const [file, setFile] = useState<File>()
    const [isConfirmModalVisible, setIsConfirmModalVisible] = useState<boolean>(false)
    const [createDocumentRequestDTO, setCreateDocumentRequestDTO] = useState<ICreateDocumentRequestDTO>()

    const createDocumentRequest = useRequest()
    useEffect(onCreateDocumentRequestChange, [createDocumentRequest.isAwaiting])

    /**
     * Callback quando termina o wizard dos dados
     */
    function onCompleteWizard(documentFormStateManager: IFormStateManager<DocumentContentFormValidator>, assigneeFormStateManager: IFormStateManager<AssigneesContentFormValidator>): void {

        if (!file) {
            NotificationHelper.error('Ops!', 'Faça o upload do arquivo')
            return
        }

        const documentFormValues = documentFormStateManager.getFormValues()
        const assigneeFormValues = assigneeFormStateManager.getFormValues()
        if (!documentFormValues || !assigneeFormValues)
            return

        // Cada etapa ja fez a validacao do form validator, entao precisamos apenas criar o DTO
        const dto: ICreateDocumentRequestDTO = {
            personCodeResponsible: AppStateUtils.getLoggedUserData()!.user.code,
            documentName: documentFormValues.name,
            securityLevel: documentFormValues.signatureSecLevel,
            hasSignOrder: assigneeFormValues.isOrdered,
            assignees: assigneeFormValues.assignees,
            signatureMessage: documentFormValues.message,
        }

        // Guardo o DTO em estado para mostrar no confirmModal
        setCreateDocumentRequestDTO(dto)

        // Abre confirm modal
        setIsConfirmModalVisible(true)
    }

    /**
     * Confirma criacao de documento
     */
    async function createDocument(): Promise<void> {

        if (!createDocumentRequestDTO || !file)
            return

        createDocumentRequest.runRequest(DocumentRequests.createDocumentConfig(DocumentTypeEnum.ELECTRONIC_SIGNATURE, file, createDocumentRequestDTO))
    }

    /**
     * Apos reotorno da api de salvar pessoa
     */
    function onCreateDocumentRequestChange(): void {

        if (!RequestUtils.isValidRequestReturn(createDocumentRequest, 'Ocorreu algun erro ao criar documento'))
            return

        NotificationHelper.success('Documento criado com sucesso')
        RoutingHelper.historyPush(DocumentRoutes.ROOT)
    }

    return (
        <>
            <LayoutCP
                sider={
                    <>
                        {
                            !screenSize.smd &&
                            <SiderCP
                                width={375}
                                bgColor={ThemeConfig.secondMenuBg}
                                scrollable={true}
                            >
                                <SiderWrapperSCP>
                                    <NewDocumentWizardCP onDone={onCompleteWizard}/>
                                </SiderWrapperSCP>
                            </SiderCP>
                        }
                    </>
                }
                content={
                    <NewDocumentScreenContentCP
                        file={file}
                        onUploadFile={setFile}
                        onCompleteWizard={onCompleteWizard}
                    />
                }
            />

            <NewDocumentConfirmModalCP
                visible={isConfirmModalVisible}
                onConfirm={createDocument}
                onCancel={() => setIsConfirmModalVisible(false)}
                loading={createDocumentRequest.isAwaiting}
                document={createDocumentRequestDTO}
            />
        </>
    )
}

const SiderWrapperSCP = styled.div`
    padding: 5px;
`
