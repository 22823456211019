/**
 * @author Stella
 * @author hjcostabr
 */
export enum InputMaskTypeEnum {
    DATE = 'date',
    CPF = 'cpf',
    CNPJ = 'cnpj',
    PHONE = 'phone',
    CEP = 'cep',
    HOUR = 'hour',
    MONEY = 'money',
}
