import React from 'react'
import styled from 'config/theme/styledWithTheme'

type _ScpPropsTP = {
    marginRight?: number,
    marginLeft?: number,
    marginTop?: number,
    marginBottom?: number,
}

interface ILogoCPprops extends _ScpPropsTP {
    height: number
    type: 'logo' | 'icon'
}

/**
 * COMPONENTE: Logo.
 *
 * @author hjcostabr
 */
export function LogoCP(props: ILogoCPprops): JSX.Element {

    const logoSrc = `${process.env.PUBLIC_URL}/logos/${props.type === 'logo' ? 'logo.png' : 'icone-branco.png'}`

    return (
        <WrapperSCP
            marginTop={props.marginTop ?? 20}
            marginRight={props.marginRight ?? 0}
            marginBottom={props.marginBottom ?? 20}
            marginLeft={props.marginLeft ?? 0}
        >
            <img
                src={logoSrc}
                alt={'logo'}
                height={props.height}
                width={'auto'}
            />
        </WrapperSCP>
    )
}

const WrapperSCP = styled.div<_ScpPropsTP>`
    margin: ${props => `${props.marginTop!}px ${props.marginRight!}px ${props.marginBottom!}px ${props.marginLeft!}px`};
`
