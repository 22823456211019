/**
 * MOMENT Js
 * Parametros para adequacao da exibicao dos valores da biblioteca moment js no idioma portugues brasileiro.
 * NOTE: Para uso no utilitario de Datas
 *
 * @see DateUtils
 */
export const momentPtBrLocale: any = {
    months: ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'],
    monthsShort: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'],
    monthsParseExact: true,
    weekdays: ['Domingo', 'Segunda', 'Terça', 'Quarta', 'Quinta', 'Sexta', 'Sábado'],
    weekdaysShort: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sáb'],
    weekdaysMin: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sáb'],
    weekdaysParseExact: true,
    longDateFormat: {
        /* eslint-disable @typescript-eslint/naming-convention */
        LT: 'HH:mm',
        LTS: 'HH:mm:ss',
        L: 'DD/MM/YYYY',
        LL: 'D MMMM YYYY',
        LLL: 'D MMMM YYYY HH:mm',
        LLLL: 'dddd D MMMM YYYY HH:mm',
        /* eslint-enable @typescript-eslint/naming-convention */
    },
    calendar: {
        sameDay: '[Hoje às] LT',
        nextDay: '[Amanhã às] LT',
        nextWeek: 'dddd [às] LT',
        lastDay: '[Ontem às] LT',
        lastWeek: 'dddd [às] LT',
        sameElse: 'L'
    },
    relativeTime: {
        future: 'até %s',
        past: 'desde %s',
        s: 'segundos',
        m: 'um minuto',
        mm: '%d minutos',
        h: 'une hora',
        hh: '%d horas',
        d: 'un dia',
        dd: '%d dias',
        /* eslint-disable @typescript-eslint/naming-convention */
        M: 'un mês',
        MM: '%d meses',
        /* eslint-enable @typescript-eslint/naming-convention */
        y: 'un ano',
        yy: '%d anos'
    },
    dayOfMonthOrdinalParse: /\d{1,2}/,
    ordinal(number: any) {
        return number
    },
    meridiemParse: /am|pm/,
    isPM(input: string) {
        return input.startsWith('M')
    },
    // In case the meridiem units are not separated around 12, then implement
    // this function (look at locale/id.js for an example).
    // meridiemHour : function (hour, meridiem) {
    //     return /* 0-23 hour, given meridiem token and hour 1-12 */ ;
    // },
    meridiem(hours: number/*, minutes, isLower*/) {
        return hours < 12 ? 'am' : 'pm'
    },
    week: {
        dow: 1, // Monday is the first day of the week.
        doy: 4  // Used to determine first week of the year.
    }
} as const
