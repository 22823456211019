import React from 'react'
import { IDocumentResponseDTO } from 'modules/document/services/dtos/response/IDocumentResponseDTO'
import { DocumentStatusCP } from 'modules/document/components/document-status/DocumentStatusCP'
import { styled } from 'config/theme/styledWithTheme'
import { DescriptionCP } from 'common/components/description/description/DescriptionCP'
import { DescriptionItemCP } from 'common/components/description/description-item/DescriptionItemCP'
import { DateUtils } from 'common/utils/date/DateUtils'
import { DateFormatEnum } from 'common/enums/DateFormatEnum'

type _ScpPropsTP = {
    marginRight?: number,
    marginLeft?: number,
    marginTop?: number,
    marginBottom?: number,
}

interface IDocumentDetailsCPProps extends _ScpPropsTP{
    document: IDocumentResponseDTO
}

/**
 * Detalhes de um documento.
 */
export function DocumentDetailsCP(props: IDocumentDetailsCPProps): JSX.Element {

    return (
        <DescriptionContentSCP
            marginTop={props.marginTop ?? 0}
            marginRight={props.marginRight ?? 0}
            marginBottom={props.marginBottom ?? 20}
            marginLeft={props.marginLeft ?? 0}
        >
            <DescriptionCP>
                <DescriptionItemCP label={'Nome'}>
                    {props.document.name}
                </DescriptionItemCP>
                <DescriptionItemCP label={'Status'}>
                    <DocumentStatusCP status={props.document.status}/>
                </DescriptionItemCP>
                <DescriptionItemCP label={'Criado em'}>
                    { DateUtils.getFormatted(props.document.createDate, DateFormatEnum.BR_WITH_TIME_H_M) }
                </DescriptionItemCP>
                <DescriptionItemCP label={'Criado por'}>
                    {props.document.ownerPerson.name}
                </DescriptionItemCP>
            </DescriptionCP>
        </DescriptionContentSCP>
    )
}

const DescriptionContentSCP = styled.div<_ScpPropsTP>`
  
  margin: ${props => `${props.marginTop!}px ${props.marginRight!}px ${props.marginBottom!}px ${props.marginLeft!}px`};

  .ant-descriptions-row > td {
      padding-bottom: 0px !important;
  }
`
