import { InputCP } from 'common/components/form-fields/input/InputCP'
import { ZipCodeSearchInputCP } from 'common/components/form-fields/zip-code-search-input/ZipCodeSearchInputCP'
import { ColumnCP } from 'common/components/grid/ColumnCP'
import { RowCP } from 'common/components/grid/RowCP'
import { IFormStateManager } from 'common/form-state-manager/interfaces/IFormStateManager'
import { useScreenSize } from 'common/responsiveness/use-screen-size/UseScreenSize'
import { IAddressSaveRequestDTO } from 'modules/person/services/dtos/request/IAddressSaveRequestDTO'
import React, { useState } from 'react'
import { FormModel } from 'common/form-state-manager/classes/FormModel'
import { UfSelectCP } from 'common/components/form-fields/uf-select/UfSelectCP'
import { AddressTypeSelectCP } from 'common/components/form-fields/address-type-select/AddressTypeSelectCP'

interface IAddressPanelCPProps<FModelTP extends FormModel> {
    formStateManager?: IFormStateManager<FModelTP>
}

/**
 * COMPONENTE
 * Campos de endereco para paineis de formularios de cadastro.
 */
export function AddressPanelCP<FModelTP extends (FormModel & IAddressSaveRequestDTO)>(props: IAddressPanelCPProps<FModelTP>): JSX.Element {

    const screenSize = useScreenSize()
    const [isAwaiting, setIsAwaiting] = useState<boolean>(false)

    return (
        <>
            <RowCP spaceBetweenCols={16}>
                <ColumnCP size={screenSize.smd ? 24 : 12}>
                    <ZipCodeSearchInputCP
                        formStateManager={props.formStateManager}
                        fieldName={'zipCode'}
                        onSearchStart={() => setIsAwaiting(true)}
                        onSearchEnd={() => setIsAwaiting(false)}
                    />
                </ColumnCP>
                <ColumnCP size={screenSize.smd ? 24 : 12}>
                    <AddressTypeSelectCP
                        loading={isAwaiting}
                        disabled={isAwaiting}
                        formStateManager={props.formStateManager}
                        fieldName={'addressType'}
                    />
                </ColumnCP>
            </RowCP>

            <RowCP spaceBetweenCols={16}>

                <ColumnCP size={screenSize.smd ? 24 : 18}>
                    <InputCP
                        label={'Rua'}
                        loading={isAwaiting}
                        disabled={isAwaiting}
                        formStateManager={props.formStateManager}
                        fieldName={'street'}
                    />
                </ColumnCP>
                <ColumnCP size={screenSize.smd ? 12 : 6}>
                    <InputCP
                        label={'Número'}
                        loading={isAwaiting}
                        disabled={isAwaiting}
                        formStateManager={props.formStateManager}
                        fieldName={'number'}
                        type={'number'}
                        min={1}
                    />
                </ColumnCP>
                <ColumnCP size={screenSize.smd ? 24 : 18}>
                    <InputCP
                        label={'Bairro'}
                        loading={isAwaiting}
                        disabled={isAwaiting}
                        formStateManager={props.formStateManager}
                        fieldName={'neighborhood'}
                    />
                </ColumnCP>
                <ColumnCP size={screenSize.smd ? 12 : 6}>
                    <InputCP
                        label={'Complemento'}
                        loading={isAwaiting}
                        disabled={isAwaiting}
                        formStateManager={props.formStateManager}
                        fieldName={'complement'}
                    />
                </ColumnCP>
                <ColumnCP size={screenSize.smd ? 16 : 18}>
                    <InputCP
                        label={'Cidade'}
                        loading={isAwaiting}
                        disabled={isAwaiting}
                        formStateManager={props.formStateManager}
                        fieldName={'city'}
                    />
                </ColumnCP>
                <ColumnCP size={screenSize.smd ? 8 : 6}>
                    <UfSelectCP
                        loading={isAwaiting}
                        disabled={isAwaiting}
                        formStateManager={props.formStateManager}
                        fieldName={'state'}
                    />
                </ColumnCP>
            </RowCP>
        </>
    )
}
