import React from 'react'
import styled from 'config/theme/styledWithTheme'
import { TextCP } from 'common/components/text/TextCP'
import { UserIconCP } from 'common/components/icons/UserIconCP'
import { DocumentUtils } from 'modules/document/utils/DocumentUtils'
import { IDocumentAssigneeResponseDTO } from 'modules/document/services/dtos/response/IDocumentAssigneeResponseDTO'
import { DocumentStatusEnum } from 'modules/document/enums/DocumentStatusEnum'

interface ISignDocumentHeaderBarCPProps {
    assignees: IDocumentAssigneeResponseDTO[]
    showIcon: boolean
}

/**
 * COMPONENTE que exibie o total de signatários e o total que já assinaram.
 */
export function TotalAssigneesAndSignedCP(props: ISignDocumentHeaderBarCPProps): JSX.Element {

    return (
        <WrapperSCP>
            {
                props.showIcon &&
                <UserIconCP color={'#FFF'} size={15}/>
            }
            <TextCP
                tooltip={'Total de pessoas que já assinaram o documento'}
                text={DocumentUtils.getDocumentTotalPersonSignedOrNot(props.assignees, DocumentStatusEnum.SIGNED)}
            />
            <TextCP text={' / '}/>
            <TextCP
                tooltip={'Total de signatários'}
                text={props.assignees.length}
            />
        </WrapperSCP>
    )
}

const WrapperSCP = styled.div`

  display: flex;
  justify-content: flex-end;
  align-items: center;
  
  .ant-typography {
    margin-left: 5px;
  }

`
