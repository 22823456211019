import React from 'react'
import { ReduxHelper } from 'common/redux/helpers/ReduxHelper'
import { ConfigProvider } from 'antd'
import { EmptyCP } from 'common/components/empty/EmptyCP'
import { ITheme } from 'config/theme/ITheme'
import { ThemeProvider } from 'config/theme/styledWithTheme'
import { ThemeConfig } from 'config/theme/ThemeConfig'
import { PublicRouter } from 'modules/app/routers/PublicRouter'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import { PersistGate } from 'redux-persist/integration/react'
import { LoadingOverlayCP } from 'common/components/loaders/loading-overlay/LoadingOverlayCP'

/**
 * Componente inicial do sistema.
 *
 * @todo: Atualizar react
 *
 * @author Lucas Rosa
 * @author hjcostabr
 */
export function App(): JSX.Element {
    return (
        <Provider store={ReduxHelper.getInstance().store}>
            <PersistGate loading={<LoadingOverlayCP show={true}/>} persistor={ReduxHelper.getInstance().persistor}>
                <ConfigProvider renderEmpty={() => <EmptyCP/>}>
                    <ThemeProvider theme={ThemeConfig as ITheme}>
                        <BrowserRouter>
                            <PublicRouter/>
                        </BrowserRouter>
                    </ThemeProvider>
                </ConfigProvider>
            </PersistGate>
        </Provider>
    )
}
