import React from 'react'
import { Tree } from 'antd'
import { TreeNodeNormal, AntTreeNodeSelectedEvent } from 'antd/lib/tree/Tree'

// eslint-disable-next-line @typescript-eslint/naming-convention
const { DirectoryTree } = Tree

interface IDirectoryTreeCPProps {
    data: TreeNodeNormal[]
    expandedKeys: string[]
}

/**
 * COMPONENT de arvore de diretorio.
 */
export function DirectoryTreeCP(props: IDirectoryTreeCPProps): JSX.Element {

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    function onSelect(keys: string[], e: AntTreeNodeSelectedEvent): void {
        // console.log('Trigger Select', keys, event)
    }

    return (
        <DirectoryTree
            expandedKeys={['root']}
            onSelect={onSelect}
            treeData={props.data}
        />
    )
}
