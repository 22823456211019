import React, { useEffect, useState } from 'react'
import { Avatar } from 'antd'
import { StringUtils } from 'common/utils/StringUtils'
import { TextCP } from 'common/components/text/TextCP'

export interface IAvatarCPProps {
    size: 'large' | 'small' | 'default' | number
    src?: string
    name?: string | number
    backgroundColor?: string
    border?: string
}

/**
 * Componente para renderizar imagem de perfil:
 * Caso tenha passado imagem e seja valida, mostra imagem;
 * Caso contrario, se passou nome mostra as iniciais;
 * Caso contrario, mostra imagem padrao;
 *
 * @author Kiara Martins
 * @author renatofs
 * @author hjcostabr
 */
export function AvatarCP(props: IAvatarCPProps): JSX.Element {

    const [isValidSrc, setIsValidSrc] = useState<boolean>(!!props.src)
    const [initials, setInitials] = useState<string | number>('')

    useEffect(_setInitials, [props.name])

    function _setInitials(): void {
        setInitials(((typeof props.name === 'string') ? StringUtils.getFirstAndLastInitials(props.name) : props.name) ?? '')
    }

    /** Evento o link da imagem seja invalido. */
    function onError(): true {
        setIsValidSrc(false)
        _setInitials()
        return true
    }

    return (
        <>
            {
                isValidSrc &&
                <Avatar
                    src={props.src}
                    size={props.size}
                    shape={'circle'}
                    onError={onError}
                />
            }

            {
                (!isValidSrc && props.name) &&
                <Avatar
                    size={props.size}
                    style={{ backgroundColor: props.backgroundColor, border: props.border }}
                    shape={'circle'}
                >
                    <TextCP text={initials}/>
                </Avatar>
            }

            {
                (!isValidSrc && !props.name) &&
                <Avatar
                    src={`${process.env.PUBLIC_URL}/default-avatar-1.png`}
                    size={props.size}
                    shape={'circle'}
                />
            }
        </>
    )
}
