import React from 'react'
import styled from 'styled-components'
import { IIconProps } from './IIconProps'

export interface ICustomIconContainerICPProps extends IIconProps {
    children: JSX.Element
}

/**
 * COMPONENT
 * Wrapper generico usado itnernamente por componentes do tipo 'icone'
 * customizados: Envolve a imagem do icone ajustando seu tamanho.
 *
 * @author hjcostabr
 */
export const CustomIconContainerICP = (props: ICustomIconContainerICPProps): JSX.Element =>
    <IconContainerSCP {...props}/>

const IconContainerSCP = styled.div<IIconProps>`

    height: ${props => props.size}px;
    width: ${props => props.size}px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap

    svg,
    img {
        width: 100%;
        height: auto;
    }
`
