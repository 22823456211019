import { OrFunctionTP } from 'common/types/OrFunctionTP'
import { OrNullishTP } from 'common/types/OrNullishTP'
import { OrUndefTP } from 'common/types/OrUndefTP'
import { TotalWithFlagTP } from 'common/types/TotalWithFlagTP'
import * as _ from 'lodash'

/**
 * UTILS
 * Encapsula logica auxiliar para uso geral.
 *
 * @author hjcostabr
 */
export const SystemUtils = {

    async sleep(time: number): Promise<void> {
        await new Promise(resolve => setTimeout(() => resolve(), time))
    },

    /**
     * Retorna 01 determinado valor se 01 determinada condicao for verdadeira. Caso contrario retorna
     * 01 valor padrao (que pode ser indefinido).
     */
    nvl<ValueTP = any>(condition: boolean, value: OrNullishTP<OrFunctionTP<ValueTP>>, defaultValue?: ValueTP): OrUndefTP<ValueTP> {
        let valueToReturn: any
        if (condition)
            valueToReturn = (typeof value === 'function') ? (value as Function)() : value
        return valueToReturn ?? defaultValue
    },

    isEmpty(value: unknown): boolean {
        return ((typeof value !== 'object' && !value)
            || (typeof value === 'object' && _.isEmpty(value))
        )
    },

    /**
     * Retorna 01 contagem + 01 flag setado caso o total seja 0:
     * Incrementa o total se o flag for verdadeiro. Decrementa, caso contrario;
     */
    getTotalWithTrueOnZero(currentCount: number, isTrue: boolean): TotalWithFlagTP {
        const total = (currentCount + (isTrue ? 1 : -1))
        return { total, isTrue: (total > 0) }
    },
}
