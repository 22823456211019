/**
 * ENUM
 * Determina formatos de exibicao de data/hora utilizados no sistema.
 */
export enum DateFormatEnum {

    BR_WITH_TIME_H_M = 'DD/MM/YYYY - HH:mm',
    BR_WITH_TIME_H_M_S = 'DD/MM/YYYY [às] HH:mm:ss',
    BR_WITHOUT_TIME = 'DD/MM/YYYY',
    BR_DAY_MONTH = 'DD/MM',
    BR_MONTH_YEAR = 'MM/YYYY',
    BR_EXT_MONTH_YEAR = 'MMM/YYYY',

    US_WITHOUT_TIME = 'YYYY-MM-DD',
    US_WITH_TIME_H_M = 'YYYY-MM-DD - HH:mm',
    US_WITH_TIME = 'YYYY-MM-DD HH:mm:ss',

    MONTH = 'MMMM',

    TIME_H_M = 'HH:mm',
    TIME_H_M_S = 'HH:mm:ss',
    YEAR_4 = 'YYYY',
}
