import { InputCP } from 'common/components/form-fields/input/InputCP'
import { ColumnCP } from 'common/components/grid/ColumnCP'
import { RowCP } from 'common/components/grid/RowCP'
import { InputMaskTypeEnum } from 'common/enums/InputMaskTypeEnum'
import { useScreenSize } from 'common/responsiveness/use-screen-size/UseScreenSize'
import { GenderSelectorCP } from 'modules/person/components/gender-selector/GenderSelectorCP'
import React from 'react'
import { InputPhoneCP } from 'common/components/form-fields/input-phone/InputPhoneCP'
import { NacionalitySelectorCP } from '../../nacionality-selector/NacionalitySelectorCP'
import { PersonTypeSelectorCP } from '../../person-type-selector/PersonTypeSelectorCP'
import { IFormStateManager } from 'common/form-state-manager/interfaces/IFormStateManager'
import { PersonFormModel } from 'modules/person/models/PersonFormModel'
import { PersonTypeEnum } from 'modules/person/enums/PersonTypeEnum'

interface IPersonDataContentICPProps {
    personType?: PersonTypeEnum
    formStateManager?: IFormStateManager<PersonFormModel>
    hidePassword?: boolean
}

/**
 * COMPONENTE
 * Painel de informacoes pessoais no cadastro de uma pessoa.
 *
 * @todo 22/10/2020 - Implementar campo de CNPJ e alterar os campos de CPF/CNPJ de acordo com o PersonType
 */
export function PersonDataContentICP(props: IPersonDataContentICPProps): JSX.Element {
    const screenSize = useScreenSize()

    return (
        <>
            <RowCP>
                {
                    (!!props.personType && props.personType === PersonTypeEnum.PJ) ?
                        <ColumnCP size={24}>
                            <InputCP
                                fontSize={'extraLarge'}
                                label={'Razão Social'}
                                fieldName={'name'}
                                formStateManager={props.formStateManager}
                                required={true}
                            />
                        </ColumnCP>
                        :
                        <ColumnCP size={24}>
                            <InputCP
                                fontSize={'extraLarge'}
                                label={'Nome'}
                                fieldName={'name'}
                                formStateManager={props.formStateManager}
                                required={true}
                            />
                        </ColumnCP>
                }
            </RowCP>

            <RowCP spaceBetweenCols={16}>

                <ColumnCP size={screenSize.smd ? 24 : 12}>
                    <PersonTypeSelectorCP
                        fieldName={'personType'}
                        formStateManager={props.formStateManager}
                        required={true}
                        disabled={true}
                    />
                </ColumnCP>

                {
                    (!!props.personType && props.personType === PersonTypeEnum.PJ) ?
                        <ColumnCP size={screenSize.smd ? 24 : 12}>
                            <InputCP
                                label={'CNPJ'}
                                mask={InputMaskTypeEnum.CNPJ}
                                fieldName={'cpfCnpj'}
                                formStateManager={props.formStateManager}
                                required={true}
                                disabled={true}
                            />
                        </ColumnCP>
                        :
                        <ColumnCP size={screenSize.smd ? 24 : 12}>
                            <InputCP
                                label={'CPF'}
                                mask={InputMaskTypeEnum.CPF}
                                fieldName={'cpfCnpj'}
                                formStateManager={props.formStateManager}
                                required={true}
                                disabled={true}
                            />
                        </ColumnCP>
                }

            </RowCP>

            <RowCP spaceBetweenCols={16}>
                <ColumnCP size={props.personType === PersonTypeEnum.PF ? 12 : 24}>
                    <InputCP
                        label={'E-mail'}
                        fieldName={'email'}
                        formStateManager={props.formStateManager}
                        required={true}
                        type={'email'}
                    />
                </ColumnCP>

                {
                    (!!props.personType && props.personType === PersonTypeEnum.PF) &&
                    <ColumnCP size={screenSize.smd ? 24 : 12}>
                        <InputCP
                            label={'Data de Nascimento'}
                            mask={InputMaskTypeEnum.DATE}
                            fieldName={'birthDate'}
                            formStateManager={props.formStateManager}
                            required={false}
                        />
                    </ColumnCP>
                }

            </RowCP>

            <RowCP spaceBetweenCols={16}>

                <ColumnCP size={12}>
                    <InputPhoneCP
                        label={'Celular'}
                        fieldName={'cellPhone'}
                        formStateManager={props.formStateManager}
                        required={false}
                    />
                </ColumnCP>

                <ColumnCP size={12}>
                    <InputPhoneCP
                        label={'Telefone Fixo'}
                        fieldName={'homePhone'}
                        formStateManager={props.formStateManager}
                        required={false}
                    />
                </ColumnCP>
            </RowCP>

            {
                (!!props.personType && props.personType === PersonTypeEnum.PF) &&
                <RowCP spaceBetweenCols={16}>
                    <ColumnCP size={screenSize.smd ? 24 : 12}>
                        <NacionalitySelectorCP
                            fieldName={'nacionality'}
                            formStateManager={props.formStateManager}
                            required={false}
                        />
                    </ColumnCP>

                    <ColumnCP size={screenSize.smd ? 24 : 12}>
                        <GenderSelectorCP
                            fieldName={'gender'}
                            formStateManager={props.formStateManager}
                            required={false}
                        />
                    </ColumnCP>
                </RowCP>
            }
        </>
    )
}
