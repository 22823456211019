import { MimeTypeEnum } from 'common/enums/MimeTypeEnum'
import { MimeTypeUtils } from 'common/utils/MimeTypeUtils'

/**
 * UTILITARIOS para manipular arquivos.
 *
 * @author hjcostabr
 */
export const FileUtils = {

    /** Avalia metadados disponiveis para determinar se tipo de 01 arquivo eh CSV. */
    isCsv(file: Blob, filename?: string): boolean {

        const fileType = file?.type
        if (!!fileType && MimeTypeUtils.isCsvMimeType(fileType))
            return true

        if (!!filename)
            return (fileType === MimeTypeEnum.TEXT && /(.*)\.csv$/.test(filename))

        return false
    },
}
