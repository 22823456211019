import React, { useState, useEffect } from 'react'
import { CardCP } from 'common/components/card/CardCP'
import { TableCP } from 'common/components/table/TableCP'
import { NotificationTableUtils } from 'modules/notification/components/notification-table/inner/NotificationTableUtils'
import { useRequest } from 'common/request-manager/use-request/UseRequest'
import { IGenericListResponseDTO } from 'common/dtos/responses/IGenericListResponseDTO'
import { RequestUtils } from 'common/request-manager/RequestUtils'
import { INotificationResponseDTO } from 'modules/notification/services/dtos/response/INotificationResponseDTO'
import { NotificationRequests } from 'modules/notification/services/NotificationRequests'
import { DocumentDrawerCP } from 'modules/document/components/document-drawer/DocumentDrawerCP'

/**
 * TELA de listagem de documentos.
 */
export function NotificationTableCP(): JSX.Element {

    const [selectedDocumentCode, setSelectedDocumentCode] = useState<number>()
    const [isDocumentDrawerVisible, setIsDrawerVisible] = useState<boolean>(false)

    useEffect(initialize, [])

    const searchNotificationsRequest = useRequest<IGenericListResponseDTO<INotificationResponseDTO>>()
    useEffect(onSearchNotificationsRequestChange, [searchNotificationsRequest.isAwaiting])

    const markAsReadRequest = useRequest('none')
    useEffect(onMarkAsReadRequestChange, [markAsReadRequest.isAwaiting])

    /**
     * Inicializa a tela buscando os documentos
     */
    function initialize(): void {
        searchNotificationsRequest.runRequest(NotificationRequests.searchConfig())
    }

    /**
     * Retorno da requisicao que busca os documentos
     */
    function onSearchNotificationsRequestChange(): void {

        if (!RequestUtils.isValidRequestReturn(searchNotificationsRequest, 'Erro obter notificacoes'))
            return

    }

    /**
     * Prepara para abrir drawer de detalhes
     */
    function prepareToOpenDocumentDrawer(notificationDto: INotificationResponseDTO): void {
        setSelectedDocumentCode(notificationDto.payload?.documentCode)
        setIsDrawerVisible(true)
    }

    /**
     * Marca como lida uma notificacao.
     */
    function markAsRead(code: number): void {
        markAsReadRequest.runRequest(NotificationRequests.markAsReadConfig(code))
    }

    /**
     * Retorno da requisicao que marca uma notificacao como lida
     */
    function onMarkAsReadRequestChange(): void {

        if (!RequestUtils.isValidRequestReturn(searchNotificationsRequest, 'Erro marcar notificacao como lida'))
            return

        // Recarrega lista de notificacoes
        initialize()
    }

    return (
        <>
            <CardCP
                title={'Mensagens'}
                innerSpacing={'small'}
            >
                <TableCP<INotificationResponseDTO>
                    columns={
                        NotificationTableUtils.getTableColumnsConfig(
                            prepareToOpenDocumentDrawer,
                            markAsRead
                        )
                    }
                    data={searchNotificationsRequest.responseData?.list}
                    loading={searchNotificationsRequest.isAwaiting || markAsReadRequest.isAwaiting}
                />

            </CardCP>

            <DocumentDrawerCP
                visible={isDocumentDrawerVisible}
                documentCode={selectedDocumentCode}
                onClose={() => setIsDrawerVisible(false)}
            />
        </>
    )
}
