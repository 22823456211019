import { MimeTypeEnum } from 'common/enums/MimeTypeEnum'

/**
 * UTILITARIOS para lidar com Mime-Types de arquivos.
 *
 * @author hjcostabr
 */
export const MimeTypeUtils = {

    isCsvMimeType(mimeType: string): boolean {
        return ([
            MimeTypeEnum.CSV_STANDARD,
            MimeTypeEnum.CSV_ALTERNATIVE_1,
            MimeTypeEnum.CSV_ALTERNATIVE_2,
            MimeTypeEnum.CSV_ALTERNATIVE_3,
            MimeTypeEnum.CSV_ALTERNATIVE_4,
            MimeTypeEnum.CSV_MICROSOFT,
        ] as string[])
            .includes(mimeType)
    }
}
