import { UserMainLayoutCP } from 'modules/app/components/user-main-layout/UserMainLayoutCP'
import { AuthActions } from 'modules/auth/AuthActions'
import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { IAuthUserResponseDTO } from 'modules/auth/dtos/response/IAuthUserResponseDTO'
import { RoutingHelper } from 'common/helpers/RoutingHelper'
import { AppStateUtils } from 'common/utils/AppStateUtils'
import { AuthRoutes } from 'modules/auth/AuthRoutes'
import { LoadingScreen } from 'common/screens/LoadingScreen'

/**
 * Determina direcionamento apropriado para definacao de rotas / telas
 * de acordo o tipo de pessoa logada atualmente (se houver alguma).
 *
 * @author hjcostabr
 */
export function LoginVerifierCP(): JSX.Element {

    const loggedUserData = AppStateUtils.getLoggedUserData()
    const [isLogged, setIsLogged] = useState<boolean>()

    const routeLocation = useLocation()
    // const verifyTokenRequest = useRequest<IAuthUserResponseDTO>(AuthRequests.getTokenVerificationReqConfig())

    useEffect(verifyToken, [routeLocation.pathname])
    // useEffect(onRequestChange, [verifyTokenRequest.awaiting])

    function verifyToken(): void {

        if (!loggedUserData)
            return setIsLogged(false)

        return onValidationOk()
        // if (!RoutingHelper.mustVerifyAuth
        //     || (!!loggedUserData.updateDate
        //         && DateUtils.getTimeFromNow(loggedUserData.updateDate, TimeBaseEnum.MINUTE) <= SystemConfig.getInstance().loginVerifierInterval
        //     )
        // )
        //     return onValidationOk()
        //
        // setIsLogged(undefined)
        // verifyTokenRequest.runRequest()
    }

    // function onRequestChange(): void {
    //
    //     if (verifyTokenRequest.awaiting || !verifyTokenRequest.tried)
    //         return
    //
    //     const user = verifyTokenRequest.returnData
    //     const _isLogged = (verifyTokenRequest.success && !!user && !!loggedUserData)
    //
    //     if (_isLogged)
    //         return onValidationOk(user)
    //
    //     setIsLogged(false)
    //     NotificationHelper.info('Sessão Expirada!', 'Sua sessão expirou. Faça login novamente para prosseguir', 4000)
    // }

    function onValidationOk(user?: IAuthUserResponseDTO): void {

        setIsLogged(true)
        RoutingHelper.onPathParsingFinish()

        if (!!user)
            AuthActions.setLoggedUser(user, loggedUserData!.token)

    }

    if (/*verifyTokenRequest.awaiting ||*/ isLogged === undefined)
        return <LoadingScreen/>

    if (!isLogged) {
        AuthActions.logout()
        return RoutingHelper.renderRedirect(AuthRoutes.USER_LOGIN)
    }

    return <UserMainLayoutCP/>
}
