import React from 'react'
import { Descriptions } from 'antd'

interface IDescriptionItemICPProps {
    label?: string
    children?: JSX.Element | string
}

/**
 * COMPONENTE
 * Linha que inclui descricao de 01 item de 01 conjunto de descricao.
 *
 * @author renatofs
 */
export function DescriptionItemCP(props: IDescriptionItemICPProps): JSX.Element {
    return (
        <Descriptions.Item label={props.label}>
            {props.children}
        </Descriptions.Item>
    )
}
