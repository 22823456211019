import { OrArrayTP } from 'common/types/OrArrayTP'
import { OrNullTP } from 'common/types/OrNullTP'
import { RouteConfigTP } from 'common/types/RouteConfigTP'
import { RouteStringTP } from 'common/types/RouteStringTP'
import { StringUtils } from 'common/utils/StringUtils'
import { History, LocationState, Path } from 'history'
import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'

type _RouteChangingFunctionTP = (path: Path, state?: LocationState) => void

/**
 * HELPER
 * Executa a gestao de roteamento & transicao de telas da aplicacao.
 *
 * @author hjcostabr
 */
export class RoutingHelper {

    private static readonly _FALLBACK_ROOT = '/'

    private static _mustVerifyAuth = true
    private static _history?: History

    private static _publicRoutes: RouteConfigTP[]
    private static _defaultRoute?: string

    static init(history: History, publicRoutes: RouteConfigTP[]): void {
        RoutingHelper._history = history
        RoutingHelper._publicRoutes = publicRoutes
        RoutingHelper._defaultRoute = RoutingHelper._FALLBACK_ROOT
    }

    static setDefaultRoute(route?: string): void {

        const isInitialDefault = (!!route && RoutingHelper._defaultRoute === RoutingHelper._FALLBACK_ROOT)
        RoutingHelper._defaultRoute = route
        if (!isInitialDefault)
            return

        let currentPath = RoutingHelper._history?.location.pathname
        if (!currentPath)
            return

        currentPath = StringUtils.stripEndingChars(currentPath, RoutingHelper._FALLBACK_ROOT)
        if (currentPath === RoutingHelper._getRootPath())
            RoutingHelper.historyReplace('default')
    }

    /**
     * Executa transicao de tela ADICIONANDO NOVA ROTA na pilha de historico de navegacao:
     * Permite desabilitar revalidacao de token de autenticacao a ser disparada com
     * o evento de transicao de tela;
     */
    static historyPush(path: RouteStringTP, byPassVerification = false): void {
        if (!!RoutingHelper._history)
            RoutingHelper._changeRoute(RoutingHelper._history.push, path, byPassVerification)
    }

    /**
     * Executa transicao de tela SUBSTITUINDO ROTA ATUAL na pilha de historico de navegacao:
     * Permite desabilitar revalidacao de token de autenticacao a ser disparada com
     * o evento de transicao de tela;
     */
    static historyReplace(path: RouteStringTP, byPassVerification = false): void {
        if (!!RoutingHelper._history)
            RoutingHelper._changeRoute(RoutingHelper._history.replace, path, byPassVerification)
    }

    /**
     * Metodo a ser chamado para computar termino da analise de 01 rota:
     * i.e: Deve ser chamado apos a ultima intervencao do ultimo elemento que responde a rota /
     * mecanismo de navegacao.
     */
    static onPathParsingFinish(): void {
        RoutingHelper._mustVerifyAuth = true
    }

    /** Reenderiza componente <Switch/> para roteamento das rotas publicas. */
    static renderPublicRoutingSwitch(): JSX.Element {
        return RoutingHelper.renderRoutingSwitch(RoutingHelper._publicRoutes)
    }

    /** Reenderiza componente <Switch/> para lista de rotas informada. */
    static renderRoutingSwitch(configList: RouteConfigTP[]): JSX.Element {

        if (!!this._defaultRoute) {
            configList.push({
                key: 'root',
                path: 'root',
                exact: true,
                redirect: '/',
            })
        }

        return (
            <Switch>
                {
                    configList.map(routeConfig => RoutingHelper._renderRoute(routeConfig))
                }
            </Switch>
        )
    }

    /** Reenderiza componente <Redirect/> para redirecionamento dentro de componentes. */
    static renderRedirect(to: RouteStringTP): JSX.Element {
        return <Redirect to={to}/>
    }

    /**
     * @todo: Verificar
     * Tratamento generico para ocorrencia de erro 403.
     */
    static handleInvalidSchema(history?: History): void {

        if (!!this._history || !!history) {

            const rootPath = RoutingHelper._getRootPath()

            if (rootPath !== RoutingHelper._FALLBACK_ROOT) {
                this._history = this._history ?? history
                return RoutingHelper.historyReplace(rootPath)
            }
        }

        console.error('FALHA RoutingHelper.handleInvalidSchema - Impossivel tratar erro de schema invalido')
    }

    /** Executa 01 transicao de tela / mudanca de rota. */
    private static _changeRoute(changeRouteFunction: _RouteChangingFunctionTP, routeWithNoSchema: string, byPassVerification = false): void {
        RoutingHelper._mustVerifyAuth = !byPassVerification
        changeRouteFunction(routeWithNoSchema)
    }

    /** Retorna rota 'raiz' da aplicacao. */
    private static _getRootPath(): string {
        return '/'
    }

    /** Reenderiza componente <Route/> para definicao de 01 rota. */
    private static _renderRoute(config: RouteConfigTP): OrNullTP<OrArrayTP<JSX.Element>> {

        const mustRender = (!config.component && (!!config.redirect || !!config.render))
        if (!mustRender && !config.component)
            return null

        const key = config.key ?? (!!config.rootPath ? StringUtils.getSlugStyleString(config.rootPath) : undefined)
        const render = mustRender
            ? !!config.redirect ? () => RoutingHelper.renderRedirect(config.redirect!) : config.render
            : undefined

        const paths: Array<string | undefined> = []

        if (!!config.path)
            paths.push(config.path)
        else
            paths.push(undefined)

        return paths.map((path, index) => (
            <Route
                key={`${key ?? ''}_${index}`}
                path={path}
                exact={config.exact}
                render={render}
                component={config.component}
            />
        ))
    }

    static get mustVerifyAuth(): boolean {
        return RoutingHelper._mustVerifyAuth
    }
}

