import * as _ from 'lodash'
import { LayoutCP } from 'common/components/screen-layout/layout/LayoutCP'
import React from 'react'
import { RoutingHelper } from 'common/helpers/RoutingHelper'
import styled from 'config/theme/styledWithTheme'
import { AppStateUtils } from 'common/utils/AppStateUtils'
import { ContentAuthCP } from 'modules/auth/components/content-auth/ContentAuthCP'
import { useScreenSize } from 'common/responsiveness/use-screen-size/UseScreenSize'
import { AuthSiderCP } from 'modules/auth/components/auth-sider/AuthSiderCP'
import { DocumentRoutes } from 'modules/document/routers/DocumentRoutes'

interface IContentAuthCPProps {
    content: JSX.Element
}

/**
 * Layout das telas de autenticacao.
 *
 * @author renatofs
 */
export function AuthLayoutCP(props: IContentAuthCPProps): JSX.Element | null {

    const loggedUserData = AppStateUtils.getLoggedUserData()
    const screenSize = useScreenSize()

    if (!_.isEmpty(loggedUserData?.user)) {
        RoutingHelper.historyReplace(DocumentRoutes.ROOT)
        return null
    }

    return (
        <LayoutWrapperSCP>
            <LayoutCP
                sider={screenSize.smd ? undefined : <AuthSiderCP width={'60%'}/>}
                mainLayout={!screenSize.smd}
                content={
                    <ContentAuthCP
                        maxWidthContainer={350}
                    >
                        {props.content}
                    </ContentAuthCP>
                }
            />
        </LayoutWrapperSCP>
    )
}

const LayoutWrapperSCP = styled.div`
    .ant-layout {
	    background: #fff;
	}
`
