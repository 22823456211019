import React from 'react'
import { ContentCP } from 'common/components/screen-layout/content/ContentCP'
import { styled } from 'config/theme/styledWithTheme'
import { PdfViewerCP } from 'common/components/pdf-viewer/PdfViewerCP'
import { FileUploadCP } from 'common/components/file-upload/FileUploadCP'
import { CardCP } from 'common/components/card/CardCP'
import { MimeTypeEnum } from 'common/enums/MimeTypeEnum'
import { NewDocumentWizardCP } from 'modules/document-signature/components/new-document-wizard/NewDocumentWizardCP'
import { useScreenSize } from 'common/responsiveness/use-screen-size/UseScreenSize'
import { IFormStateManager } from 'common/form-state-manager/interfaces/IFormStateManager'
import { DocumentContentFormValidator } from 'modules/document-signature/components/new-document-wizard/inner/DocumentContentFormValidator'
import { AssigneesContentFormValidator } from 'modules/document-signature/components/new-document-wizard/inner/AssigneesContentFormValidator'
import { HeaderCP } from 'common/components/screen-layout/header/HeaderCP'

interface INewDocumentScreenContentCPProps {
    onUploadFile: (file: File) => void
    file?: File
    onCompleteWizard: (documentFormStateManager: IFormStateManager<DocumentContentFormValidator>, assigneerFormStateManager: IFormStateManager<AssigneesContentFormValidator>) => void
}

/**
 * Content da TELA de Criar novo documento para assinatura.
 */
export function NewDocumentScreenContentCP(props: INewDocumentScreenContentCPProps): JSX.Element | null {

    const screenSize = useScreenSize()

    return (
        <ContentCP>
            {
                screenSize.smd &&
                <HeaderCP title={'Criar Documento'}/>
            }

            {
                props.file
                    ?
                    <PdfViewerCP
                        file={props.file}
                        paginationType={'paginator'}
                    />
                    :
                    <FileUploadWrapperSCP>
                        <CardCP>
                            <FileUploadCP
                                uploadFiles={props.onUploadFile}
                                fileTypes={[MimeTypeEnum.PDF]}
                            />
                        </CardCP>
                    </FileUploadWrapperSCP>
            }

            {
                screenSize.smd &&
                <CardCP innerSpacing={'small'}>
                    <NewDocumentWizardCP onDone={props.onCompleteWizard}/>
                </CardCP>
            }
        </ContentCP>
    )
}

const FileUploadWrapperSCP = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    
    .ant-spin-container {
      height: 300px;
      width: 400px;
    }
`
