import React, { useEffect, useState } from 'react'
import * as CPFValidator from '@fnando/cpf'
import { RowCP } from 'common/components/grid/RowCP'
import { InputCP } from 'common/components/form-fields/input/InputCP'
import { IFormStateManager } from 'common/form-state-manager/interfaces/IFormStateManager'
import { SwitchCP } from 'common/components/switch/SwitchCP'
import { styled } from 'config/theme/styledWithTheme'
import { ButtonCP } from 'common/components/button/ButtonCP'
import * as _ from 'lodash'
import { NotificationHelper } from 'common/helpers/NotificationHelper'
import { AssigneesContentFormValidator } from 'modules/document-signature/components/new-document-wizard/inner/AssigneesContentFormValidator'
import { InputMaskTypeEnum } from 'common/enums/InputMaskTypeEnum'
import { PersonInviteModalCP } from 'modules/person/components/person-invite-modal/PersonInviteModalCP'
import { AssigneeTableDnDCP } from 'modules/document-signature/components/assignee-table-dnd/AssigneeTableDnDCP'
import { IPersonResponseDTO } from 'modules/person/services/dtos/response/IPersonResponseDTO'
import { useRequest } from 'common/request-manager/use-request/UseRequest'
import { PersonRequests } from 'modules/person/services/PersonRequests'
import { HttpStatusEnum } from 'common/enums/HttpStatusEnum'
import { IAssigneeRequestDTO } from 'modules/document/services/dtos/request/IAssigneeRequestDTO'
import { MaskUtils } from 'common/utils/MaskUtils'

interface IAssigneesContentStepICPProps {
    formStateManager: IFormStateManager<AssigneesContentFormValidator>
}

/**
 * COMPONENTE da etapa de signatarios.
 */
export function AssigneesContentStepICP(props: IAssigneesContentStepICPProps): JSX.Element | null {

    const [isNewPersonModalVisible, setIsNewPersonModalVisible] = useState<boolean>(false)
    const [assignees, setAssignees] = useState<IPersonResponseDTO[]>([])
    const [newAssigneeCPF, setNewAssigneeCPF] = useState<string>()

    const searchPersonRequest = useRequest<IPersonResponseDTO>()
    useEffect(onSearchPersonRequestChange, [searchPersonRequest.isAwaiting])

    /**
     * Busca pessoa com CPF informado.
     */
    function searchAssignee(): void {

        if (!newAssigneeCPF || !CPFValidator.isValid(newAssigneeCPF)) {
            NotificationHelper.error('Informe um CPF válido')
            return
        }

        const formatedCPF = MaskUtils.removeMask(newAssigneeCPF)

        searchPersonRequest.runRequest(PersonRequests.getPersonByCpf(formatedCPF))
    }

    /**
     * Retorno da API, verifica se encontrou a pessoa ou nao.
     */
    function onSearchPersonRequestChange(): void {

        if (searchPersonRequest.isAwaiting || !searchPersonRequest.wasTried)
            return

        const isSuccess = (searchPersonRequest.isSuccess && !!searchPersonRequest.responseData)
        if (!isSuccess) {

            // Se o retorno foi de nao encontrado, abrimos o modal para cadastrar pessoa
            if ([HttpStatusEnum.NOT_FOUND].includes(searchPersonRequest.responseStatus!)) {
                setIsNewPersonModalVisible(true)
                return
            }

            // Qualquer outro erro foi inesperado
            NotificationHelper.error('Ocorreu algun erro ao buscar pessoa')
            return
        }

        NotificationHelper.success('Pessoa adicionada com sucesso!')
        addAssignee(searchPersonRequest.responseData!)
        setNewAssigneeCPF('')
    }

    /**
     * Retorno quando o convite for enviado para pessoa
     */
    function onPersonInvited(person?: IPersonResponseDTO): void {

        setIsNewPersonModalVisible(false)

        // Se retornou uma pessoa eh que o cadastro foi feito com sucesso
        if (person) {
            setNewAssigneeCPF('')
            addAssignee(person)
        }
    }

    /**
     * Adiciona um signatario na lista.
     */
    function addAssignee(person: IPersonResponseDTO): void {
        const _newAssignees = _.concat(assignees, person)
        setAssignees(_newAssignees)
        syncAssigneeFormStateManager(_newAssignees)
    }

    /**
     * Remove um signatario na lista.
     */
    function removeAssignee(index: number): void {
        const _newAssignees = assignees.splice(index, 1)
        syncAssigneeFormStateManager(_newAssignees)
    }

    /**
     * Passa a lista de signatarios para o formato do request, salvo no statemanager
     */
    function syncAssigneeFormStateManager(_assignees: IPersonResponseDTO[]): void {

        const assigneesDTO: IAssigneeRequestDTO[] = _assignees.map((_assignee, index) => {
            return {
                order: index + 1,
                personCode: _assignee.code
            }
        })

        props.formStateManager.changeFieldValue('assignees', assigneesDTO)
    }

    return (
        <>
            <RowCP>
                <SwitchCP
                    isTextInside={false}
                    isChecked={props.formStateManager.getFieldValue('isOrdered')}
                    textOutside={'Assinatura ordenada'}
                    onChange={(checked) => props.formStateManager.changeFieldValue('isOrdered', checked)}
                />
            </RowCP>

            <RowCP>
                <NewAssigneeSCP>
                    <InputCP
                        label={'CPF (informe o CPF do signatário)'}
                        required={true}
                        mask={InputMaskTypeEnum.CPF}
                        value={newAssigneeCPF}
                        onChange={(value) => setNewAssigneeCPF(value)}
                    />
                    <ButtonCP
                        icon={'plus'}
                        onClick={searchAssignee}
                        loading={searchPersonRequest.isAwaiting}
                    />
                </NewAssigneeSCP>
            </RowCP>

            {
                !_.isEmpty(assignees) &&
                <AssigneeTableDnDCP
                    assignees={assignees}
                    canOrder={props.formStateManager.getFieldValue('isOrdered')}
                    onRemoveAssignee={removeAssignee}
                    onChangeOrder={syncAssigneeFormStateManager}
                />
            }

            <PersonInviteModalCP
                visible={isNewPersonModalVisible}
                enteredCpf={newAssigneeCPF}
                onClose={onPersonInvited}
            />
        </>
    )
}

const NewAssigneeSCP = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 15px;
`
