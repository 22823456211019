import { Layout } from 'antd'
import { useScreenSize } from 'common/responsiveness/use-screen-size/UseScreenSize'
import styled from 'config/theme/styledWithTheme'
import React from 'react'

type _AntPropsTP = {
    style?: React.CSSProperties,
}

interface IContentCPProps extends React.PropsWithChildren<_AntPropsTP> {}

/**
 * COMPONENTE
 * Area de conteudo principal dentro de 01 layout.
 *
 * @author Lucas Rosa
 * @author hjcostabr
 */
export function ContentCP(props: IContentCPProps): JSX.Element {
    const screenSize = useScreenSize()
    return (
        <WrapperSCP isSmallScreen={screenSize.smd} data-tour={'content-cp'}>
            <Layout.Content style={props.style}>
                { props.children }
            </Layout.Content>
        </WrapperSCP>
    )
}

const WrapperSCP = styled.div<{isSmallScreen: boolean}>`

    height: 100%;
    flex: 1;
    ${props => (props.isSmallScreen ? 'width: 100%;' : '')}

    .ant-layout-content {
        display: flex;
        height: 100%;
        flex-direction: column;
        overflow: auto;
        position: relative;
    }

`
