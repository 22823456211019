import React from 'react'
import styled from 'config/theme/styledWithTheme'
import { AlertCP } from 'common/components/alert/AlertCP'
import { TextCP } from 'common/components/text/TextCP'
import { AppStateUtils } from 'common/utils/AppStateUtils'
import { StringUtils } from 'common/utils/StringUtils'
import { DocumentDetailsCP } from 'modules/document/components/document-details/DocumentDetailsCP'
import { IDocumentResponseDTO } from 'modules/document/services/dtos/response/IDocumentResponseDTO'

interface ISignatureContentICPProps {
    document: IDocumentResponseDTO
}

/**
 * COMPONENTE: Conteudo do modal quando esta logado e pronto para assinar.
 */
export function SignatureContentICP(props: ISignatureContentICPProps): JSX.Element {

    if (!AppStateUtils.getLoggedUserData())
        return <>Usuário não está logado</>

    return (
        <>
            <AlertCP
                message={
                    <>
                        <TextCP
                            text={`Olá ${StringUtils.getFirstName(AppStateUtils.getLoggedUserData()!.user.name)}, seja bem-vindo ao Dokfy.`}
                            style={{ marginBottom: '10px' }}
                        />
                        <TextCP
                            text={'Confirme se todos os dados estão corretos e clique em Assinar.'}
                            style={{ marginBottom: '10px' }}
                        />
                        <TextCP text={'Lembre-se que essa ação não pode ser desfeita.'}/>
                    </>
                }
                type={'info'}
            />

            <DocumentDetailsCP document={props.document}/>

            <SignatureContentWrapperSCP>
                <TextCP
                    text={AppStateUtils.getLoggedUserData()!.user.name}
                    size={'extraLarge'}
                    style={{ fontFamily: 'Caveat, cursive' }}
                    underline={true}
                />
            </SignatureContentWrapperSCP>
        </>
    )
}

const SignatureContentWrapperSCP = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
`
