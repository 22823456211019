import React, { useState } from 'react'
import styled from 'config/theme/styledWithTheme'
import { HeaderCP } from 'common/components/screen-layout/header/HeaderCP'
import { LoggedUserDataCardCP } from 'modules/person/components/logged-user-data-card/LoggedUserDataCardCP'
import { AppStateUtils } from 'common/utils/AppStateUtils'
import { HeaderButtonICP } from 'common/components/screen-layout/header/inner/HeaderButtonICP'
import { ChangePasswordModalCP } from 'modules/person/components/change-password-modal/ChangePasswordModalCP'

/**
 * TELA perfil.
 */
export function PersonProfileScreen(): JSX.Element {

    const [isPasswordModalVisible, setIsPasswordModalVisible] = useState<boolean>(false)
    const loggedUserData = AppStateUtils.getLoggedUserData()!

    return (
        <>
            <HeaderCP title={'Meu Perfil'}>
                <HeaderButtonICP
                    icon={'setting'}
                    label={'Alterar senha'}
                    onClick={() => setIsPasswordModalVisible(true)}
                />
            </HeaderCP>

            <ContentSCP>
                <CardContainer>
                    <LoggedUserDataCardCP userCpfCnpj={loggedUserData.user.cpfCnpj}/>

                    <ChangePasswordModalCP
                        user={loggedUserData.user}
                        visible={isPasswordModalVisible}
                        onClose={() => setIsPasswordModalVisible(false)}
                    />
                </CardContainer>
            </ContentSCP>
        </>
    )
}

const ContentSCP = styled.div`
    display: flex;
    overflow-x: auto;
    justify-content: center;
`

const CardContainer = styled.div`
    width: 600px;

    .ant-card .ant-card-body {
        overflow: hidden;
    }
`
