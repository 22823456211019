import { Input } from 'antd'
import { FormItemICP } from 'common/components/form-fields/inner/FormItemICP'
import { styled } from 'config/theme/styledWithTheme'
import React from 'react'
import { IFormItemInputCommonProps } from 'common/components/form-fields/inner/interfaces/IFormItemInputCommonProps'
import { FormModel } from '../../../form-state-manager/classes/FormModel'

const MIN_ROWS_DEFAULT = 2

interface ITextAreaCPProps<FModelTP extends FormModel = any> extends IFormItemInputCommonProps<FModelTP> {
    minRows?: number
    maxRows?: number
}

/**
 * COMPONENTE
 * Input do tipo 'textarea'.
 *
 * @author hjcostabr
 */
export function TextAreaCP<FModelTP extends FormModel = any>(props: ITextAreaCPProps<FModelTP>): JSX.Element {
    return (
        <FormItemICP<FModelTP, IFormItemInputCommonProps<FModelTP>>
            label={props.label}
            onChange={props.onChange}
            fieldName={props.fieldName}
            formStateManager={props.formStateManager}
            required={props.required}
            width={props.width}
            errorMessage={props.errorMessage}
            noValidation={props.noValidation}
        >
            <TextAreaWrapperSCP>
                <Input.TextArea
                    autosize={{
                        minRows: props.minRows ?? MIN_ROWS_DEFAULT,
                        maxRows: props.maxRows
                    }}
                />
            </TextAreaWrapperSCP>
        </FormItemICP>
    )
}

const TextAreaWrapperSCP = styled.div`

    width: 100%;

    .ant-input {
        margin-top: 10px;
    }
`
