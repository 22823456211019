import React from 'react'

import { IIconProps } from './inner/IIconProps'
import { IconICP } from 'common/components/icons/inner/IconICP'

interface IFileIconCPProps extends IIconProps{
    type: 'check' | 'verified' | 'add'
}

/**
 * ICONEs: Arquivo
 */
export function FileIconCP(props: IFileIconCPProps): JSX.Element {

    let _iconName

    switch (props.type) {
        case 'add':
            _iconName = 'file-add'
            break

        case 'check':
            _iconName = 'file-done'
            break

        case 'verified':
            _iconName = 'file-protect'
            break

        default:
            _iconName = ''
            break
    }

    return (
        <IconICP
            iconName={_iconName}
            size={props.size}
            color={props.color}
            style={props.style}
            theme={props.theme}
            transitionTime={props.transitionTime}
        />
    )
}
