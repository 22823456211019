import React from 'react'
import { Col } from 'antd'
import { styled } from 'config/theme/styledWithTheme'
import { ColumnCpColspanTP } from 'common/components/grid/inner/ColumnCpColspanTP'

type AntPropsTP = {
    size: ColumnCpColspanTP,
    xs?: ColumnCpColspanTP,
    sm?: ColumnCpColspanTP,
    md?: ColumnCpColspanTP,
    lg?: ColumnCpColspanTP,
}

type SharedPropsTP = {
    centerContent?: boolean,
}

interface IColumnCPProps extends React.PropsWithChildren<AntPropsTP & SharedPropsTP> {}

/**
 * COMPONENTE
 * COLUNA para alinhamento de layout em GRID.
 */
export function ColumnCP(props: IColumnCPProps): JSX.Element {
    return (
        <WrapperSCP centerContent={!!props.centerContent} span={props.size}>
            <Col
                span={props.size}
                xs={props.xs}
                sm={props.sm}
                md={props.md}
                lg={props.lg}
            >
                {props.children}
            </Col>
        </WrapperSCP>
    )
}

const WrapperSCP = styled.div<SharedPropsTP & { span: ColumnCpColspanTP }>`
    &.ant-col-${props => props.span} {
        ${props => (props.centerContent ? 'display: flex;' : '')}
        ${props => (props.centerContent ? 'justify-content: center;' : '')}
        ${props => (props.centerContent ? 'align-items: center;' : '')}
    }
`
