import { Skeleton, Table } from 'antd'
import { PaginationConfig } from 'antd/lib/pagination'
import { ColumnProps, SorterResult, SortOrder, TableRowSelection } from 'antd/lib/table'
import { TableEventListeners } from 'antd/lib/table/interface'
import { EmptyCP } from 'common/components/empty/EmptyCP'
import { ListMobileICP } from 'common/components/table/inner/ListMobileICP'
import { DateFormatEnum } from 'common/enums/DateFormatEnum'
import { useScreenSize } from 'common/responsiveness/use-screen-size/UseScreenSize'
import { OrUndefTP } from 'common/types/OrUndefTP'
import { DateUtils } from 'common/utils/date/DateUtils'
import { css, styled } from 'config/theme/styledWithTheme'
import React from 'react'

/**
 * @todo: Mover para 01 arquivo proprio
 */
export type TablePaginationTP = {
    total: number,
    current: number,
    pageSize: number,
    showTotal?: (total: number, range: [number, number]) => React.ReactNode,
}

type CustomPropsTP<RowTP> = {
    rowKeyGetter?: (row: RowTP, index: number) => string,
    showTableOnResponsive?: boolean,
}

interface ITableCPProps<RowTP> {
    data?: RowTP[]
    columns?: Array<ColumnProps<RowTP>>
    pagination?: TablePaginationTP
    onPaginationChange?: (pagination: PaginationConfig) => void
    onSortingChange?: (sorting: SorterResult<RowTP>) => void
    rowKey?: (row: RowTP, index: number) => string
    bordered?: boolean
    rowSelection?: TableRowSelection<RowTP>
    loading?: boolean
    children?: React.ReactNode
    sortDirections?: SortOrder[]
    rowClassName?: (record: RowTP, index: number) => string
    showHeader?: boolean
    onRow?: (record: RowTP, index: number) => TableEventListeners
    showLoadingAsSkeleton?: boolean
    empty?: JSX.Element | string
}

/**
 * Tabela com estilo do sistema.
 *
 * @todo: Nao expor elementos do ant
 * @todo: Forcar definicao de key
 * @todo: Concluir refatoracao
 */
export function TableCP<RowTP = any>(props: CustomPropsTP<RowTP> & ITableCPProps<RowTP>): JSX.Element {

    const screenSize = useScreenSize()

    function onChangeHandler(
        pagination: PaginationConfig,
        filters: Partial<Record<keyof RowTP, string[]>>,
        sorter: SorterResult<RowTP>/*,
        extra: TableCurrentDataSource<RowTP>*/
    ): void {

        if (!!props.onPaginationChange)
            props.onPaginationChange(pagination)

        if (!!props.onSortingChange)
            props.onSortingChange(sorter)
    }

    function rowKeyGetter(row: RowTP, index: number): string {

        if (!!props.rowKeyGetter)
            return props.rowKeyGetter(row, index)

        const _row = (row as any)

        const defaultRowKey: number | string = (
            ((_row.code ?? _row.key ?? _row.id) as OrUndefTP<number | string>)
            ?? `table_${DateUtils.getFormatted(new Date(), DateFormatEnum.US_WITHOUT_TIME)}_${index}`
        )

        return defaultRowKey.toString()
    }

    /** Definicao do componente de tabela. */
    const tableAntComponent = (
        <TableComponent<RowTP>
            rowSelection={props.rowSelection}
            rowKey={rowKeyGetter}
            dataSource={props.data}
            columns={props.columns}
            pagination={props.pagination ? { position: 'bottom', size: 'small', ...props.pagination } : false}
            onChange={onChangeHandler}
            sortDirections={props.sortDirections}
            rowClassName={props.rowClassName}
            size={'small'}
            locale={{ emptyText: props.empty ?? <EmptyCP description={'Nenhum dado encontrado para os filtros selecionados'}/> }}
            loading={props.loading}
            showHeader={props.showHeader !== false}
            onRow={props.onRow}
            bordered={props.bordered}
            data-tour={'table-cp'}
        >
            {props.children}
        </TableComponent>
    )

    if (screenSize.smd && !props.showTableOnResponsive) {
        return (
            <ListMobileICP
                data={props.data}
                columns={props.columns}
                loading={props.loading}
                pagination={props.pagination ?
                    {
                        position: 'bottom',
                        size: 'small',
                        ...props.pagination
                    }
                    : false}
                onPaginationChange={(pagination: PaginationConfig) => {
                    if (props.onPaginationChange)
                        props.onPaginationChange(pagination)
                }}
            />
        )
    }

    return (
        props.showLoadingAsSkeleton ?
            <SkeletonContentSCP loading={props.loading}>
                <Skeleton loading={props.loading} active={true}>
                    {tableAntComponent}
                </Skeleton>
            </SkeletonContentSCP>
            : tableAntComponent
    )
}

const SkeletonContentSCP = styled.div<{loading?: boolean}>`
    margin: ${props => (props.loading ? '10px' : '0')};
`

const border = css`
    border: 1px solid #e8e8e8;
    border-radius: 10px;

`

const TableSCP = styled(Table)<{ bordered: boolean }>`
    width: 100%;
    &.ant-table-wrapper .ant-spin-nested-loading .ant-spin-container .ant-table {
        border: none;
    }
    &.ant-table-wrapper .ant-spin-nested-loading .ant-spin-container .ant-table .ant-table-content .ant-table-placeholder {
        ${props => (props.bordered ? border : 'border: none;')};
        background: none;
    }
    &.ant-table-wrapper .ant-spin-nested-loading .ant-spin-container .ant-table .ant-table-content .ant-table-body > table > thead {
        tr {
            th {
                color: ${props => props.theme.primaryColor};
                font-weight: bold;
                font-size: 0.8rem;
                border: none;
                padding-top: 11px;
                padding-bottom: 11px;
                white-space: nowrap;
            }
        }
    }

    &.ant-table-wrapper .ant-spin-nested-loading .ant-spin-container .ant-table .ant-table-content .ant-table-body > table > tbody {
        tr:hover td {
            background: rgba(218, 225, 239, 0.3);
        }
        ${props => (props.bordered ? 'box-shadow: 0 0 0 1px #e8e8e8;' : '')}
        ${props => (props.bordered ? 'border-radius: 10px;' : '')}

    }
    &.ant-table-wrapper .ant-spin-nested-loading  .ant-pagination {
        width: 100%;
        display: flex;
        justify-content: center;
    }
    .ant-table-small{
        .ant-table-content {
            .ant-table-body {
                margin: 0;
                table > .ant-table-tbody > tr > td {
                    padding: 6px 6px;
                }
            }
        }
    }
`

const TableComponent = TableSCP as any as React.ComponentType as new <RowTP>() => Table<RowTP>
