import React from 'react'
import { styled } from 'config/theme/styledWithTheme'
import { WhatsappIconCP } from 'common/components/icons/WhatsappIconCP'
import { AppStateUtils } from 'common/utils/AppStateUtils'
import { IntercomCP } from 'common/components/intercom/IntercomCP'
import { EnvironmentEnum } from 'common/enums/EnvironmentEnum'
import { SystemConfig } from 'config/SystemConfig'

interface IChatBalloonCPProps {
    showWhatsapp?: boolean
}

/**
 * Encapsula chat. Podem ser intercom ou whatsapp
 *
 * @author renatofs
 */
export function ChatBalloonCP(props: IChatBalloonCPProps): JSX.Element {

    const loggedUser = AppStateUtils.getLoggedUserData()

    if (SystemConfig.getInstance().environment !== EnvironmentEnum.PROD)
        return <></>

    if (loggedUser === null)
        return <></>

    return (
        <>
            <IntercomCP/>

            {
                props.showWhatsapp &&
                <WhatsappWrapperSCP>
                    <a
                        href={'https://api.whatsapp.com/send?phone=5531992029450&text=Quero%20mais%20informa%C3%A7%C3%B5es%20sobre%20o%20plano%20especial.'}
                        target={'_blank'}
                        rel={'noopener noreferrer'}
                    >
                        <WhatsappIconCP size={30} color={'#FFF'}/>
                    </a>
                </WhatsappWrapperSCP>
            }
        </>
    )
}

const WhatsappWrapperSCP = styled.div`

    position: fixed;
    bottom: 20px;
    right: 100px;
    z-index: 1;
    width: 60px;
    height: 60px;

    background: #5cb85c;
    
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 70px;

`
