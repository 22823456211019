import React, { useEffect, useState } from 'react'
import styled from 'config/theme/styledWithTheme'
import { PdfViewerCP } from 'common/components/pdf-viewer/PdfViewerCP'
import { ButtonCP } from 'common/components/button/ButtonCP'
import { ConfirmSignatureModalCP } from 'modules/document-signature/components/confirm-signature-modal/ConfirmSignatureModalCP'
import { IDocumentForSignResponseDTO } from 'modules/document/services/dtos/response/IDocumentForSignResponseDTO'
import { SignDocumentHeaderBarCP } from 'modules/document-signature/components/sign-document-header-bar/SignDocumentHeaderBarCP'
import { EditIconCP } from 'common/components/icons/EditIconCP'
import { useRequest } from 'common/request-manager/use-request/UseRequest'
import { DocumentRequests } from 'modules/document/services/DocumentRequests'
import { RequestUtils } from 'common/request-manager/RequestUtils'
import { DeclineSignatureModalCP } from 'modules/document-signature/components/decline-signature-modal/DeclineSignatureModalCP'
import { DocumentStatusEnum } from 'modules/document/enums/DocumentStatusEnum'
import { PersonUtils } from 'modules/person/utils/PersonUtils'
import { IPersonIpRequestDTO } from 'modules/person/services/dtos/request/IPersonIpRequestDTO'

interface IDocumentToSignScreenContentICPProps {
    documentForSign: IDocumentForSignResponseDTO
    onSignOrDecline: (signed: DocumentStatusEnum) => void
}

/**
 * Conteudo da tela que mostra o documento e botoes para assinatura.
 */
export function DocumentToSignScreenContentICP(props: IDocumentToSignScreenContentICPProps): JSX.Element {

    const [isDocumentFileLoaded, setIsDocumentFileLoaded] = useState<boolean>(false)
    const [isSignModalVisible, setIsSignModalVisible] = useState<boolean>(false)
    const [isDeclineModalVisible, setIsDeclineModalVisible] = useState<boolean>(false)
    const [signedOrDeclined, setSignedOrDeclined] = useState<DocumentStatusEnum>()

    const signOrDeclineDocumentRequest = useRequest()
    useEffect(onSignOrDeclineDocumentRequestChange, [signOrDeclineDocumentRequest.isAwaiting])

    /**
     * Assina ou recusa documento
     */
    async function signOrDecline(_signed: DocumentStatusEnum): Promise<void> {

        setSignedOrDeclined(_signed)

        const saveDTO: IPersonIpRequestDTO = {
            personIp: await PersonUtils.getPersonIP()
        }

        if (_signed === DocumentStatusEnum.SIGNED)
            signOrDeclineDocumentRequest.runRequest(DocumentRequests.getSignDocumentConfig(props.documentForSign.document.code, saveDTO))
        else if (_signed === DocumentStatusEnum.DECLINED)
            signOrDeclineDocumentRequest.runRequest(DocumentRequests.getDeclineDocumentConfig(props.documentForSign.document.code, saveDTO))
    }

    /**
     * Retorno da requisicao que assina ou recusa os documentos
     */
    function onSignOrDeclineDocumentRequestChange(): void {

        if (!RequestUtils.isValidRequestReturn(signOrDeclineDocumentRequest, 'Erro ao assinar/recusar documento'))
            return

        setIsSignModalVisible(false)
        setIsDeclineModalVisible(false)

        props.onSignOrDecline(signedOrDeclined!)
    }

    return (
        <>
            <SignDocumentHeaderBarCP documentForSign={props.documentForSign}/>

            <PdfViewerCP
                file={props.documentForSign.document.files.originalFileUrl}
                paginationType={'scroll'}
                onLoadDocument={() => setIsDocumentFileLoaded(true)}
            />

            {
                isDocumentFileLoaded &&
                <ButtonWrapperSCP>
                    <ButtonCP
                        type={'danger'}
                        size={'large'}
                        marginRight={10}
                        onClick={() => setIsDeclineModalVisible(true)}
                    >
                        Recusar
                    </ButtonCP>
                    <ButtonCP
                        type={'primary'}
                        size={'large'}
                        onClick={() => setIsSignModalVisible(true)}
                    >
                        <EditIconCP/>
                        Assinar Documento
                    </ButtonCP>
                </ButtonWrapperSCP>
            }

            <ConfirmSignatureModalCP
                visible={isSignModalVisible}
                onCancel={() => setIsSignModalVisible(false)}
                onSign={async () => await signOrDecline(DocumentStatusEnum.SIGNED)}
                documentToSign={props.documentForSign}
                loading={signOrDeclineDocumentRequest.isAwaiting}
            />

            <DeclineSignatureModalCP
                visible={isDeclineModalVisible}
                onCancel={() => setIsDeclineModalVisible(false)}
                onDecline={async () => await signOrDecline(DocumentStatusEnum.DECLINED)}
                documentToSign={props.documentForSign}
                loading={signOrDeclineDocumentRequest.isAwaiting}
            />

        </>
    )
}

const ButtonWrapperSCP = styled.div`
    position: fixed;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin: 20px 0;
`
