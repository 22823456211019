/* eslint-disable @typescript-eslint/no-floating-promises */
import React, { useEffect, useState } from 'react'
import { InputCP } from 'common/components/form-fields/input/InputCP'
import { MailIconCP } from 'common/components/icons/MailIconCP'
import { useFormStateManager } from 'common/form-state-manager/UseFormStateManager'
import { NotificationHelper } from 'common/helpers/NotificationHelper'
import { useRequest } from 'common/request-manager/use-request/UseRequest'
import { AuthRequests } from 'modules/auth/AuthRequests'
import { AuthFormFooterCP } from 'modules/auth/components/auth-form-footer/AuthFormFooterCP'
import { RecoverPwdFormValidator } from 'modules/auth/components/recover-password-form/inner/RecoverPwdFormValidator'
import { RequestUtils } from 'common/request-manager/RequestUtils'
import { AppStateUtils } from 'common/utils/AppStateUtils'
import { HttpStatusEnum } from 'common/enums/HttpStatusEnum'
import { OrUndefTP } from 'common/types/OrUndefTP'

interface IRecoverPasswordFormCPProps {
    goToLogin: () => void
}

/**
 * COMPONENTE
 * Formulario de RECUPERAR SENHA.
 *
 * @author Stella
 * @author hjcostabr
 */
export function RecoverPasswordFormCP(props: IRecoverPasswordFormCPProps): JSX.Element {

    const [formValidator] = useState<RecoverPwdFormValidator>(new RecoverPwdFormValidator({ schema: AppStateUtils.getDomainSchema() }))
    const formStateManager = useFormStateManager(formValidator)

    const request = useRequest<void>(undefined, 'none')
    useEffect(onRequestChange, [request.isAwaiting])

    function onRequestChange(): void {

        if (request.isAwaiting || !request.wasTried)
            return

        if (!request.isSuccess)
            return handleRequestFailure()

        NotificationHelper.success('Sucesso', 'Senha redefinida com sucesso! Verifique seu e-mail e prossiga com o login')
        props.goToLogin()
    }

    function handleRequestFailure(): void {

        let errorNotification: string
        let errorLog: OrUndefTP<string>

        if (request.responseStatus === HttpStatusEnum.FORBIDDEN)
            errorNotification = 'Domínio inativo ou não reconhecido'

        else if (request.responseStatus === HttpStatusEnum.NOT_FOUND)
            errorNotification = 'e-mail não reconhecido'

        else {
            errorNotification = 'Falha ao tentar realizar login'
            errorLog = 'Falha inesperada'
        }

        if (!!errorLog)
            console.error('FALHA - RecoverPasswordFormCP.onRequestChange: ', errorLog, request.responseData, request.error)

        RequestUtils.showDefaultErrorNotification(request.error, errorNotification)
    }

    async function onFormSubmit(): Promise<void> {

        formStateManager.setConsiderAllErrors(true)
        if (!await formStateManager.validate())
            return

        const formValues = formStateManager.getFormValues()
        if (!formValues)
            return

        request.runRequest(AuthRequests.getRecoverPwdReqConfig({ email: formValues.email }))
    }

    return (
        <>
            <InputCP
                label={'E-mail'}
                fieldName={'email'}
                formStateManager={formStateManager}
                required={true}
                onFormSubmit={onFormSubmit}
                icon={<MailIconCP/>}
            />

            <AuthFormFooterCP
                link={{
                    text: 'Voltar para login',
                    onClick: props.goToLogin,
                }}
                button={{
                    text: 'Recuperar Senha',
                    onClick: onFormSubmit,
                }}
                awaiting={request.isAwaiting}
            />
        </>
    )
}
