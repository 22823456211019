import { Modal } from 'antd'
import { ButtonProps } from 'antd/lib/button'
import { ButtonCP } from 'common/components/button/ButtonCP'
import { styled } from 'config/theme/styledWithTheme'
import React from 'react'
import { useScreenSize } from 'common/responsiveness/use-screen-size/UseScreenSize'

type _AntPropsTP = {
    visible?: boolean,
    title?: string | JSX.Element,
    onOk?: () => void,
    onCancel?: () => void,
    okText?: string,
    cancelText?: string,
    width?: string | number,
    zIndex?: number,
    okButtonProps?: ButtonProps,
    destroyOnClose?: boolean,
    closable?: boolean,
}

type _CustomPropsTP = {
    onClose?: (okClicked: boolean) => void,
    disableOutsideClickClosing?: boolean,
    loading?: boolean,
    singleButton?: boolean,
    noFooter?: boolean,
    top?: number,
    height?: number,
}

interface IModalCPProps extends React.PropsWithChildren<_AntPropsTP & _CustomPropsTP> {
    customFooter?: JSX.Element
}

/**
 * COMPONENTE: Modal.
 * OBSERVACAO: Nao adianta envolver este componente em 01 wrapper pq ele eh reenderizado
 * 'por cima' de tudo.
 *
 * @author Kiara
 * @author renatofs
 * @author hjcostabr
 */
export function ModalCP(props: IModalCPProps): JSX.Element {

    const screenSize = useScreenSize()

    function onClose(okClicked: boolean): void {

        if (!!props.onClose)
            props.onClose(okClicked)

        if (okClicked && !!props.onOk)
            props.onOk()

        if (!okClicked && !!props.onCancel)
            props.onCancel()
    }

    function renderCustomFooter(): JSX.Element | undefined {

        if (!!props.noFooter)
            return <NoFooterSCP/>

        if (props.customFooter)
            return props.customFooter

        if (!!props.singleButton) {
            return (
                <SingleButtonFooterWrapperSCP>
                    <ButtonCP type={'primary'} onClick={() => onClose(true)}>
                        OK
                    </ButtonCP>
                </SingleButtonFooterWrapperSCP>
            )
        }

        return undefined
    }

    return (
        <Modal
            title={props.title}
            visible={props.visible}
            onOk={() => onClose(true)}
            onCancel={() => onClose(false)}
            okText={props.okText ?? 'Salvar'}
            cancelText={props.cancelText ?? 'Cancelar'}
            width={'95%'}
            zIndex={props.zIndex}
            maskClosable={props.disableOutsideClickClosing !== true}
            destroyOnClose={props.destroyOnClose}
            okButtonProps={props.okButtonProps}
            confirmLoading={props.loading}
            footer={renderCustomFooter()}
            closable={props.closable !== false}
            style={{
                top: screenSize.smd ? 10 : props.top,
                maxWidth: props.width ?? 530,
            }}
            bodyStyle={{
                height: props.height,
                overflow: !!props.height ? 'hidden' : undefined,
                paddingBottom: !!props.height ? 10 : undefined,
            }}
        >
            {props.children!}
        </Modal>
    )

}

const NoFooterSCP = styled.div`
  
  &.ant-modal-footer {
    padding: 0px !important;
    border: none !important;
  }
  
`

const SingleButtonFooterWrapperSCP = styled.div`
    display: flex;
    justify-content: flex-end;
`
