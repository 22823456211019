import React from 'react'
import { Select } from 'antd'
import { styled } from 'config/theme/styledWithTheme'
import { FormItemICP } from 'common/components/form-fields/inner/FormItemICP'
import { InputValueCallbackTP } from 'common/components/form-fields/inner/types/InputValueCallbackTP'
import { IFormItemCommonProps } from 'common/components/form-fields/inner/interfaces/IFormItemCommonProps'
import { SelectOptionTP } from 'common/components/form-fields/select/inner/types/SelectOptionTP'
import { SelectCPUtils } from 'common/components/form-fields/select/inner/SelectCPUtils'
import { OrUndefTP } from 'common/types/OrUndefTP'
import { FormModel } from 'common/form-state-manager/classes/FormModel'

export interface ISelectCPProps<FModelTP extends FormModel = any> extends IFormItemCommonProps<FModelTP> {
    options: SelectOptionTP[]
    selectedOptions?: SelectOptionTP[]
    isMultiple?: boolean
    loading?: boolean
    defaultOpen?: boolean
    onSearch?: InputValueCallbackTP<string>
    onClose?: () => void
    returnFullOption?: boolean
    notFoundContent?: string | JSX.Element
    disabled?: boolean
}

/**
 * COMPONENTE
 * Input de multipla escolha para formularios.
 *
 * NOTE: Caso este componente seja controlado via 'form state manager' seu valor nao eh determinado diretamente pela prop 'value'.
 * TODO: Fazer 'filter option' funcionar
 */
export function SelectCP<FModelTP extends FormModel = any>(props: ISelectCPProps): JSX.Element {

    function handleChange(value: any): void {

        if (!props.onChange)
            return

        if (value === undefined)
            return props.onChange(undefined)

        const valueArr = Array.isArray(value) ? value : [value]
        const selectedOptions = !!props.returnFullOption ? valueArr.map(_value => getOptionByValue(_value)) : valueArr
        props.onChange(!!props.isMultiple ? selectedOptions : selectedOptions[0])
    }

    function getOptionByValue(singleValue?: number): OrUndefTP<SelectOptionTP> {

        let option = SelectCPUtils.getOptionByValue(props.options, singleValue)

        if (!option && !!props.selectedOptions)
            option = SelectCPUtils.getOptionByValue(props.selectedOptions, singleValue)

        return option
    }

    return (
        <SelectWrapperSCP>
            <FormItemICP<FModelTP, IFormItemCommonProps>
                label={props.label}
                onChange={handleChange}
                fieldName={props.fieldName}
                formStateManager={props.formStateManager}
                required={props.required}
                width={props.width}
                value={props.value}
                errorMessage={props.errorMessage}
                noValidation={props.noValidation}
            >
                <Select
                    disabled={props.disabled ?? false}
                    optionFilterProp={'children'}
                    mode={!!props.isMultiple ? 'multiple' : 'default'}
                    loading={props.loading}
                    showSearch={!!props.onSearch}
                    defaultOpen={props.defaultOpen}
                    notFoundContent={props.notFoundContent ?? 'Nenhum item disponível'}
                    onSearch={props.onSearch}
                    onDropdownVisibleChange={isVisible => {
                        if (!isVisible && !!props.onClose)
                            props.onClose()
                    }}
                >
                    {
                        props.options.map((opt: SelectOptionTP) => (
                            <Select.Option key={SelectCPUtils.getOptionKey(opt)} value={SelectCPUtils.getOptionValue(opt)}>
                                {SelectCPUtils.getOptionLabel(opt)}
                            </Select.Option>
                        ))
                    }
                </Select>
            </FormItemICP>
        </SelectWrapperSCP>
    )
}

const SelectWrapperSCP = styled.div`
    width: 100%;
`
