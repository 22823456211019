import React from 'react'

import { SelectCP } from 'common/components/form-fields/select/SelectCP'
import { SelectOptionTP } from 'common/components/form-fields/select/inner/types/SelectOptionTP'
import { IFormItemCommonProps } from 'common/components/form-fields/inner/interfaces/IFormItemCommonProps'
import { GenderEnum } from 'modules/person/enums/GenderEnum'
import { PersonUtils } from 'modules/person/utils/PersonUtils'
import { InputValueCallbackTP } from 'common/components/form-fields/inner/types/InputValueCallbackTP'
import { FormModel } from 'common/form-state-manager/classes/FormModel'
import { PersonTypeEnum } from 'modules/person/enums/PersonTypeEnum'

const OPTIONS: SelectOptionTP[] = Object.values(PersonTypeEnum).map((type, i) => ({
    key: `key-person-type-${i}`,
    label: PersonUtils.getPersonTypeLabel(type),
    value: type
}))

interface IPersonTypeSelectorCPProps<FModelTP extends FormModel = any> extends IFormItemCommonProps<FModelTP> {
    onChange?: InputValueCallbackTP<GenderEnum>
    disabled?: boolean
}

/**
 * COMPONENTE
 * Seletor unitario de tipo de pessoa.
 */
export function PersonTypeSelectorCP<FModelTP extends FormModel = any>(props: IPersonTypeSelectorCPProps<FModelTP>): JSX.Element {
    return (
        <SelectCP<FModelTP>
            label={'Tipo de Pessoa'}
            options={OPTIONS}
            onChange={props.onChange}
            formStateManager={props.formStateManager}
            fieldName={props.fieldName}
            required={props.required}
            errorMessage={props.errorMessage}
            disabled={props.disabled ?? false}
        />
    )
}
