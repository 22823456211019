import React from 'react'

import { IIconProps } from './inner/IIconProps'
import { IconICP } from 'common/components/icons/inner/IconICP'

/**
 * ICONE: pasta
 */
export function FolderIconCP(props: IIconProps): JSX.Element {
    return (
        <IconICP
            iconName={'folder-open'}
            size={props.size}
            color={props.color}
            style={props.style}
            theme={props.theme}
            transitionTime={props.transitionTime}
        />
    )
}
