import { HttpMethodEnum } from 'common/enums/HttpMethodEnum'
import { RequestConfigTP } from 'common/request-manager/types/RequestConfigTP'
import { SystemConfig } from 'config/SystemConfig'

/**
 * REQUISICOES relacionadas ao modulo de Notificacoes
 */
export class NotificationRequests {

    private static readonly _MODULE_BASE = `${SystemConfig.getInstance().apiBaseUrl}/notifications`

    static searchConfig = (): RequestConfigTP => ({
        url: NotificationRequests._MODULE_BASE,
        method: HttpMethodEnum.GET,
    })

    static markAsReadConfig = (code: number): RequestConfigTP => ({
        url: `${NotificationRequests._MODULE_BASE}/${code}/mark-as-read`,
        method: HttpMethodEnum.PUT,
    })

}
