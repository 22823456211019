import React from 'react'
import { Result } from 'antd'
import { HttpStatusEnum } from 'common/enums/HttpStatusEnum'

interface IFallbackScreenContentCPProps {
    status: HttpStatusEnum.FORBIDDEN | HttpStatusEnum.NOT_FOUND | 'success' | 'info' | 'warning' | 'error'
    title: string
    message?: React.ReactNode
    bottomContent?: JSX.Element
}

/**
 * COMPONENTE
 * Monta 01 tela generica parametrizavel para notificar sobre algum problema
 * que fez com que nenhuma tela pudesse ser reenderizada.
 *
 * @author hjcostabr
 */
export function FallbackScreenContentCP(props: IFallbackScreenContentCPProps): JSX.Element {
    return (
        <Result
            status={props.status.toString() as any}
            title={props.title}
            subTitle={props.message}
            extra={props.bottomContent}
        />
    )
}
