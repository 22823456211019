import * as React from 'react'

import styled from 'config/theme/styledWithTheme'
import { LoadingIconCP } from 'common/components/icons/LoadingIconCP'

interface ILoadingOverlayCPProps {
    show: boolean
    size?: number
}

/**
 * Componente de loading que ocupa espaco inteiro do container
 * dentro do qual for inserido.
 *
 * @author Lucas Rosa
 * @author hjcostabr
 */
export function LoadingOverlayCP(props: ILoadingOverlayCPProps): JSX.Element | null {

    if (!props.show)
        return null

    return (
        <LoadingWrapperSCP>
            <LoadingIconCP size={props.size ?? 35}/>
        </LoadingWrapperSCP>
    )
}

const LoadingWrapperSCP = styled.div`
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(255, 255, 255, 0.7);
    z-index: 999999;
`
