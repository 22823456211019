import React from 'react'
import styled from 'config/theme/styledWithTheme'
import { IDocumentForSignResponseDTO } from 'modules/document/services/dtos/response/IDocumentForSignResponseDTO'
import { TextCP } from 'common/components/text/TextCP'
import { DateUtils } from 'common/utils/date/DateUtils'
import { DateFormatEnum } from 'common/enums/DateFormatEnum'
import { TotalAssigneesAndSignedCP } from 'modules/document/components/total-assignees-and-signed/TotalAssigneesAndSignedCP'

interface ISignDocumentHeaderBarCPProps {
    documentForSign: IDocumentForSignResponseDTO
}

/**
 * COMPONENTE Barra do Header da tela de assinatura de contrato.
 */
export function SignDocumentHeaderBarCP(props: ISignDocumentHeaderBarCPProps): JSX.Element {

    return (
        <TitleWrapperSCP>

            <LeftSCP>
                <TextCP text={props.documentForSign.document.name} size={'large'}/>
                <TextCP text={`por ${props.documentForSign.document.ownerPerson.name}`} size={'extraSmall'} style={{ fontStyle: 'italic', marginLeft: '10px' }} color={'#e8e8e8'}/>
                <TextCP text={`em ${DateUtils.getFormatted(props.documentForSign.document.createDate, DateFormatEnum.BR_WITH_TIME_H_M)}`} size={'extraSmall'} style={{ fontStyle: 'italic' }} color={'#cacaca'}/>
            </LeftSCP>

            <TotalAssigneesAndSignedCP assignees={props.documentForSign.document.assignees} showIcon={true}/>

        </TitleWrapperSCP>
    )
}

const TitleWrapperSCP = styled.div`

  margin-bottom: 30px;
  background: ${props => props.theme.primaryColor};
  padding: 20px 40px;
  display: flex;
  justify-content: space-between;
  color: #fff;

`

const LeftSCP = styled.div`

  display: flex;
  justify-content: flex-start;
  align-items: baseline;
  
  .ant-typography {
    margin-right: 5px;
  }

`
