import React, { useEffect, useState } from 'react'
import { ModalCP } from 'common/components/modal/ModalCP'
import { SignatureContentICP } from 'modules/document-signature/components/confirm-signature-modal/inner/SignatureContentICP'
import { LoginContentICP } from 'modules/document-signature/components/confirm-signature-modal/inner/LoginContentICP'
import { AppStateUtils } from 'common/utils/AppStateUtils'
import { SafeFooterCP } from 'modules/document-signature/components/safe-footer/SafeFooterCP'
import { IDocumentForSignResponseDTO } from 'modules/document/services/dtos/response/IDocumentForSignResponseDTO'
import { PersonRegisterWizardCP } from 'modules/person/components/person-register-wizard/PersonRegisterWizardCP'
import { SignatureSecurityLevelEnum } from 'modules/document-signature/enums/SignatureSecurityLevelEnum'

type PageViewTP = 'sign' | 'login' | 'profile'

interface IConfirmSignatureModalCPProps {
    visible: boolean
    onCancel: () => void
    onSign: () => void
    documentToSign?: IDocumentForSignResponseDTO
    loading: boolean
}

/**
 * COMPONENTE: Modal para confirmacao de assinatura.
 */
export function ConfirmSignatureModalCP(props: IConfirmSignatureModalCPProps): JSX.Element {

    const [pageView, setPageView] = useState<PageViewTP>('login')

    useEffect(defineCanSign, [props.visible])

    function defineCanSign(): void {

        if (!props.visible || !props.documentToSign)
            return

        // Se usuario nao tiver logdado, ou se o usuario logado for diferente do usuario informado no token
        if (!AppStateUtils.getLoggedUserData() || AppStateUtils.getLoggedUserData()?.user.code !== props.documentToSign?.personToSign.person.code) {
            setPageView('login')
            return
        }

        // Se tipo de seguranca de assinatura for completa, e usuario for basico. Deve preencher os dados
        if (props.documentToSign?.document.signatureSecurityLevel === SignatureSecurityLevelEnum.FULL_REGISTER && AppStateUtils.getLoggedUserData()!.user.securityLevel !== SignatureSecurityLevelEnum.FULL_REGISTER) {
            setPageView('profile')
            return
        }

        setPageView('sign')
    }

    if (!props.documentToSign)
        return <></>

    return (
        <ModalCP
            visible={props.visible}
            title={pageView === 'profile' ? 'Complete seu cadastro' : 'Assinar Documento'}
            okText={'Assinar'}
            onCancel={props.onCancel}
            onOk={props.onSign}
            width={pageView !== 'profile' ? 380 : undefined}
            top={5}
            loading={props.loading}
            customFooter={
                pageView !== 'sign' ? <SafeFooterCP/> : undefined
            }
        >
            {
                pageView === 'login' &&
                    <LoginContentICP
                        personToSign={props.documentToSign.personToSign}
                        onLoginSuccess={defineCanSign}
                    />
            }

            {
                pageView === 'profile' &&
                    <PersonRegisterWizardCP
                        onDone={() => setPageView('sign')}
                        signatureSecLevel={SignatureSecurityLevelEnum.FULL_REGISTER}
                        personCpf={props.documentToSign?.personToSign.person.cpfCnpj}
                    />
            }

            {
                pageView === 'sign' &&
                    <SignatureContentICP
                        document={props.documentToSign.document}
                    />
            }
        </ModalCP>
    )
}
