/**
 * UTILS
 * Encapsula definicao de metodos genericos uteis para manipulacao
 * de estilos.
 *
 * @todo: Remover codigo duplicado
 *
 * @author Stella
 * @author hjcostabr
 */
export class ColorUtils {

    private constructor() {}

    static lightenColor(color: string, qtd: number): string {

        qtd = Math.abs(qtd)

        let usePound = false

        if (color.startsWith('#')) {
            color = color.slice(1)
            usePound = true
        }

        // tslint:disable-next-line: ban
        const num = parseInt(color, 16)

        // tslint:disable-next-line: no-bitwise
        let r = (num >> 16) + qtd

        if (r > 255)
            r = 255
        else if (r < 0)
            r = 0

        // tslint:disable-next-line: no-bitwise
        let b = ((num >> 8) & 0x00FF) + qtd

        if (b > 255)
            b = 255
        else if (b < 0)
            b = 0

        // tslint:disable-next-line: no-bitwise
        let g = (num & 0x0000FF) + qtd

        if (g > 255)
            g = 255
        else if (g < 0)
            g = 0

        // tslint:disable-next-line: no-bitwise
        return (usePound ? '#' : '') + (g | (b << 8) | (r << 16)).toString(16)
    }

    static darkenColor(color: string, qtd: number): string {

        qtd = Math.abs(qtd) * -1

        let usePound = false

        if (color.startsWith('#')) {
            color = color.slice(1)
            usePound = true
        }

        // tslint:disable-next-line: ban
        const num = parseInt(color, 16)

        // tslint:disable-next-line: no-bitwise
        let r = (num >> 16) + qtd

        if (r > 255)
            r = 255
        else if (r < 0)
            r = 0

        // tslint:disable-next-line: no-bitwise
        let b = ((num >> 8) & 0x00FF) + qtd

        if (b > 255)
            b = 255
        else if (b < 0)
            b = 0

        // tslint:disable-next-line: no-bitwise
        let g = (num & 0x0000FF) + qtd

        if (g > 255)
            g = 255
        else if (g < 0)
            g = 0

        // tslint:disable-next-line: no-bitwise
        return (usePound ? '#' : '') + (g | (b << 8) | (r << 16)).toString(16)
    }
}
