import { HttpMethodEnum } from 'common/enums/HttpMethodEnum'
import { RequestConfigTP } from 'common/request-manager/types/RequestConfigTP'
import { SystemConfig } from 'config/SystemConfig'
import { IPersonChangePasswordRequestDTO } from 'modules/person/services/dtos/request/IPersonChangePasswordRequestDTO'
import { IPersonSaveRequestDTO } from 'modules/person/services/dtos/request/IPersonSaveRequestDTO'
import { PersonDocumentEnum } from '../enums/PersonDocumentEnum'
import { MaskUtils } from 'common/utils/MaskUtils'

/**
 * REQUISICOES relacionadas ao modulo de Usuario
 *
 * @author renatofs
 */
export class PersonRequests {

    private static readonly _MODULE_BASE = `${SystemConfig.getInstance().apiBaseUrl}/people`

    static getChangePasswordConfig = (dto: IPersonChangePasswordRequestDTO): RequestConfigTP<IPersonChangePasswordRequestDTO> => ({
        url: `${PersonRequests._MODULE_BASE}/change-password`,
        method: HttpMethodEnum.PUT,
        params: dto
    })

    static getPersonByCpf = (cpfCnpj: string): RequestConfigTP<null> => ({
        url: `${PersonRequests._MODULE_BASE}/by-cpf-cnpj/${MaskUtils.removeMask(cpfCnpj)}`,
        method: HttpMethodEnum.GET,
    })

    static getPersonSaveConfig = (dto: IPersonSaveRequestDTO): RequestConfigTP<IPersonSaveRequestDTO> => ({
        url: PersonRequests._MODULE_BASE,
        method: HttpMethodEnum.POST,
        params: dto,
    })

    static getPersonInviteConfig = (dto: IPersonSaveRequestDTO): RequestConfigTP<IPersonSaveRequestDTO> => ({
        url: `${PersonRequests._MODULE_BASE}/invite`,
        method: HttpMethodEnum.POST,
        params: dto,
    })

    static getPersonUpdateConfig = (userCode: number, dto: IPersonSaveRequestDTO): RequestConfigTP<IPersonSaveRequestDTO> => ({
        url: `${PersonRequests._MODULE_BASE}/${userCode}`,
        method: HttpMethodEnum.PUT,
        params: dto
    })

    static uploadDocumentConfig = (documentType: PersonDocumentEnum, file: File): RequestConfigTP => {

        const formData = new FormData()
        formData.append('file', file)

        return ({
            url: `${PersonRequests._MODULE_BASE}/${documentType}`,
            method: HttpMethodEnum.POST,
            params: formData
        })
    }

}
