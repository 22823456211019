import React from 'react'
import styled from 'config/theme/styledWithTheme'
import { LogoCP } from 'common/components/logo/LogoCP'

/**
 * Barra com a logo.
 */
export function LogoBarCP(): JSX.Element | null {

    return (
        <LogoWrapperSCP>
            <LogoCP
                height={40}
                type={'logo'}
                marginTop={10}
                marginBottom={10}
            />
        </LogoWrapperSCP>
    )
}

const LogoWrapperSCP = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    background: #fff;
`
