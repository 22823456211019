/**
 * CONFIG
 * Local de definição do tema no sistema. Ao acrescentar alguma variável,
 * acrescentar na interface 'ITheme.ts' o seu nome e tipo para que os styled components
 * possam enxergá-la também.
 *
 * OBSERVACAO: Definir codigos hexadecimais SEMPRE com 6 digitos (senao ha problemas para adicionar
 * opacidade dinamicamente).
 */
const ThemeConfig = {

    // Propriedades customizadas
    primaryDisabledColor: '#5577b5',
    secondaryColor: '#28B9CF',
    secondaryDisabledColor: '#58a37f',
    tertiaryColor: '#ffffff',

    fontSizes: {
        extraSmall: '.6rem',
        small: '.7rem',
        normal: '.85rem',
        large: '1rem',
        extraLarge: '1.3rem',
        title: '2.5rem',
    },

    // Propriedades do tema padrao do ant.d
    primaryColor: '#285688',
    infoColor: '#1aa2c4',
    successColor: '#27ae60',
    nrtPoliticGreen: '#169357',
    nrtPoliticBlue: '#0c3c5e',
    processingColor: '#1aa2c4',
    errorColor: '#c41a1a',
    highlightColor: '#c41a1a',
    warningColor: '#c4ab1a',
    normalColor: '#d9d9d9',
    white: '#ffffff',
    black: '#010102',

    fontFamily: 'Montserrat, sans-serif',
    borderColorBase: '#010102',
    inputBg: 'transparent',
    inputHoverBorderColor: '#010102',
    btnPrimaryBg: '#083991',
    primary6: '#010102',
    linkColor: '#010102',

    tooltipBg: '#28B9CF',
    menuBg: '#285688',
    menuTextColor: '#fff',
    secondMenuBg: '#ffffff',
    layoutBodyBackground: '#F1F1F1',
    layoutHeaderBackground: '#ffffff',
    layoutFooterBackground: '@layout-body-background',
    layoutHeaderHeight: '72px',
    layoutSiderBackground: '@layout-header-background',
}

module.exports = { ThemeConfig }
