import { AppStateUtils } from 'common/utils/AppStateUtils'
import { SystemConfig } from 'config/SystemConfig'
import React from 'react'
import Intercom from 'react-intercom'
import { MaskUtils } from 'common/utils/MaskUtils'
import { InputMaskTypeEnum } from 'common/enums/InputMaskTypeEnum'
import { EnvironmentEnum } from 'common/enums/EnvironmentEnum'
import { PersonTypeEnum } from 'modules/person/enums/PersonTypeEnum'

/**
 * Encapsula dados para integracao com intercom
 *
 * @author renatofs
 */
export function IntercomCP(): JSX.Element {

    const loggedUser = AppStateUtils.getLoggedUserData()

    if (SystemConfig.getInstance().environment !== EnvironmentEnum.PROD)
        return <></>

    if (loggedUser === null)
        return <></>

    if (loggedUser.user.personType === PersonTypeEnum.PF)
        return <></>

    return (
        <Intercom
            appID={SystemConfig.getInstance().intercomId}
            name={loggedUser.user.name}
            email={loggedUser.user.email}
            phone={MaskUtils.applyMask(loggedUser.user.cellPhone || '', InputMaskTypeEnum.PHONE)}
            user_id={`app.dokfy.${loggedUser.user.email || ''}`}
            company={{
                id: 'app.dokfy',
                name: 'Dokfy App',
                sistema: 'Dokfy',
            }}
        />
    )
}
