import { RoutingHelper } from 'common/helpers/RoutingHelper'
import { LoadingScreen } from 'common/screens/LoadingScreen'
import { LoginVerifierCP } from 'modules/app/components/login-verifier/LoginVerifierCP'
import { AuthRoutes } from 'modules/auth/AuthRoutes'
import { RecoverPasswordScreen } from 'modules/auth/screens/RecoverPasswordScreen'
import React, { useEffect, useState } from 'react'
import { Route, Switch } from 'react-router'
import { useHistory, useLocation } from 'react-router-dom'
import { LoginScreen } from 'modules/auth/screens/LoginScreen'
import { DocumentSignatureRoutes } from 'modules/document-signature/routers/DocumentSignatureRoutes'
import { SignDocumentScreen } from 'modules/document-signature/screens/SignDocumentScreen'
import { CertificateDocumentScreen } from 'modules/document-signature/screens/CertificateDocumentScreen'
import { AuthActions } from 'modules/auth/AuthActions'
import { RequestHelper } from 'common/request-manager/RequestHelper'
import { HttpStatusEnum } from 'common/enums/HttpStatusEnum'

/**
 * ROTEADOR
 * Define rotas que podem ser acessadas sem realizar login.
 *
 * OBSERVACAO: Este eh o UNICO componente da aplicacao que deve utilizar o hook
 * 'useHistory' da biblioteca de roteamento.
 *
 * @author hjcostabr
 */
export function PublicRouter(): JSX.Element {

    const [isInitialized, setIsInitialized] = useState<boolean>(false)

    const routeHistory = useHistory()
    const routeLocation = useLocation()

    useEffect(() => { init() }, [routeLocation.pathname])

    async function init(): Promise<void> {

        RoutingHelper.init(routeHistory, [])

        // Add tratamento para expiracao de token de login
        RequestHelper.addErrorCustomAction({
            action: AuthActions.onUnauthorizedRequestResponse,
            httpStatus: HttpStatusEnum.UNAUTHORIZED
        })

        setTimeout(() => setIsInitialized(true), 250)
    }

    if (!isInitialized)
        return <LoadingScreen/>

    return (
        <Switch>
            <Route exact={true} path={AuthRoutes.USER_LOGIN} component={LoginScreen}/>
            <Route exact={true} path={AuthRoutes.RECOVER_PWD} component={RecoverPasswordScreen}/>

            <Route path={DocumentSignatureRoutes.SIGN_WITH_QUERY} component={SignDocumentScreen}/>
            <Route path={DocumentSignatureRoutes.CERTIFICATE_WITH_QUERY} component={CertificateDocumentScreen}/>

            <Route path={'/'} component={LoginVerifierCP}/>
        </Switch>
    )
}
