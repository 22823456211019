import React from 'react'
import { TitleCP } from 'common/components/title/TitleCP'
import { ThemeConfig } from 'config/theme/ThemeConfig'
import { FontSizeTP } from 'config/theme/ThemeTypes'

interface IHeaderTitleICPProps {
    title: string | JSX.Element
    titleSize?: FontSizeTP
    noBackground?: boolean
}

/**
 * COMPONENTE
 * Titulo para cabecalho.
 *
 * @see HeaderCP
 */
export function HeaderTitleICP(props: IHeaderTitleICPProps): JSX.Element {

    if (typeof props.title !== 'string')
        return props.title

    return (
        <TitleCP
            textSize={props.titleSize}
            color={ThemeConfig.primaryColor}
        >
            {props.title}
        </TitleCP>
    )
}
