import { Modal, notification } from 'antd'
import { ArgsProps, NotificationPlacement } from 'antd/lib/notification'
import { ModalFunc, ModalFuncProps } from 'antd/lib/modal/Modal'

type _MsgTP = string | JSX.Element

type _ModalConfirmParamsTP = {
    title: string,
    content: JSX.Element,
    icon?: JSX.Element,
    onOk?: () => void,
    onCancel?: () => void,
    okText?: string,
    cancelText?: string,
}

/**
 * MANAGER
 * Gerencia exibicao de notificacoes, no sistema.
 *
 * @todo: Add opcao 'callback de fechamento'
 *
 * @author guilherme.diniz
 * @author StellaMCR
 * @author hjcostabr
 */
export class NotificationHelper {

    private static readonly _DEFAULT_DURATION = 4.5
    private static readonly _DEFAULT_PLACEMENT = 'topRight' as NotificationPlacement

    private constructor() {}

    /** Gera notificacao de sucesso. */
    static success(title: string, message?: _MsgTP, duration?: number, placement?: NotificationPlacement): void {
        this._showNotification(notification.success, title, message, duration, placement)
    }

    /** Gera notificacao de info. */
    static info(title: string, message?: _MsgTP, duration?: number, placement?: NotificationPlacement): void {
        this._showNotification(notification.info, title, message, duration, placement)
    }

    /** Gera notificacao de alerta. */
    static warning(title: string, message?: _MsgTP, duration?: number, placement?: NotificationPlacement): void {
        this._showNotification(notification.warning, title, message, duration, placement)
    }

    /** Gera notificacao de falha. */
    static error(title: string, message?: _MsgTP, duration?: number, placement?: NotificationPlacement): void {
        this._showNotification(notification.error, title, message, duration, placement)
    }

    /**
     * Exibe 01 modal customizado para forcar 01 interacao com usuario do tipo
     * 'SOLICITACAO DE CONFIRMACAO'
     */
    static showConfirmationModal(config: _ModalConfirmParamsTP): void {
        this._showModal(Modal.confirm, config)
    }

    /**
     * Exibe 01 modal customizado para forcar 01 interacao com usuario do tipo
     * 'NOTIFICACAO DE FALHA'
     */
    static showErrorModal(config: _ModalConfirmParamsTP): void {
        config = {
            okText: 'OK',
            ...config
        }
        this._showModal(Modal.error, config)
    }

    /**
     * Exibe 01 modal customizado para forcar 01 interacao com usuario do tipo
     * 'NOTIFICACAO DE SUCESSO'
     */
    static showSuccessModal(config: _ModalConfirmParamsTP): void {
        config = {
            okText: 'OK',
            ...config
        }
        this._showModal(Modal.success, config)
    }

    /**
     * Exibe 01 modal customizado para forcar 01 interacao com usuario do tipo
     * 'NOTIFICACAO DE ALERTA'
     */
    static showWarningModal(config: _ModalConfirmParamsTP): void {
        config = {
            okText: 'OK',
            ...config
        }
        this._showModal(Modal.warn, config)
    }

    /**
     * Gera modal generico para forcar interacoes com usuario.
     * @see Modal
     */
    private static _showModal(method: ModalFunc, config: ModalFuncProps): void {

        config = {
            okText: 'Sim',
            cancelText: 'Cancelar',
            ...config
        }

        method(config)
    }

    /**
     * Gera 01 notificacao generica.
     * @see NotificationApi
     */
    private static _showNotification(       // eslint-disable-line max-params
        method: (args: ArgsProps) => void,
        title: string,
        message?: _MsgTP,
        duration?: number,
        placement?: NotificationPlacement
    ): void {
        method({
            message: title,
            description: message,
            duration: duration ?? this._DEFAULT_DURATION,
            placement: placement ?? this._DEFAULT_PLACEMENT
        })
    }
}
