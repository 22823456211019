import styled from 'config/theme/styledWithTheme'
import React from 'react'
import { SiderCP } from 'common/components/screen-layout/sider/SiderCP'

import defaultImageUrl from 'modules/auth/components/auth-sider/inner/cover-1.jpg'

interface IAuthScreenCoverCPProps {
    imageUrl?: string
    width?: number | string
}

/**
 * COMPONENTE
 * Imagem de fundo nas telas do modulo de AUTENTICACAO.
 *
 * @author StellaMCR
 * @author hjcostabr
 */
export function AuthSiderCP(props: IAuthScreenCoverCPProps): JSX.Element {
    return (
        <SiderCP width={props.width ?? '50%'}>
            <WrapperSCP>

                <CoverSCP imageUrl={props.imageUrl ?? defaultImageUrl}>
                    <ColorSCP/>
                </CoverSCP>

                <BarSCP/>
            </WrapperSCP>
        </SiderCP>
    )
}

const WrapperSCP = styled.div`
    height: 100%;
    width: 100%;
    position: relative;
`

const CoverSCP = styled.div<{ imageUrl: string }>`
    height: 100%;
    background-image: url(${props => props.imageUrl});
    background-position: center;
    background-size: cover;
    display: flex;
`

const BarSCP = styled.div`
    height: 60%;
    width: 5px;
    background-color: ${props => props.theme.primaryColor};
    position: absolute;
    right: -2px;
    top: 20%;
`
const ColorSCP = styled.div`
    background: radial-gradient(#4082f6, #06327f);
    opacity: 0.5;
    flex: 1;
`
