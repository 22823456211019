import { OrNullTP } from 'common/types/OrNullTP'
import { UserDataTP } from 'common/redux/types/UserDataTP'
import { ReduxHelper } from 'common/redux/helpers/ReduxHelper'
import { OrUndefTP } from 'common/types/OrUndefTP'
import { DomainDataTP } from 'common/redux/types/DomainDataTP'

/**
 * UTILITARIOS
 * Para manipular estado global da aplicacao (redux).
 *
 * OBSERVACAO: Esses metodos nao podem pertencer a utilitaria do redux por problemas
 * de depencia circular com a helper do redux.
 *
 * @see IReduxState
 * @see ReduxUtils
 * @see ReduxHelper
 *
 * @author hjcostabr
 */
export const AppStateUtils = {

    /** Retorna dados do usuario logado atual. */
    getLoggedUserData(): OrNullTP<UserDataTP> {
        return ReduxHelper.getInstance().store.getState().userData
    },

    /** Retorna dados do cliente (schema) a que pertence o usuario logado atual. */
    getDomainData(): OrNullTP<DomainDataTP> {
        return ReduxHelper.getInstance().store.getState().domainData
    },

    /** Retorna nome do schema/slug do dominio atual. */
    getDomainSchema(): OrUndefTP<string> {
        return 'zumbo' // @TODO Simula um schema qualquer no NP. Depois sera criado uma base propria
    },
}
