import React, { useEffect, useState } from 'react'
import { InputCP } from 'common/components/form-fields/input/InputCP'
import { LockIconCP } from 'common/components/icons/LockIconCP'
import { MailIconCP } from 'common/components/icons/MailIconCP'
import { HttpStatusEnum } from 'common/enums/HttpStatusEnum'
import { useFormStateManager } from 'common/form-state-manager/UseFormStateManager'
import { useRequest } from 'common/request-manager/use-request/UseRequest'
import { AuthActions } from 'modules/auth/AuthActions'
import { AuthRequests } from 'modules/auth/AuthRequests'
import { UserLoginFormValidator } from 'modules/auth/components/user-login-form/inner/UserLoginFormValidator'
import { IUserLoginRequestDTO } from 'modules/auth/dtos/request/IUserLoginRequestDTO'
import { IAuthResponseDTO } from 'modules/auth/dtos/response/IAuthResponseDTO'
import { SwitchCP } from 'common/components/switch/SwitchCP'
import { TextCP } from 'common/components/text/TextCP'
import styled from 'styled-components'
import { LoginFormButtonsICP } from 'modules/auth/components/user-login-form/inner/LoginFormButtonsICP'
import { NotificationHelper } from 'common/helpers/NotificationHelper'
import md5 from 'md5'

const LOCAL_STORAGE_EMAIL_KEY = 'LOCAL_STORAGE_EMAIL_KEY'

interface ILoginFormICPProps {
    onLoginSuccess: () => void
    maxWidth?: number
    showRemember: boolean
    showRegister: boolean
    onRecoverPassword?: () => void
    onUserRegister?: () => void
    email?: string
    emailDisabled?: boolean
}

/**
 * COMPONENTE
 * Formulario de login de usuario do sistema (medico / funcionario de clinica).
 *
 * @author Stella
 * @author hjcostabr
 */
export function LoginFormCP(props: ILoginFormICPProps): JSX.Element {

    const [shouldRememberMe, setShouldRememberMe] = useState<boolean>(true)
    const [formValidator, setFormValidator] = useState<UserLoginFormValidator>(new UserLoginFormValidator())

    const formStateManager = useFormStateManager(formValidator)
    const loginRequest = useRequest<IAuthResponseDTO>()

    useEffect(onLoginRequestChange, [loginRequest.isAwaiting])

    useEffect(init, [])

    /**
     * Inicializa dados se tiver no localstorage, ou seja, marcado no lembrar-me
     */
    function init(): void {

        // Se dominio ja tiver enviado da url, nao pega do localstorage
        const rememberedEmail = props.email ?? localStorage.getItem(LOCAL_STORAGE_EMAIL_KEY) ?? undefined

        setFormValidator(new UserLoginFormValidator({
            email: rememberedEmail
        }))

    }

    function onLoginRequestChange(): void {

        if (loginRequest.isAwaiting || !loginRequest.wasTried)
            return

        const isSuccess = (loginRequest.isSuccess && !!loginRequest.responseData)
        if (!isSuccess)
            return handleLoginFailure()

        const resDto = loginRequest.responseData as IAuthResponseDTO

        AuthActions.setLoggedUser(resDto.user, resDto.token)
        props.onLoginSuccess()
    }

    function handleLoginFailure(): void {

        let errorNotification = 'Falha ao tentar realizar login'

        if (loginRequest.responseStatus === HttpStatusEnum.FORBIDDEN)
            errorNotification = 'Domínio inativo ou não reconhecido'

        else if ([HttpStatusEnum.UNAUTHORIZED, HttpStatusEnum.NOT_FOUND].includes(loginRequest.responseStatus!))
            errorNotification = 'E-mail ou senha inválidos'
        else
            console.error('FALHA - UserLoginFormCP.handleLoginFailure: ', 'Falha inesperada', loginRequest.responseData, loginRequest.error)

        NotificationHelper.error('Ops!', errorNotification)
    }

    async function onFormSubmit(): Promise<void> {

        formStateManager.setConsiderAllErrors(true)
        if (!await formStateManager.validate())
            return

        const formValues = formStateManager.getFormValues()!

        // Se tiver marcado para lembrar, guarda informacoes. Se nao limpa dados
        if (shouldRememberMe)
            localStorage.setItem(LOCAL_STORAGE_EMAIL_KEY, formValues.email)
        else
            localStorage.removeItem(LOCAL_STORAGE_EMAIL_KEY)

        const loginDto: IUserLoginRequestDTO = {
            email: formValues.email,
            password: md5(formValues.password)
        }

        loginRequest.runRequest(AuthRequests.getUserLoginReqConfig(loginDto))
    }

    return (
        <FormContainerSCP
            maxWidth={props.maxWidth}
        >
            <InputCP
                label={'E-mail'}
                fieldName={'email'}
                formStateManager={formStateManager}
                required={true}
                onFormSubmit={onFormSubmit}
                disabled={props.emailDisabled}
                icon={<MailIconCP/>}
            />

            <InputCP
                label={'Senha'}
                type={'password'}
                fieldName={'password'}
                formStateManager={formStateManager}
                required={true}
                onFormSubmit={onFormSubmit}
                icon={<LockIconCP/>}
            />

            {
                props.showRemember &&
                <RememberMeSCP>
                    <TextCP text={'Lembrar-me'}/>
                    <SwitchCP
                        isTextInside={false}
                        isChecked={shouldRememberMe}
                        onChange={(isChecked) => setShouldRememberMe(isChecked)}
                    />
                </RememberMeSCP>
            }

            <LoginFormButtonsICP
                showRegister={props.showRegister}
                loading={loginRequest.isAwaiting}
                onFormSubmit={onFormSubmit}
                onUserRegister={props.onUserRegister}
                onRecoverPassword={props.onRecoverPassword}
            />

        </FormContainerSCP>
    )
}

const RememberMeSCP = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  
  .ant-typography {
    margin-right: 10px;
  }
`
const FormContainerSCP = styled.div<{ maxWidth?: number }>`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    max-width: ${props => props.maxWidth ?? 500}px;
`
