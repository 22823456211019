import moment from 'moment-timezone'

import { MonthEnum } from 'common/enums/MonthEnum'
import { DateFormatEnum } from 'common/enums/DateFormatEnum'
import { SystemConfig } from 'config/SystemConfig'
import { OrNullTP } from 'common/types/OrNullTP'
import { TimeBaseEnum } from 'common/enums/TimeBaseEnum'
import { OrUndefTP } from 'common/types/OrUndefTP'
import { DayOfMonthTP } from 'common/types/DayOfMonthTP'
import { momentPtBrLocale } from 'common/utils/date/moment-pt-br-locale'

/**
 * UTILITARIOS
 * Reune metodos genericos uteis para manipulacao de datas.
 */
export const DateUtils = {

    /** Retorna uma instancia de Data de acordo com valor, formato e timezone passados. */
    fixTimeZone(
        date: Date | string,
        format: DateFormatEnum = DateFormatEnum.US_WITHOUT_TIME,
        timezone: string = SystemConfig.getInstance().defaultTimeZone
    ): OrNullTP<Date> {
        const fixedDate = !!date ? moment(moment.tz(date, timezone).format(format)).toDate() : null
        return fixedDate ?? null
    },

    /** Aplica horario da data 1 a data 2. */
    mergeHour(date1: Date, date2: Date): Date {
        const newDate = new Date()
        newDate.setTime(date1.getTime())
        newDate.setHours(date2.getHours(), date2.getMinutes(), date2.getSeconds())
        return newDate
    },

    getFormatted(date: string | Date, format: DateFormatEnum): string {
        moment.locale('pt_BR', momentPtBrLocale)
        return moment(date).format(format)
    },

    /** Transforma uma data, string, de um formato para outro. Ex.: 22/04/1987 para 1987-04-22. */
    transformDateStrFormat(dateStr: string, originalFormat: DateFormatEnum, finalFormat: DateFormatEnum): string {
        return DateUtils.getFormatted(DateUtils.toDate(dateStr, originalFormat), finalFormat)
    },

    /** Transforma string data. */
    toDate(dateStr: string, dateStrFormat: DateFormatEnum): Date {
        return moment(dateStr, dateStrFormat).toDate()
    },

    /** Valida 01 string quanto a representar 01 data valida (formato americano). */
    isValidUSADateString(dateString: string): boolean {
        try {
            if (!dateString.match(/^\d{4}-\d{2}-\d{2}$/))
                return false
            return moment(dateString, DateFormatEnum.US_WITHOUT_TIME).isValid()

        } catch (error) {
            return false
        }
    },

    /** Define HORAS & MINUTOS de 01 data. */
    setTime(date: Date, hours: number, minutes: number): Date {
        return moment(date)
            .set(TimeBaseEnum.HOUR, hours)
            .set(TimeBaseEnum.MINUTE, minutes)
            .toDate()
    },

    /** Obtem hora:minuros do horario de uma data. */
    getTimeStr(date: Date): string {

        const hour = `${moment(date).get(TimeBaseEnum.HOUR)}`.padStart(2, '0')
        const minute = `${moment(date).get(TimeBaseEnum.MINUTE)}`.padStart(2, '0')

        return `${hour}:${minute}`
    },

    /** SOMA valor de tempo a 01 data. */
    add(date: Date, value: number, timeBase: TimeBaseEnum): Date {
        return moment(date).add(value, timeBase).toDate()
    },

    /** SUBTRAI valor de tempo a 01 data. */
    subtract(date: Date, value: number, timeBase: TimeBaseEnum): Date {
        return moment(date).subtract(value, timeBase).toDate()
    },

    /** Retorna diferenca entre data informa e data atual, numa determinada unidade de tempo. */
    getDiff(timeBase: TimeBaseEnum, date: Date, secondDate: Date = new Date()): number {
        return +moment(secondDate).diff(moment(date), timeBase)
    },

    /** Retorna diferenca entre data informa e data atual, numa determinada unidade de tempo. */
    getPresentableDiff(date: Date, secondDate: Date = new Date()): string {

        const yearDiff = DateUtils.getDiff(TimeBaseEnum.YEAR, date, secondDate)
        if (yearDiff > 0)
            return `${yearDiff} ${yearDiff === 1 ? 'ano' : 'anos'}`

        const monthDiff = DateUtils.getDiff(TimeBaseEnum.MONTH, date, secondDate)
        if (monthDiff > 0)
            return `${monthDiff} ${monthDiff === 1 ? 'mês' : 'meses'}`

        const dayDiff = DateUtils.getDiff(TimeBaseEnum.DAY, date, secondDate)
        if (dayDiff > 0)
            return `${dayDiff} ${dayDiff === 1 ? 'dia' : 'dias'}`

        return 'poucas horas'
    },

    /** Retorna hora & minutos extraidos de 01 string que venha na forma [hora]:[minutos]. */
    getHourAndMinutes(timeStr: string): { hour: number, minutes: number } {

        let timeArray: OrUndefTP<[number, number]>
        if (/^((2[0-3])|([01][0-9])):([0-5][0-9])$/.test(timeStr))
            timeArray = timeStr.split(':').map(timeUnit => +timeUnit) as [number, number]

        return {
            hour: timeArray?.[0] ?? 0,
            minutes: timeArray?.[1] ?? 0,
        }
    },

    /** Extrai & retorna mes de 01 data. */
    getMonth(date: Date): MonthEnum {
        return moment(date).month()
    },

    getMonthLabel(month: MonthEnum): string {
        return DateUtils.getFormatted(DateUtils.getDate({ month }), DateFormatEnum.MONTH)
    },

    /** Extrai & retorna dia de 01 mes. */
    getDay(date: Date): DayOfMonthTP {
        return moment(date).date() as DayOfMonthTP
    },

    getDate(params: { day?: DayOfMonthTP, month?: MonthEnum, year?: number }): Date {

        const now = new Date()

        return new Date(
            params.year ?? now.getFullYear(),
            params.month ?? now.getMonth(),
            params.day ?? now.getDate(),
            0,
            0,
            0,
            0,
        )
    }
}
