import React, { useEffect, useState } from 'react'
import { ContentCP } from 'common/components/screen-layout/content/ContentCP'
import { LayoutCP } from 'common/components/screen-layout/layout/LayoutCP'
import styled from 'config/theme/styledWithTheme'
import { useParams } from 'react-router-dom'
import { useRequest } from 'common/request-manager/use-request/UseRequest'
import { DocumentRequests } from 'modules/document/services/DocumentRequests'
import { LoadingOverlayCP } from 'common/components/loaders/loading-overlay/LoadingOverlayCP'
import { LogoBarCP } from 'modules/document-signature/components/logo-bar/LogoBarCP'
import { PdfViewerCP } from 'common/components/pdf-viewer/PdfViewerCP'
import { CertificateDocumentScreenSider } from 'modules/document-signature/components/certificate-document-screen-sider/CertificateDocumentScreenSider'
import { CheckIconCP } from 'common/components/icons/CheckIconCP'
import { TextCP } from 'common/components/text/TextCP'
import { DocumentStatusEnum } from 'modules/document/enums/DocumentStatusEnum'
import { FallbackScreenContentCP } from 'common/components/fallback-screen-content/FallbackScreenContent'
import { IDocumentResponseDTO } from 'modules/document/services/dtos/response/IDocumentResponseDTO'
import { useScreenSize } from 'common/responsiveness/use-screen-size/UseScreenSize'
import { SiderCP } from 'common/components/screen-layout/sider/SiderCP'

/**
 * TELA: Tela para mostrar detalhes do documento assinado.
 */
export function CertificateDocumentScreen(): JSX.Element | null {

    const routeParams = useParams<{documentToken: string}>()
    const screenSize = useScreenSize()

    const [invalidTokenDescription, setInvalidTokenDescription] = useState<string>()
    const [isDocumentSigned, setIsDocumentSigned] = useState<boolean>(false)

    useEffect(init, [])

    const [documentLoaded, setDocumentLoaded] = useState<IDocumentResponseDTO>()
    const getDocumentRequest = useRequest<IDocumentResponseDTO>()
    useEffect(onGetDocumentRequestChange, [getDocumentRequest.isAwaiting])

    /**
     * Inicializa a tela.
     */
    function init(): void {

        const documentToken = routeParams.documentToken
        if (!documentToken)
            setInvalidTokenDescription('Documento inválido')

        getDocumentRequest.runRequest(DocumentRequests.getDocumentByCertificateTokenConfig(documentToken))
    }

    /**
     * Retorno da requisicao para pegar documento.
     */
    function onGetDocumentRequestChange(): void {

        if (getDocumentRequest.isAwaiting || !getDocumentRequest.wasTried)
            return

        const documentResultDto = getDocumentRequest.responseData
        const isSuccess = (getDocumentRequest.isSuccess && !!documentResultDto)
        if (!isSuccess) {
            setInvalidTokenDescription('Documento inválido')
            return
        }

        if (documentResultDto?.status !== DocumentStatusEnum.SIGNED) {
            setInvalidTokenDescription('O documento ainda não foi assinado por todos os signatários')
            return
        }

        setIsDocumentSigned(true)
        setDocumentLoaded(documentResultDto)
    }

    return (
        <>
            <LoadingOverlayCP show={getDocumentRequest.isAwaiting}/>
            <LogoBarCP/>

            {
                isDocumentSigned &&
                <TitleWrapperSCP>
                    <CheckIconCP style={{ marginRight: '10px' }}/>
                    <TextCP text={'Contrato assinado por todos signatários'}/>
                </TitleWrapperSCP>
            }

            <LayoutCP
                sider={
                    screenSize.smd
                        ?
                        undefined
                        :

                        <SiderCP
                            width={600}
                            bgColor={'transparent'}
                            scrollable={true}
                        >
                            <CertificateDocumentScreenSider document={documentLoaded}/>
                        </SiderCP>
                }
                content={
                    <ContentWrapperSCP>
                        <ContentCP>

                            {
                                screenSize.smd &&
                                <CertificateDocumentScreenSider document={documentLoaded}/>
                            }

                            {
                                invalidTokenDescription &&
                                <FallbackScreenContentCP
                                    status={'error'}
                                    title={invalidTokenDescription}
                                />
                            }

                            {
                                documentLoaded &&
                                <PdfViewerCP
                                    file={documentLoaded?.files.signedFileUrl}
                                    paginationType={'scroll'}
                                />
                            }
                        </ContentCP>
                    </ContentWrapperSCP>
                }
            />
        </>
    )
}

const ContentWrapperSCP = styled.div`
    width: 100%;
    margin: 20px 0;
    min-height: 98vh;
`

const TitleWrapperSCP = styled.div`
    background: ${props => props.theme.primaryColor};
    padding: 20px 40px;
    display: flex;
    justify-content: center;
    color: #fff;
`
