import React, { useEffect, useState } from 'react'
import { DrawerCP } from 'common/components/drawer/DrawerCP'
import { useRequest } from 'common/request-manager/use-request/UseRequest'
import { RequestUtils } from 'common/request-manager/RequestUtils'
import { IDocumentResponseDTO } from 'modules/document/services/dtos/response/IDocumentResponseDTO'
import { DocumentRequests } from 'modules/document/services/DocumentRequests'
import { AssigneeTableCP } from 'modules/document/components/assignee-table/AssigneeTableCP'
import { DocumentDetailsCP } from 'modules/document/components/document-details/DocumentDetailsCP'
import { DocumentDrawerButtonsCP } from 'modules/document/components/document-file-buttons/DocumentFileButtonsCP'

interface IDocumentDrawerCPProps {
    visible: boolean
    onClose: () => void
    documentCode?: number
}

/**
 * Drawer de detalhes de um document.
 */
export function DocumentDrawerCP(props: IDocumentDrawerCPProps): JSX.Element {

    const [documentLoaded, setDocumentLoaded] = useState<IDocumentResponseDTO>()
    const getDocumentRequest = useRequest<IDocumentResponseDTO>()
    useEffect(onGetDocumentRequestChange, [getDocumentRequest.isAwaiting])
    useEffect(initialize, [props.visible])

    /**
     * Inicializa a tela.
     */
    function initialize(): void {

        if (!props.visible || !props.documentCode)
            return

        getDocumentRequest.runRequest(DocumentRequests.getDocumentConfig(props.documentCode))
    }

    /**
     * Retorno da requisicao para pegar documento passada como parametro
     */
    function onGetDocumentRequestChange(): void {

        if (!RequestUtils.isValidRequestReturn(getDocumentRequest, 'Erro obter documento'))
            return

        setDocumentLoaded(getDocumentRequest.responseData)
    }

    return (
        <DrawerCP
            title={'Detalhes do Documento'}
            visible={props.visible}
            onClose={props.onClose}
            loading={getDocumentRequest.isAwaiting}
        >
            {
                documentLoaded &&
                    <>
                        <DocumentDrawerButtonsCP
                            originalFile={documentLoaded?.files?.originalFileUrl}
                            signedFile={documentLoaded?.files?.signedFileUrl}
                        />

                        <DocumentDetailsCP
                            marginTop={20}
                            marginBottom={20}
                            document={documentLoaded}
                        />

                        <AssigneeTableCP assignees={documentLoaded.assignees}/>
                    </>
            }

        </DrawerCP>
    )
}
