import React from 'react'
import { AccessControlCP } from 'common/components/access-control/AccessControlCP'
import { RoutingHelper } from 'common/helpers/RoutingHelper'
import { RouteConfigTP } from 'common/types/RouteConfigTP'
import { StringUtils } from 'common/utils/StringUtils'
import { NotificationListScreen } from 'modules/notification/screens/NotificationListScreen'

NotificationRoutes.ROOT = '/caixa-de-entrada'

const ROUTES_CONFIG: RouteConfigTP[] = [
    {
        key: StringUtils.getSlugStyleString(NotificationRoutes.ROOT),
        path: NotificationRoutes.ROOT,
        component: NotificationListScreen,
    },
]

/**
 * ROTEADOR para telas de notificacoes.
 */
export function NotificationRoutes(): JSX.Element {
    return (
        <AccessControlCP>
            {RoutingHelper.renderRoutingSwitch(ROUTES_CONFIG)}
        </AccessControlCP>
    )
}
