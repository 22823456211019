import React from 'react'
import { IIconProps } from './inner/IIconProps'
import { IconICP } from 'common/components/icons/inner/IconICP'

/**
 * ICONE: 'Home'
 *
 * @author hjcostabr
 */
export function HomeIconCP(props: IIconProps): JSX.Element {
    return <IconICP iconName={'home'} {...props}/>  // eslint-disable-line react/jsx-props-no-spreading
}
