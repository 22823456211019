import React from 'react'
import { AuthRoutes } from 'modules/auth/AuthRoutes'
import { RoutingHelper } from 'common/helpers/RoutingHelper'
import { LoginFormCP } from 'modules/auth/components/user-login-form/LoginFormCP'
import { AuthLayoutCP } from 'modules/auth/components/auth-layout/AuthLayoutCP'
import { DocumentRoutes } from 'modules/document/routers/DocumentRoutes'

/**
 * TELA: Login.
 */
export function LoginScreen(): JSX.Element | null {

    return (
        <AuthLayoutCP
            content={
                <LoginFormCP
                    showRegister={false}
                    showRemember={true}
                    onLoginSuccess={() => RoutingHelper.historyReplace(DocumentRoutes.ROOT, true)}
                    onRecoverPassword={() => RoutingHelper.historyPush(AuthRoutes.RECOVER_PWD)}
                />
            }
        />
    )
}
