import { Popover } from 'antd'
import { AvatarCP } from 'common/components/avatar/AvatarCP'
import styled from 'config/theme/styledWithTheme'
import React, { useState } from 'react'

interface IProfileMenuItemICPProps {
    profileImage?: string
    profileOptions: Array<{ text: string, action: () => void }>
    optionTitle: string
}

/**
 * Foto e menu de profile no final do menu.
 *
 * @author Lucas Rosa
 */
export function ProfileMenuItemICP(props: IProfileMenuItemICPProps): JSX.Element {
    const [isVisible, setIsVisible] = useState(false)
    return (
        <Popover
            trigger={'click'}
            placement={'rightTop'}
            visible={isVisible}
            onVisibleChange={setIsVisible}
            title={<TitleSCP>{props.optionTitle}</TitleSCP>}
            content={
                <OptionsWrapperSCP>
                    {
                        props.profileOptions.map(opt => (
                            <OptionSCP
                                onClick={() => {
                                    setIsVisible(false)
                                    opt.action()
                                }}
                                key={opt.text}
                            >
                                {opt.text}
                            </OptionSCP>
                        ))
                    }
                </OptionsWrapperSCP>
            }
        >

            <ProfileAvatarSCP>
                <AvatarCP
                    size={'large'}
                    src={props.profileImage}
                    name={props.optionTitle}
                />
            </ProfileAvatarSCP>

        </Popover>
    )
}

const TitleSCP = styled.div`
    font-weight: 700;
    padding: 0.6rem 0;
    color: ${props => props.theme.primaryColor};
`

const OptionSCP = styled.p`
    cursor: pointer;
    font-weight: 700;
`

const OptionsWrapperSCP = styled.div`
    box-sizing: border-box;
`

const ProfileAvatarSCP = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-bottom: 10px;
    cursor: pointer;
`
