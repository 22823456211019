import cep from 'cep-promise'
import { IFormItemCommonProps } from 'common/components/form-fields/inner/interfaces/IFormItemCommonProps'
import { InputCP } from 'common/components/form-fields/input/InputCP'
import { ZipCodeResponseAddressTP } from 'common/components/form-fields/zip-code-search-input/inner/ZipCodeResponseAddressTP'
import { InputMaskTypeEnum } from 'common/enums/InputMaskTypeEnum'
import { OrUndefTP } from 'common/types/OrUndefTP'
import React, { useState } from 'react'
import { FormModel } from '../../../form-state-manager/classes/FormModel'

interface IZipCodeSearchInputCPProps<FModelTP extends FormModel> extends IFormItemCommonProps<FModelTP> {
    onSearchEnd?: (address?: ZipCodeResponseAddressTP) => void
    onSearchStart?: () => void
    isRequired?: boolean
}

/**
 * COMPONENTE
 * Input para CEP - Utiliza o cep promise para busca do endereço completo.
 */
export function ZipCodeSearchInputCP<FModelTP extends FormModel>(props: IZipCodeSearchInputCPProps<FModelTP>): JSX.Element {

    const [isLoading, setLoading] = useState<boolean>(false)

    function handleChange(zipCode?: string): void {

        const stringLength = zipCode?.replace(/\D/g, '').length ?? 0

        if (stringLength >= 8)
            searchZipCode(zipCode!)

        else if (!stringLength)
            updateAddressFormState()
    }

    async function searchZipCode(zipCode: string): Promise<void> {

        setLoading(true)
        if (!!props.onSearchStart)
            props.onSearchStart()

        let foundAddress: OrUndefTP<ZipCodeResponseAddressTP>

        try {
            foundAddress = await cep(zipCode)
            updateAddressFormState(foundAddress)

        } catch (error) {
            console.error(`FALHA ${ZipCodeSearchInputCP.name}.${searchZipCode.name} (cep "${zipCode}"): `, error)

        } finally {
            props.onSearchEnd?.(foundAddress)
            setLoading(false)
        }
    }

    function updateAddressFormState(address?: ZipCodeResponseAddressTP): void {

        const hasStateManager = (props.formStateManager && props.fieldName)
        if (!hasStateManager)
            return

        const addressFields: Array<keyof ZipCodeResponseAddressTP> = [
            'city',
            'state',
            'street',
            'neighborhood',
        ]

        addressFields.forEach(addressField => {
            const newValue = address ? address[addressField] : ''
            const mustUpdate = (address && newValue) || !address
            if (mustUpdate)
                props.formStateManager?.changeFieldValue(addressField as keyof FModelTP, newValue)
        })
    }

    return (
        <InputCP
            label={'CEP'}
            type={'search'}
            loading={isLoading}
            required={props.isRequired}
            onChange={handleChange}
            mask={InputMaskTypeEnum.CEP}
            formStateManager={props.formStateManager}
            fieldName={props.fieldName}
            errorMessage={props.errorMessage}
        />
    )
}
