import { Alert } from 'antd'
import * as React from 'react'
import { AlertCPTypeTP } from 'common/components/alert/inner/AlertCPTypeTP'
import styled from 'config/theme/styledWithTheme'

type _ScpPropsTP = {
    marginRight?: number,
    marginLeft?: number,
    marginTop?: number,
    marginBottom?: number,
}

interface IAlertCPProps extends _ScpPropsTP {
    message: string | JSX.Element
    description?: string | JSX.Element
    type: AlertCPTypeTP
}

/**
 * COMPONENTE
 * Exibe mensagem estatica para notificacao de forma destacada.
 *
 * @author renatofs
 * @author hjcostabr
 */
export function AlertCP(props: IAlertCPProps): JSX.Element {
    return (
        <WrapperSCP
            marginTop={props.marginTop ?? 0}
            marginRight={props.marginRight ?? 0}
            marginBottom={props.marginBottom ?? 20}
            marginLeft={props.marginLeft ?? 0}
        >
            <Alert
                message={props.message}
                description={props.description}
                type={props.type}
            />
        </WrapperSCP>
    )
}

const WrapperSCP = styled.div<_ScpPropsTP>`
    margin: ${props => `${props.marginTop!}px ${props.marginRight!}px ${props.marginBottom!}px ${props.marginLeft!}px`};
`
