import { Dispatch, Middleware, Reducer } from 'redux'
import { IAction } from 'common/redux/interfaces/IAction'
import { ActionMiddlewareHandlerTP } from 'common/redux/types/ActionMiddlewareHandlerTP'
import { OrNullTP } from 'common/types/OrNullTP'

/**
 * REDUX
 * Define metodos uteis para lidar com controle de estado de aplicacao
 * via Redux.
 *
 * @author hjcostabr
 */
export const ReduxUtils = {

    /**
     * Gera & retorna dinamicamente 01 funcao do tipo 'reducer' que funciona
     * substituindo o valor de 01 chave do estado da aplicacao pelo que quer
     * que seja recebido como 'payload' de sua respectiva 'action'.
     */
    getPayloadReplacingReducer<ValueTP>(type: string, initialState?: ValueTP): Reducer<OrNullTP<ValueTP>> {
        return (state = initialState ?? null, action: IAction): OrNullTP<ValueTP> =>
            ((action.type === type) ? action.payload : state)
    },

    /**
     * Gera & retorna 01 middleware para ciclo de tratamento de actions do redux.
     * Pode ser executado na ocorrencia de 01 action especifica OU na ocorrencia
     * de qualquer action.
     */
    createMiddleware<PayloadTP = any>(handler: ActionMiddlewareHandlerTP<PayloadTP>, actionType?: string): Middleware {

        return store => (next: Dispatch) => (action: IAction<PayloadTP>) => {

            const result = next(action)

            if (!actionType || action.type === actionType)
                handler(action, store.getState())

            return result
        }
    },
}
