import { AddressTypeEnum } from 'common/components/form-fields/address-type-select/inner/AddressTypeEnum'
import { GenderEnum } from 'modules/person/enums/GenderEnum'
import { NacionalityEnum } from 'modules/person/enums/NacionalityEnum'
import { PersonTypeEnum } from 'modules/person/enums/PersonTypeEnum'
import { PersonFormModel } from 'modules/person/models/PersonFormModel'

/**
 * DTO para as requisições de salvar pessoa.
 */
export class PersonSaveRequestDTO {

    name: string
    email: string
    cpfCnpj: string

    personType?: PersonTypeEnum
    birthDate?: string
    nacionality?: NacionalityEnum
    gender?: GenderEnum
    homePhone?: string
    cellPhone?: string

    zipCode?: string
    street?: string
    number?: string
    complement?: string
    neighborhood?: string
    city?: string
    state?: string
    addressType?: AddressTypeEnum

    constructor(formData: PersonFormModel) {

        const dto = {}
        Object.keys(formData).forEach(key => {
            if (formData[key] !== '')
                dto[key] = formData[key]
        })

        this.name = formData.name
        this.email = formData.email
        this.cpfCnpj = formData.cpfCnpj

        this.personType = formData.personType ?? null
        this.birthDate = formData.birthDate ?? null
        this.nacionality = formData.nacionality ?? null
        this.gender = formData.gender ?? null
        this.homePhone = formData.homePhone ?? null
        this.cellPhone = formData.cellPhone ?? null

        this.zipCode = formData.zipCode ?? undefined
        this.street = formData.street ?? undefined
        this.number = formData.number ?? undefined
        this.complement = formData.complement ?? undefined
        this.neighborhood = formData.neighborhood ?? undefined
        this.city = formData.city ?? undefined
        this.state = formData.state ?? undefined
        this.addressType = formData.addressType ?? undefined
    }
}
