import * as React from 'react'
import styled from 'config/theme/styledWithTheme'
import { AlertCP } from 'common/components/alert/AlertCP'
import { isChrome } from 'react-device-detect'

import chromeIcon from 'common/components/browser-warning/inner/chrome_icon.png'
import { useScreenSize } from 'common/responsiveness/use-screen-size/UseScreenSize'

interface IScpProps {
    marginBottom?: number
}

interface IBrowserWarningCPProps extends IScpProps{
    type: 'alert' | 'topBar'
}

/**
 * COMPONENTE
 * Exibe mensagem estatica para notificacao de forma destacada.
 *
 * @author renatofs
 * @author hjcostabr
 */
export function BrowserWarningCP(props: IBrowserWarningCPProps): JSX.Element {

    const screenSize = useScreenSize()

    function getContent(): JSX.Element {
        return (
            <ContentSCP>
                <img src={chromeIcon} width={30}/>
                <a href={'https://www.google.com.br/chrome/browser/desktop/'} target={'_blank'} rel={'noopener noreferrer'}>
                    O Dokfy é otimizado para o Google Chrome. Clique aqui para baixar.
                </a>
            </ContentSCP>
        )
    }

    if (isChrome)
        return <></>

    return (
        <WrapperSCP
            marginBottom={props.marginBottom}
        >
            {
                props.type === 'alert'
                    ? <AlertCP
                        message={getContent()}
                        type={'warning'}
                    />
                    : <TopBarSCP isSmd={screenSize.smd}>
                        { getContent() }
                    </TopBarSCP>
            }
        </WrapperSCP>
    )
}

const WrapperSCP = styled.div<IScpProps>`
    margin-bottom: ${props => props.marginBottom ? `${props.marginBottom}px` : ''}
`

const ContentSCP = styled.div<IScpProps>`

    a {
        margin-left: 10px;
    }

    img {
        height: 24px;
        width: 24px;
    }
`

const TopBarSCP = styled.div<{ isSmd: boolean }>`
    height: ${props => props.isSmd ? '70px' : '50px'};
    display: flex;
    justify-content: center;
    align-items: center;
`
