import React from 'react'
import { ThemeConfig } from 'config/theme/ThemeConfig'
import { ColumnProps } from 'antd/lib/table'
import { ButtonCP } from 'common/components/button/ButtonCP'
import { MoreContentIconCP } from 'common/components/icons/MoreContentIconCP'
import { EditIconCP } from 'common/components/icons/EditIconCP'
import { FlexCP } from 'common/components/flex/FlexCP'
import { RoutingHelper } from 'common/helpers/RoutingHelper'
import { DocumentSignatureRoutes } from 'modules/document-signature/routers/DocumentSignatureRoutes'
import { IDocumentResponseDTO } from 'modules/document/services/dtos/response/IDocumentResponseDTO'
import { SignatureSecurityLevelIconCP } from 'modules/document-signature/components/signature-security-level-icon/SignatureSecurityLevelIconCP'
import { DocumentStatusCP } from 'modules/document/components/document-status/DocumentStatusCP'
import { MultiAssigneeAvatarCP } from 'modules/document/components/multi-assignee-avatar/MultiAssigneeAvatarCP'
import { DateUtils } from 'common/utils/date/DateUtils'
import { DateFormatEnum } from 'common/enums/DateFormatEnum'
import { DocumentUtils } from 'modules/document/utils/DocumentUtils'

/**
 * UTILITARIOS do componente de listagem de documentos
 */
export const DocumentTableUtils = {

    getTableColumnsConfig(
        onSelect: (code: number) => void
    ): Array<ColumnProps<IDocumentResponseDTO>> {

        return [
            {
                title: 'Título',
                dataIndex: 'title',
                key: 'title',
                render: (text, dto) => (
                    <>
                        <FlexCP>
                            <SignatureSecurityLevelIconCP secLevel={dto.signatureSecurityLevel} showTooltip={true} marginRight={5}/>
                            <>{ dto.name }</>
                        </FlexCP>
                        <DocumentStatusCP status={dto.status} marginTop={5}/>
                    </>
                )
            }, {
                title: 'Criado em',
                dataIndex: 'cdate',
                key: 'cdate',
                render: (text, dto) => DateUtils.getFormatted(dto.createDate, DateFormatEnum.BR_WITH_TIME_H_M)
            }, {
                title: 'Responsável',
                dataIndex: 'cdate',
                key: 'cdate',
                render: (text, dto) => dto.ownerPerson.name
            }, {
                title: 'Signatários',
                dataIndex: 'assignees',
                key: 'assignees',
                render: (text, dto) => <MultiAssigneeAvatarCP assignees={dto.assignees}/>
            }, {
                title: '',
                dataIndex: 'act',
                key: 'act',
                align: 'right',
                render: (text, dto) => (
                    <FlexCP justifyContent={'flex-end'}>
                        <>
                            {
                                DocumentUtils.isAwaitngLoggedUserSignature(dto.assignees) &&
                                <ButtonCP
                                    size={'small'}
                                    type={'primary'}
                                    tooltip={'Assinar'}
                                    onClick={() => RoutingHelper.historyPush(`${DocumentSignatureRoutes.SIGN}/${DocumentUtils.getLoggedUserDocumentAssignee(dto.assignees)!.signToken}`)}
                                    marginRight={5}
                                >
                                    <EditIconCP color={ThemeConfig.white} size={14}/>
                                    Assinar
                                </ButtonCP>
                            }
                            <ButtonCP
                                size={'small'}
                                tooltip={'Mais detalhes'}
                                type={'ghost'}
                                onClick={() => onSelect(dto.code)}
                            >
                                <MoreContentIconCP size={14}/>
                            </ButtonCP>
                        </>
                    </FlexCP>
                )
            }
        ]
    }

}
