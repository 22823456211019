import { Radio } from 'antd'
import { RadioChangeEvent } from 'antd/lib/radio'
import Tooltip from 'antd/lib/tooltip'
import { RadioOptionTP } from 'common/components/form-fields/radio-group/inner/RadioOptionTP'
import { IFormStateManager } from 'common/form-state-manager/interfaces/IFormStateManager'
import styled from 'config/theme/styledWithTheme'
import React from 'react'
import { FormModel } from '../../../form-state-manager/classes/FormModel'

type _AntPropsTP = {
    disabled?: boolean,
}

type _ScpPropsTP = {
    buttonWidth?: number,
    buttonHeight?: number,
    secondary?: boolean,
}

type _CustomPropsTP<OptionTP, FModelTP extends FormModel> = {
    selected?: OptionTP,
    onChange?: (value: OptionTP) => void,
    fieldName?: keyof FModelTP,
    formStateManager?: IFormStateManager<FModelTP>,
    options: Array<RadioOptionTP<OptionTP>>,
}

const CLASS_SECONDARY_GROUP = 'radio_group_secondary'

interface IRadioGroupCPProps<OptionTP = string, FModelTP extends FormModel = any>
    extends _AntPropsTP, _ScpPropsTP, _CustomPropsTP<OptionTP, FModelTP> {}

/**
 * COMPONENTE
 * Grupo de radio-butons (combo de selecao unica).
 *
 * @author Lucas Rosa
 * @author hjcostabr
 */
export function RadioGroupCP<OptionTP = string, FModelTP extends FormModel = any>(props: IRadioGroupCPProps<OptionTP, FModelTP>): JSX.Element | null {

    const hasStateManager = (!!props.formStateManager && !!props.formStateManager)

    function handleChange(event: RadioChangeEvent): void {

        const nextValue = event.target.value

        if (!!props.onChange)
            props.onChange(nextValue)

        else if (hasStateManager)
            props.formStateManager!.changeFieldValue(props.fieldName!, nextValue)
    }

    return (
        <RadioGroupWrapperSCP
            className={!!props.secondary ? CLASS_SECONDARY_GROUP : ''}
            buttonWidth={props.buttonWidth}
            buttonHeight={props.buttonHeight}
            secondary={props.secondary}
        >
            <Radio.Group
                value={props.selected}
                disabled={props.disabled}
                onChange={handleChange}
            >
                {
                    props.options.map((opt, index) => (
                        <Tooltip
                            title={opt.tooltip}
                            key={`opt-radio-group-${((opt.value ?? '') as string || index).toString()}`}
                        >
                            <Radio.Button value={opt.value} disabled={opt.disabled}>
                                {opt.content}
                            </Radio.Button>
                        </Tooltip>
                    ))
                }
            </Radio.Group>
        </RadioGroupWrapperSCP>
    )
}

const QUERY_BTN_CHECKED = '.ant-radio-button-wrapper.ant-radio-button-wrapper-checked'
const QUERY_BTN_UNCHECKED = '.ant-radio-button-wrapper:not(.ant-radio-button-wrapper-checked)'

const RadioGroupWrapperSCP = styled.div<_ScpPropsTP>`

    .ant-radio-group {
        display: flex;
    }

    .ant-radio-button-wrapper {
        display: flex;
        width: ${props => (!!props.buttonWidth ? `${props.buttonWidth}px` : 'unset')};
        height: ${props => (!!props.buttonHeight ? `${props.buttonHeight}px` : 'unset')};
        align-items: center;
        justify-content: center;
    }

    
    &.${CLASS_SECONDARY_GROUP} ${QUERY_BTN_CHECKED},    /* Para botao secundario, cor do item selecionado eh 'branca'. */
    &:not(.${CLASS_SECONDARY_GROUP}) ${QUERY_BTN_UNCHECKED} {
        
        color: ${props => props.theme.black};

        &:hover {
            background: #adadad;
        }
        &:not(:hover) {
            border-color: #adadad;
        }
    }

    &.${CLASS_SECONDARY_GROUP} ${QUERY_BTN_UNCHECKED},  /* Para botao secundario, cor do item NAO-selecionado eh 'colorida'. */
    &:not(.${CLASS_SECONDARY_GROUP}) ${QUERY_BTN_CHECKED} {
        
        &:not(:hover) {
            
            color: ${props => props.theme.white};
            border-color: #adadad;

            &:not(.ant-radio-button-wrapper-disabled) {
                background: ${props => (!!props.secondary ? props.theme.secondaryColor : props.theme.primaryColor)};
            }

            &.ant-radio-button-wrapper-disabled {
                background: ${props => (!!props.secondary ? props.theme.secondaryDisabledColor : props.theme.primaryDisabledColor)};
            }
        }

        &:hover {
            color: ${props => props.theme.black};
            background: #adadad;
        }
    }
`
