import React from 'react'
import styled from 'config/theme/styledWithTheme'
import { AvatarCP } from 'common/components/avatar/AvatarCP'
import { IDocumentAssigneeResponseDTO } from 'modules/document/services/dtos/response/IDocumentAssigneeResponseDTO'
import { ThemeConfig } from 'config/theme/ThemeConfig'
import { TooltipCP } from 'common/components/tooltip/TooltipCP'
import { DateUtils } from 'common/utils/date/DateUtils'
import { DateFormatEnum } from 'common/enums/DateFormatEnum'
import { DocumentStatusEnum } from 'modules/document/enums/DocumentStatusEnum'

interface IAssigneeAvatarCPProps {
    assignee: IDocumentAssigneeResponseDTO
    showTooltip: boolean
}

/**
 * COMPONENTE avatares dos que signatarios.
 */
export function AssigneeAvatarCP(props: IAssigneeAvatarCPProps): JSX.Element {

    function getAvatar(): JSX.Element {
        const avatarColor = props.assignee.signed === DocumentStatusEnum.SIGNED ?
            ThemeConfig.primaryColor
            : props.assignee.signed === DocumentStatusEnum.AWAITING_SIGNATURE ?
                ThemeConfig.warningColor
                : ThemeConfig.errorColor

        return (
            <AvatarCP
                size={'default'}
                name={props.assignee.person.name}
                backgroundColor={avatarColor}
                border={'solid 1px #FFF'}
            />
        )
    }

    function getTooltipText(assignee: IDocumentAssigneeResponseDTO): JSX.Element {
        return (
            <>
                { assignee.person.name }
                <TooltipDescSCP>
                    {
                        assignee.signed === DocumentStatusEnum.SIGNED ?
                            `Assinado em ${DateUtils.getFormatted(assignee.signedDate!, DateFormatEnum.BR_WITH_TIME_H_M)} através do IP ${assignee.signedIp!}`
                            :
                            assignee.signed === DocumentStatusEnum.AWAITING_SIGNATURE ?
                                'Aguardando assinatura'
                                : `Recusado em ${DateUtils.getFormatted(assignee.signedDate!, DateFormatEnum.BR_WITH_TIME_H_M)} através do IP ${assignee.signedIp!}`
                    }
                </TooltipDescSCP>
            </>
        )
    }

    return (
        <>
            {
                props.showTooltip
                    ?
                    <TooltipCP
                        text={getTooltipText(props.assignee)}
                        showSpan={true}
                    >
                        { getAvatar() }
                    </TooltipCP>
                    :
                    getAvatar()
            }
        </>
    )
}

const TooltipDescSCP = styled.div`
  font-size: 13px;
  font-style: italic;
`
