import React from 'react'
import styled from 'config/theme/styledWithTheme'
import { ButtonCP } from 'common/components/button/ButtonCP'
import { LinkCP } from 'common/components/link/LinkCP'

interface IAuthFormFooterCPProps {
    link: { text: string, onClick: () => void }
    button: { text: string, onClick: () => void }
    awaiting: boolean
}

/**
 * COMPONENTE
 * Rodape para formlarios do modulo de AUTENTICACAO.
 *
 * @author hjcostabr
 */
export function AuthFormFooterCP(props: IAuthFormFooterCPProps): JSX.Element {

    return (
        <WrapperSCP>

            <LinkCP text={props.link.text} onClick={props.link.onClick}/>

            <ButtonCP
                size={'large'}
                type={'primary'}
                isSubmit={true}
                loading={props.awaiting}
                onClick={props.button.onClick}
            >
                {props.button.text}
            </ButtonCP>
        </WrapperSCP>
    )
}

const WrapperSCP = styled.div`
    margin-top: 2em;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
`
