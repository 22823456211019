/* eslint-disable no-console */
import React from 'react'
import { PersonDataContentICP } from 'modules/person/components/person-register-wizard/inner/PersonDataContentICP'
import { IWizardSteps } from 'common/components/wizard/inner/IWizardSteps'
import { PersonDocumentsContentICP } from 'modules/person/components/person-register-wizard/inner/PersonDocumentsContentICP'
import { AddressPanelCP } from 'modules/person/components/address-panel/AddressPanelCP'
import { IFormStateManager } from 'common/form-state-manager/interfaces/IFormStateManager'
import { PersonFormModel } from 'modules/person/models/PersonFormModel'

/**
 * UTILITARIOS para Wizard de Cadastro de pessoas.
 */
export const PersonRegisterWizardUtils = {

    getPersonDataStep(formStateManager: IFormStateManager<PersonFormModel>): IWizardSteps {
        return ({
            key: 'person-data',
            title: 'Dados Pessoais',
            content: <PersonDataContentICP formStateManager={formStateManager}/>,
            nextAction: () => { return true }
        })
    },

    getAddressDataStep(formStateManager: IFormStateManager<PersonFormModel>, onNext: () => Promise<boolean>): IWizardSteps {
        return ({
            key: 'person-address',
            title: 'Endereço',
            content: <AddressPanelCP formStateManager={formStateManager}/>,
            nextAction: onNext,
        })
    },

    getDocumentsDataStep(onAllFilesUploaded: () => void): IWizardSteps {
        return ({
            key: 'person-docs',
            title: 'Documentos',
            content: <PersonDocumentsContentICP onAllFilesUploaded={onAllFilesUploaded}/>,
        })
    },

}
