import React from 'react'

import { IIconProps } from './inner/IIconProps'
import { IconICP } from 'common/components/icons/inner/IconICP'

/**
 * ICONE: Olho
 *
 * @author renatofs
 */
export const EyeIconCP = (props: IIconProps): JSX.Element => {
    return <IconICP iconName={'eye'} {...props}/>
}
