import React from 'react'

import styled, { css } from 'config/theme/styledWithTheme'
import { FontSizeTP } from 'config/theme/ThemeTypes'
import { ComponentUtils } from 'common/utils/ComponentUtils'
import { OuterSpacingTP } from 'common/types/OuterSpacingTP'

type SharedPropsTP = { // Fazem parte tanto das props do componente 'principal' quanto do 'SCP'
    color?: string,
    underLine?: boolean,
    isStrong?: boolean,
    textSize?: FontSizeTP,
    center?: boolean,
}

interface ITitleCPProps extends React.PropsWithChildren<SharedPropsTP & {
    margin?: OuterSpacingTP | string | number,
}> {}

/**
 * COMPONTENTE
 * Exibe texto como 'titulo' de algo.
 */
export function TitleCP(props: ITitleCPProps): JSX.Element {
    return (
        <TitleSCP
            color={props.color}
            isStrong={props.isStrong}
            textSize={props.textSize}
            underLine={props.underLine}
            center={props.center}
            margin={(props.margin && props.margin !== 'normal') ? ComponentUtils.getMarginCssValue(10, props.margin) : '0 0 10px 0'}
        >
            {props.children}
        </TitleSCP>
    )
}

const afterUnderline = css`
    ::after{
        content: '';
        width: 58px;
        height: 2px;
        position: absolute;
        background: ${props => props.theme.primaryColor};
        bottom: -10px;
        left: 0;
        border-radius: 80px;
    }
`

const TitleSCP = styled.h3<SharedPropsTP & { margin: string }>`
    position: relative;
    color: ${props => (props.color ? props.color : props.theme.black)};
    font-weight: ${props => props.isStrong ? 700 : 500};
    font-size: ${props => props.theme.fontSizes[props.textSize ?? 'extraLarge']};
    margin: ${props => props.margin};
    text-align: ${props => props.center ? 'center' : 'unset'};
    ${props => (!!props.underLine ? afterUnderline : '')}
`
