import IMask from 'imask'
import { InputMaskTypeEnum } from 'common/enums/InputMaskTypeEnum'
import { MoneyUtils } from 'common/utils/MoneyUtils'

/**
 * Classe para auxiliar a inserção de máscaras em campos de formulário.
 *
 * @author Stella
 * @author hjcostabr
 */
export class MaskUtils {

    static applyMask(value: string | number, maskType: InputMaskTypeEnum): string | number {

        if (value === '')
            return value

        const valueToMask = value.toString()
        let mask: string

        switch (maskType) {
            case InputMaskTypeEnum.DATE:
                mask = '00/00/0000'
                break
            case InputMaskTypeEnum.CPF:
                mask = '000.000.000-00'
                break
            case InputMaskTypeEnum.PHONE:
                const phoneWithoutMask = valueToMask.length && valueToMask.replace(/\D/g, '')
                mask = phoneWithoutMask[2] === '9' ? '(00) 00000-0000' : '(00) 0000-0000'
                break
            case InputMaskTypeEnum.CEP:
                mask = '00000-000'
                break
            case InputMaskTypeEnum.CNPJ:
                mask = '00.000.000/0000-00'
                break
            case InputMaskTypeEnum.HOUR:
                mask = '00:00'
                break
            case InputMaskTypeEnum.MONEY:
                // @Todo Colocar casas decimais na digitacao
                return (IMask.createMask({
                    mask: 'R$ num',
                    blocks: {
                        num: {
                            mask: Number,
                            thousandsSeparator: '.',
                            scale: 2,
                            padFractionalZeros: false,
                            radix: '.',
                            normalizeZeros: true,
                            mapToRadix: [',']
                        }
                    }
                })).resolve(valueToMask)
            default:
                return value
        }

        return this._maskField(valueToMask, mask)
    }

    static removeMask(value: string): string {
        return value.replace(/\D/g, '')
    }

    static applyMoneyMask(value: number, decimals: number): string {
        if (!value)
            return ''
        const _value = +value

        return `R$ ${MoneyUtils.format(_value.toFixed(decimals))}`
    }

    static applyNumberMask(value?: number, decimals?: number): string {

        if (!value)
            return ''

        return IMask.createMask({
            mask: Number,
            scale: decimals,
            signed: true,
            thousandsSeparator: '.',
            radix: ','
        })
            .resolve(value.toString())
    }

    private static _maskField(value: string, mask: string): string {
        const masked = IMask.createMask({ mask })
        masked.resolve(value)
        return masked.value
    }
}
