import React, { useEffect, useState } from 'react'
import { InputCP } from 'common/components/form-fields/input/InputCP'
import { useFormStateManager } from 'common/form-state-manager/UseFormStateManager'
import { useRequest } from 'common/request-manager/use-request/UseRequest'
import { RequestUtils } from 'common/request-manager/RequestUtils'
import { NotificationHelper } from 'common/helpers/NotificationHelper'
import { ModalCP } from 'common/components/modal/ModalCP'
import { IPersonChangePasswordRequestDTO } from 'modules/person/services/dtos/request/IPersonChangePasswordRequestDTO'
import { PersonRequests } from 'modules/person/services/PersonRequests'
import { ChangePasswordFormValidator } from 'modules/person/components/change-password-modal/inner/ChangePasswordFormValidator'
import { IAuthUserResponseDTO } from 'modules/auth/dtos/response/IAuthUserResponseDTO'
import { PersonSaveRequestDTO } from 'modules/person/services/dtos/request/PersonSaveRequestDTO'

interface IChangePasswordModalCPProps {
    user: IAuthUserResponseDTO
    visible: boolean
    onClose: () => void
}

/**
 * Modal de alteracao de senha.
 *
 * @author renatofs
 */
export function ChangePasswordModalCP(props: IChangePasswordModalCPProps): JSX.Element {

    const [formValidator] = useState<ChangePasswordFormValidator>(new ChangePasswordFormValidator())
    const formStateManager = useFormStateManager(formValidator)
    const changePasswordRequest = useRequest<PersonSaveRequestDTO>()

    useEffect(onChangePasswordRequestChange, [changePasswordRequest.isAwaiting])

    /**
     * Faz requisicao na API para alteracao de senha.
     */
    async function changePassword(): Promise<void> {

        formStateManager.setConsiderAllErrors(true)
        if (!await formStateManager.validate())
            return

        const formValues = formStateManager.getFormValues()
        if (!formValues)
            return

        if (formValues.newPassword !== formValues.confirmNewPassword) {
            NotificationHelper.error('Ops!', 'A nova senha e a confirmação não estão iguais')
            return
        }

        const dto: IPersonChangePasswordRequestDTO = {
            cpfCnpj: props.user.cpfCnpj,
            newPassword: formValues.newPassword,
            oldPassword: formValues.currentPassword
        }

        changePasswordRequest.runRequest(PersonRequests.getChangePasswordConfig(dto))
    }

    /**
     * Manipula retorno da API
     */
    function onChangePasswordRequestChange(): void {

        if (!RequestUtils.isValidRequestReturn(changePasswordRequest, 'Não foi possível altear a sua senha', true))
            return

        NotificationHelper.success('Sucesso', 'Senha alterada com sucesso!')
        formStateManager.reset()
        props.onClose()
    }

    return (
        <ModalCP
            title={'Alterar Senha'}
            visible={props.visible}
            onOk={changePassword}
            onCancel={props.onClose}
            loading={changePasswordRequest.isAwaiting}
            destroyOnClose={true}
        >
            <InputCP
                fieldName={'currentPassword'}
                formStateManager={formStateManager}
                required={true}
                type={'password'}
                label={'Senha Atual'}
            />

            <InputCP
                fieldName={'newPassword'}
                formStateManager={formStateManager}
                required={true}
                type={'password'}
                label={'Nova senha'}
            />

            <InputCP
                fieldName={'confirmNewPassword'}
                formStateManager={formStateManager}
                required={true}
                type={'password'}
                label={'Confirmar senha'}
            />
        </ModalCP>
    )
}
