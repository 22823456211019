import React, { useState } from 'react'
import { Icon, Steps } from 'antd'
import { ButtonCP } from '../button/ButtonCP'
import styled from 'styled-components'
import { IWizardSteps } from 'common/components/wizard/inner/IWizardSteps'
import { LoadingOverlayCP } from 'common/components/loaders/loading-overlay/LoadingOverlayCP'

// eslint-disable-next-line @typescript-eslint/naming-convention
const { Step } = Steps

interface IWizardCPProps {
    steps: IWizardSteps[]
    action: () => void
    loading?: boolean
    currentStep?: number
}

/**
 * Componente de passo-a-passo
 *
 * @author renatofs
 */
export function WizardCP(props: IWizardCPProps): JSX.Element {

    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [currentStepIndex, setCurrentStepIndex] = useState<number>(props.currentStep ?? 0)

    async function next(customAction?: () => Promise<boolean> | boolean): Promise<void> {

        setIsLoading(true)

        let canGoNext = true
        if (customAction)
            canGoNext = await customAction()

        if (canGoNext)
            setCurrentStepIndex(currentStepIndex + 1)

        setIsLoading(false)
    }

    function prev(): void {
        setCurrentStepIndex(currentStepIndex - 1)
    }

    const currentStep = props.steps[currentStepIndex]

    return (
        <WizardContainerSCP>

            <LoadingOverlayCP show={props.loading || isLoading}/>

            <Steps current={currentStepIndex} progressDot={true}>
                {props.steps.map(item => (
                    <Step key={item.key} title={item.title} icon={item.icon && <Icon type={item.icon}/>}/>
                ))}
            </Steps>

            <ContentSCP>
                {currentStep.content}
            </ContentSCP>

            <ButtonsSCP>

                {currentStepIndex > 0 && (
                    <ButtonCP
                        marginRight={8}
                        onClick={() => prev()}
                    >
                        Voltar
                    </ButtonCP>
                )}

                {currentStepIndex < props.steps.length - 1 && (
                    <ButtonCP
                        type={'primary'}
                        disabled={!!currentStep.canContinue && !currentStep.canContinue()}
                        onClick={() => { next(currentStep.nextAction) }}
                    >
                        Próximo
                    </ButtonCP>
                )}

                {currentStepIndex === props.steps.length - 1 && (
                    <ButtonCP
                        type={'primary'}
                        disabled={!!currentStep.canContinue && !currentStep.canContinue()}
                        onClick={() => {
                            props.action()
                        }}
                    >
                        Confirmar
                    </ButtonCP>
                )}
            </ButtonsSCP>

        </WizardContainerSCP>
    )

}

const WizardContainerSCP = styled.div`
    margin: 10px 0
`

const ContentSCP = styled.div`
    margin-top: 16px;
    border: 1px dashed #e9e9e9;
    background: #fff;
    border-radius: 6px;
    padding: 10px;
`

const ButtonsSCP = styled.div`
    margin-top: 10px;
    display: flex;
    justify-content: flex-end;
`
