import React from 'react'

import { IIconProps } from './inner/IIconProps'
import { IconICP } from 'common/components/icons/inner/IconICP'

/**
 * ICONE: Relogio
 *
 * @author renatofs
 */
export const ClockIconCP = (props: IIconProps): JSX.Element => {
    return <IconICP iconName={'clock-circle'} {...props}/>
}
