import React from 'react'
import { IIconProps } from './inner/IIconProps'
import { IconICP } from 'common/components/icons/inner/IconICP'

/**
 * ICONE: lambada de ideia
 *
 * @author renatofs
 */
export const IdeaIconCP = (props: IIconProps): JSX.Element => {

    return (
        <IconICP
            iconName={'bulb'}
            size={props.size}
            color={props.color}
            style={props.style}
            theme={props.theme}
            transitionTime={props.transitionTime}
        />
    )
}
