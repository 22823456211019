import { MenuItemCP } from 'common/components/menu/menu-item/MenuItemCP'
import { MenuLogoCP } from 'common/components/menu/menu-logo/MenuLogoCP'
import { MenuCP } from 'common/components/menu/menu/MenuCP'
import { RoutingHelper } from 'common/helpers/RoutingHelper'
import { AppStateUtils } from 'common/utils/AppStateUtils'
import { StringUtils } from 'common/utils/StringUtils'
import { MainMenuItemTP } from 'modules/app/components/user-main-menu/inner/MainMenuItemTP'
import { MENU_OPTIONS_TOP } from 'modules/app/components/user-main-menu/inner/MenuOptionsTop'
import { ProfileMenuItemICP } from 'modules/app/components/user-main-menu/inner/ProfileMenuItemICP'
import { AuthActions } from 'modules/auth/AuthActions'
import { AuthRoutes } from 'modules/auth/AuthRoutes'
import { PermissionUtils } from 'modules/auth/utils/PermissionUtils'
import React from 'react'
import { useLocation } from 'react-router-dom'
import { HelpMenuItemICP } from 'modules/app/components/user-main-menu/inner/HelpMenuItemICP'
import styled from 'config/theme/styledWithTheme'
import { useScreenSize } from 'common/responsiveness/use-screen-size/UseScreenSize'
import { PersonRoutes } from 'modules/person/routers/PersonRoutes'
import { NotificationRoutes } from 'modules/notification/routers/NotificationRoutes'
import { BellIconCP } from 'common/components/icons/BellIconCP'
import { ThemeConfig } from 'config/theme/ThemeConfig'
import { PersonTypeEnum } from 'modules/person/enums/PersonTypeEnum'

interface IUserMainMenuCPProps {
    onSelectMenuItem: () => void
}

/**
 * COMPONENTE: Menu lateral principal.
 */
export function UserMainMenuCP(props: IUserMainMenuCPProps): JSX.Element {

    const screenSize = useScreenSize()
    const routeLocation = useLocation()

    function renderMenuItems(options: MainMenuItemTP[]): JSX.Element[] {
        return options
            .filter(option => PermissionUtils.hasAccess(option.module, option.permissions, option.personType))
            .map(option => (
                <MenuItemCP
                    key={`menu-opts-route${StringUtils.getSlugStyleString(option.title)}`}
                    tooltipTitle={option.title}
                    isActive={!!routeLocation.pathname.match(option.route)}
                    onClick={() => {
                        RoutingHelper.historyPush(option.route)
                        props.onSelectMenuItem()
                    }}
                    marginVertical={screenSize.smd ? 20 : 25}
                    dataTour={option.title}
                    badge={option.badge}
                >
                    {option.icon}
                </MenuItemCP>
            ))
    }

    return (
        <MenuContainerSCP>
            <MenuCP selectable={false} inlineCollapsed={true} width={67} dataTour={'main-menu-top'}>
                <MenuLogoCP/>
                {renderMenuItems(MENU_OPTIONS_TOP)}
            </MenuCP>

            <MenuCP selectable={false} inlineCollapsed={true} width={67} dataTour={'main-menu-bottom'}>

                <HelpMenuItemICP/>

                {renderMenuItems([{
                    route: NotificationRoutes.ROOT,
                    title: 'Notificações',
                    icon: <BellIconCP color={ThemeConfig.menuTextColor}/>,
                    badge: AppStateUtils.getLoggedUserData()?.user.totalUnreadNotifications
                }])}

                <ProfileMenuItemICP
                    optionTitle={AppStateUtils.getLoggedUserData()?.user.name ?? 'Usuário'}
                    profileOptions={[
                        {
                            text: AppStateUtils.getLoggedUserData()?.user.personType === PersonTypeEnum.PF ? 'Meu Perfil' : 'Dados da Empresa',
                            action: () => {
                                RoutingHelper.historyPush(PersonRoutes.PROFILE)
                                props.onSelectMenuItem()
                            }
                        }, {
                            text: 'Sair',
                            action: () => {
                                AuthActions.logout()
                                RoutingHelper.historyReplace(AuthRoutes.USER_LOGIN)
                            }
                        }
                    ]}
                />
            </MenuCP>
        </MenuContainerSCP>
    )
}

const MenuContainerSCP = styled.div`
    
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    flex-direction: column;
    height: 100%;
    
    li.ant-menu-item {
      padding: 0px !important;
    }
`
