import React from 'react'
import styled from 'config/theme/styledWithTheme'
import { BrowserWarningCP } from 'common/components/browser-warning/BrowserWarningCP'
import { useScreenSize } from 'common/responsiveness/use-screen-size/UseScreenSize'

/**
 * Area para mensagens do sistema (Como plano, limite de usuarios acabando, etc.)
 *
 * @author renatofs
 */
export function UserSystemMessageCP(): JSX.Element {

    const screenSize = useScreenSize()

    return (
        <WrapperSCP isSmd={screenSize.smd}>

            <BrowserWarningCP type={'topBar'}/>

        </WrapperSCP>
    )
}

const WrapperSCP = styled.div<{ isSmd: boolean }>`
    background: #FFF7D6;
    z-index: 9;
    padding-left: ${props => props.isSmd ? '40px' : ''}
`
