import { Icon } from 'antd'
import { IIconProps } from 'common/components/icons/inner/IIconProps'
import React from 'react'

/**
 * Icon: Download
 * @author Stella
 */

export const DownloadIconCP = (props: IIconProps): JSX.Element => {
    return (
        <Icon
            type={'download'}
            style={{
                color: props.color ?? 'inherit',
                fontSize: props.size,
                ...props.style
            }}
        />
    )
}
