import { useMediaQuery } from 'react-responsive'
import { UseScreenSizeUtils } from './UseScreenSizeUtils'
import { BreakpointsTP } from './BreakpointsTP'

/**
 * HOOK (Responsividade)
 * Expoe flags que representam faixas de tamanho de tela indicando se a largura atual do viewport
 * corresponde a cada uma destas faixas.
 *
 * NOTE: Utiiza mesmo padrao de nomenclatura tradicional do framework Twitter Bootstrap.
 *
 * @see BreakpointsTP
 */
export function useScreenSize(): Record<BreakpointsTP | 'smd', boolean> {
    return {

        /* Tamanhos especificos de tela. */
        xs: useMediaQuery(UseScreenSizeUtils.getBreakpointBounds('xs')),    // Extra Small
        sm: useMediaQuery(UseScreenSizeUtils.getBreakpointBounds('sm')),    // Small
        md: useMediaQuery(UseScreenSizeUtils.getBreakpointBounds('md')),    // Medium
        lg: useMediaQuery(UseScreenSizeUtils.getBreakpointBounds('lg')),    // Large
        xl: useMediaQuery(UseScreenSizeUtils.getBreakpointBounds('xl')),    // Extra large

        /* Entra na condicao, caso a tela seja qualquer tamanho menor do que o estipulado. */
        smd: useMediaQuery(UseScreenSizeUtils.getSmdBounds()),
    }
}
