import { IsOptional } from 'class-validator'
import { AddressTypeEnum } from 'common/components/form-fields/address-type-select/inner/AddressTypeEnum'
import { FormModel } from 'common/form-state-manager/classes/FormModel'
import { IsEmail } from 'common/validation/decorators/IsEmail'
import { IsIn } from 'common/validation/decorators/IsIn'
import { IsNotEmpty } from 'common/validation/decorators/IsNotEmpty'
import { IsString } from 'common/validation/decorators/IsString'
import { IsPhoneBR } from 'common/validation/decorators/phone/IsPhoneBR'
import { GenderEnum } from '../enums/GenderEnum'
import { NacionalityEnum } from '../enums/NacionalityEnum'
import { PersonTypeEnum } from '../enums/PersonTypeEnum'
import { IAddressSaveRequestDTO } from '../services/dtos/request/IAddressSaveRequestDTO'

/**
 * FORMULARIO
 * Modelo que define campos & regras de validacao para formulario de dados de PESSOA.
 *
 * @author Nathan Duarte
 */
export class PersonFormModel extends FormModel implements IAddressSaveRequestDTO {

    @IsString()
    @IsNotEmpty()
    name: string

    @IsOptional()
    @IsIn(Object.values(PersonTypeEnum))
    personType: PersonTypeEnum

    @IsString()
    @IsNotEmpty()
    cpfCnpj: string

    @IsEmail()
    @IsNotEmpty()
    email: string

    @IsOptional()
    birthDate: string

    @IsOptional()
    @IsPhoneBR()
    cellPhone: string

    @IsOptional()
    @IsPhoneBR()
    homePhone: string

    @IsOptional()
    @IsIn(Object.values(NacionalityEnum))
    nacionality: NacionalityEnum

    @IsOptional()
    @IsIn(Object.values(GenderEnum))
    gender: GenderEnum

    @IsOptional()
    @IsString()
    password: string

    @IsOptional()
    @IsString()
    zipCode?: string

    @IsOptional()
    @IsIn(Object.values(AddressTypeEnum))
    addressType?: AddressTypeEnum

    @IsOptional()
    @IsString()
    street?: string

    @IsOptional()
    @IsString()
    number?: string

    @IsOptional()
    @IsString()
    neighborhood?: string

    @IsOptional()
    @IsString()
    complement?: string

    @IsOptional()
    @IsString()
    city?: string

    @IsOptional()
    @IsString()
    state?: string

    constructor(initialData?: Partial<PersonFormModel>) {
        super(initialData)
        Object.assign(this, initialData)
    }
}
