import { IsNotEmpty } from 'common/validation/decorators/IsNotEmpty'
import { FormModel } from 'common/form-state-manager/classes/FormModel'
import { IsEmail } from 'common/validation/decorators/IsEmail'
import { IsValidCpf } from 'common/validation/decorators/IsValidCpf'

export class PersonInviteFormValidator extends FormModel {

    @IsNotEmpty()
    name: string

    @IsNotEmpty()
    @IsEmail()
    email: string

    @IsNotEmpty()
    @IsValidCpf()
    cpf: string

    constructor(initialData?: any) {
        super(initialData)
        Object.assign(this, initialData)
    }

}
