import React from 'react'
import { AccessControlCP } from 'common/components/access-control/AccessControlCP'
import { RoutingHelper } from 'common/helpers/RoutingHelper'
import { RouteConfigTP } from 'common/types/RouteConfigTP'
import { StringUtils } from 'common/utils/StringUtils'
import { PersonProfileScreen } from 'modules/person/screens/PersonProfileScreen'

PersonRoutes.PROFILE = '/perfil'

const PERSON_ROUTES_CONFIG: RouteConfigTP[] = [
    {
        key: StringUtils.getSlugStyleString(PersonRoutes.PROFILE),
        path: PersonRoutes.PROFILE,
        component: PersonProfileScreen,
    },
]

/**
 * ROTEADOR
 * Define & manipula rotas do modulo de PESSOA.
 */
export function PersonRoutes(): JSX.Element {
    return (
        <AccessControlCP>
            {RoutingHelper.renderRoutingSwitch(PERSON_ROUTES_CONFIG)}
        </AccessControlCP>
    )
}
