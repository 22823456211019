import React from 'react'
import { DocumentDetailsCP } from 'modules/document/components/document-details/DocumentDetailsCP'
import { AssigneeTableCP } from 'modules/document/components/assignee-table/AssigneeTableCP'
import { IDocumentResponseDTO } from 'modules/document/services/dtos/response/IDocumentResponseDTO'
import { CardCP } from 'common/components/card/CardCP'
import { DocumentDrawerButtonsCP } from 'modules/document/components/document-file-buttons/DocumentFileButtonsCP'
import { styled } from 'config/theme/styledWithTheme'

interface ICertificateDocumentScreenSiderProps {
    document?: IDocumentResponseDTO
}

/**
 * Barra lateral da tela de certificado do documento.
 */
export function CertificateDocumentScreenSider(props: ICertificateDocumentScreenSiderProps): JSX.Element {

    if (!props.document)
        return <></>

    return (
        <>
            <CardCP title={'Dados do Contrato'}>
                <DocumentDetailsCP
                    document={props.document}
                />
            </CardCP>

            <ButtonsSCP>
                <DocumentDrawerButtonsCP
                    originalFile={props.document?.files?.originalFileUrl}
                    signedFile={props.document?.files?.signedFileUrl}
                />
            </ButtonsSCP>

            <CardCP innerSpacing={'none'}>
                <AssigneeTableCP
                    assignees={props.document.assignees}
                />
            </CardCP>
        </>
    )
}

const ButtonsSCP = styled.div`
  margin: 0 22px;
`
