import { NotificationHelper } from 'common/helpers/NotificationHelper'
import { RoutingHelper } from 'common/helpers/RoutingHelper'
import { ReduxHelper } from 'common/redux/helpers/ReduxHelper'
import { IReduxState } from 'common/redux/interfaces/IReduxState'
import { UserDataTP } from 'common/redux/types/UserDataTP'
import { RequestHelper } from 'common/request-manager/RequestHelper'
import { OrNullTP } from 'common/types/OrNullTP'
import { AppStateUtils } from 'common/utils/AppStateUtils'
import { AuthUtils } from 'common/utils/AuthUtils'
import * as _ from 'lodash'
import { ISchemaResponseDTO } from 'modules/admin/dto/responses/ISchemaResponseDTO'
import { AuthRoutes } from 'modules/auth/AuthRoutes'
import { IAuthUserResponseDTO } from 'modules/auth/dtos/response/IAuthUserResponseDTO'

/**
 * ACTIONS
 * Encapsula definicao de acoes envolvendo estado global (redux) relativas
 * ao modulo de AUTENTICACAO.
 *
 * @author hjcostabr
 */
export class AuthActions {

    static readonly TYPE_UPDATE_USER = 'update_user'
    static readonly TYPE_UPDATE_DOMAIN = 'update_domain'

    private static _handlingUnauthorizedError = false

    private constructor() {}

    /** Encapsula procedimento completo de 'logar' 01 usuario do sistama. */
    static setLoggedUser(user: IAuthUserResponseDTO, token: string): void {
        this._logUserInOrOut(user, token)
    }

    /** Atualiza dados do cliente (schema). */
    static setDomain(schema: ISchemaResponseDTO, mktToken?: string): void {
        ReduxHelper.getInstance().store.dispatch({
            type: this.TYPE_UPDATE_DOMAIN,
            payload: { schema, mktToken }
        })
    }

    /** Encapsula procedimento completo de 'deslogar'. */
    static logout(): void {

        this._logUserInOrOut()

        ReduxHelper.getInstance().store.dispatch({
            type: this.TYPE_UPDATE_DOMAIN,
            payload: null,
        })

        RoutingHelper.setDefaultRoute(undefined)
    }

    /** Action para tratamento erro 401 (nao autorizado) em requisicoes. */
    static onUnauthorizedRequestResponse(): void {

        if (AuthActions._handlingUnauthorizedError)
            return

        const currentState: IReduxState = ReduxHelper.getInstance().store.getState()

        if (!_.isEmpty(currentState.userData)) {
            AuthActions._handlingUnauthorizedError = true
            const durationSeconds = 6
            NotificationHelper.info('Sessão Expirada!', 'Sua sessão expirou. Faça login novamente para prosseguir', durationSeconds)
            setTimeout(() => AuthActions._handlingUnauthorizedError = false, (durationSeconds - 1) * 100)
        }

        AuthActions.logout()
        RoutingHelper.historyReplace(AuthRoutes.USER_LOGIN)
    }

    /**
     * Atualiza propriedades estaticas que guardam o token de autenticacao do
     * usuario atual sincronizando-as com o conteudo mantido no estado global da
     * aplicacao (redux).
     */
    static refreshLoginData(token: string | null): void {

        // @TODO - Coloca um schema fixo por enquanto para fazer as requisicoes no NP
        RequestHelper.addDefaultHeader('schema', 'zumbo')

        if (!!token)
            RequestHelper.addDefaultHeader('Authorization', AuthUtils.getBearerAuthHeaderValue(token))
    }

    /**
     * Realiza procedimentos de login / logout de usuario.
     * SE ambos os parametros forem fornecidos, faz login. Caso contrario faz logout.
     */
    private static _logUserInOrOut(user?: IAuthUserResponseDTO, token?: string, updateDate?: Date): void {

        let payload: OrNullTP<UserDataTP> = null
        const isLogin = (!_.isEmpty(user) && !!token)

        if (isLogin) {
            payload = {
                user: user!,
                token: token!,
                updateDate: !!updateDate ? updateDate : AppStateUtils.getLoggedUserData()?.updateDate
            }
        }

        ReduxHelper.getInstance().store.dispatch({
            type: this.TYPE_UPDATE_USER,
            payload,
        })

        this.refreshLoginData(token ?? null)
    }
}
