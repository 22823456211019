import * as React from 'react'
import styled from 'config/theme/styledWithTheme'
import { ButtonTypeTP } from 'common/components/button/inner/ButtonTypeTP'

interface ILinkHtmlCPProps {
    type: ButtonTypeTP
    href: string
    children: JSX.Element | string | JSX.Element[]
}

/**
 * COMPONENTE: Link.
 *
 * @author StellaMCR
 * @author hjcostabr
 */
export function LinkHtmlCP(props: ILinkHtmlCPProps): JSX.Element {
    return (
        <WrapperSCP>
            <a
                className={`ant-btn ant-btn-${props.type as string} ant-btn-sm`}
                href={props.href}
                target={'_blank'}
                rel={'noopener noreferrer'}
            >
                { props.children }
            </a>
        </WrapperSCP>
    )
}

const WrapperSCP = styled.div`
  
`
