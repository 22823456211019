import React from 'react'
import { ModalCP } from 'common/components/modal/ModalCP'
import { ListCP } from 'common/components/list/ListCP'
import { ListItemICP } from 'common/components/list/inner/ListItemICP'
import { NewDocumentConfirmModalUtils } from 'modules/document-signature/components/new-document-confirm-modal/inner/NewDocumentConfirmModalUtils'
import { ICreateDocumentRequestDTO } from 'modules/document/services/dtos/request/ICreateDocumentRequestDTO'

interface INewShotConfirmModalCPProps {
    visible: boolean
    onConfirm: () => void
    onCancel: () => void
    loading: boolean
    document?: ICreateDocumentRequestDTO
}

/**
 * Modal de confirmacao de submissao de documento para assinatura
 */
export function NewDocumentConfirmModalCP(props: INewShotConfirmModalCPProps): JSX.Element {

    if (!props.document)
        return <></>

    return (
        <ModalCP
            top={10}
            visible={props.visible}
            title={'Confirme os dados antes de enviar o documento para assinatura'}
            onOk={props.onConfirm}
            loading={props.loading}
            onCancel={props.onCancel}
            okText={'Enviar documento'}
        >
            <ListCP
                dataSource={NewDocumentConfirmModalUtils.getConfirmData(props.document)}
                renderItem={
                    item => (
                        <ListItemICP
                            avatar={item.avatar}
                            title={item.title}
                            content={item.value}
                        />
                    )
                }
            />
        </ModalCP>
    )
}
