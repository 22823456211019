import React from 'react'
import { AccessControlCP } from 'common/components/access-control/AccessControlCP'
import { RoutingHelper } from 'common/helpers/RoutingHelper'
import { RouteConfigTP } from 'common/types/RouteConfigTP'
import { StringUtils } from 'common/utils/StringUtils'
import { DocumentListScreen } from 'modules/document/screens/DocumentListScreen'

DocumentRoutes.ROOT = '/documentos'

const ROUTES_CONFIG: RouteConfigTP[] = [
    {
        key: StringUtils.getSlugStyleString(DocumentRoutes.ROOT),
        path: DocumentRoutes.ROOT,
        component: DocumentListScreen,
    },
]

/**
 * ROTEADOR para telas de documento.
 */
export function DocumentRoutes(): JSX.Element {
    return (
        <AccessControlCP>
            {RoutingHelper.renderRoutingSwitch(ROUTES_CONFIG)}
        </AccessControlCP>
    )
}
