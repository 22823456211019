import { FormModel } from 'common/form-state-manager/classes/FormModel'
import { IsNotEmpty } from 'common/validation/decorators/IsNotEmpty'
import { IAssigneeRequestDTO } from 'modules/document/services/dtos/request/IAssigneeRequestDTO'

export class AssigneesContentFormValidator extends FormModel {

    @IsNotEmpty()
    isOrdered: boolean

    @IsNotEmpty()
    assignees: IAssigneeRequestDTO[]

    constructor(initialData?: any) {
        super(initialData)
        Object.assign(this, initialData)
    }

}
