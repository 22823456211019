import { PermissionEnum } from 'modules/auth/enums/PermissionEnum'
import { NotificationHelper } from 'common/helpers/NotificationHelper'
import { ModuleEnum } from 'common/enums/ModuleEnum'
import { LogicOperationTP } from 'common/types/LogicOperationTP'
import { OrNullishTP } from 'common/types/OrNullishTP'
import { AppStateUtils } from 'common/utils/AppStateUtils'
import { PersonTypeEnum } from 'modules/person/enums/PersonTypeEnum'

/**
 * UTILITARIOS: Permissoes de acesso a recursos do sistema.
 *
 * TODO: Concluir implantacao quando passar a processar habilitacao de modulos (alem das permissoes)
 */
export class PermissionUtils {

    /** Exibe notificacao padrao tentativa de acesso a rota nao atorizada. */
    static showDefaultDeniedAccessMsg(): void {
        NotificationHelper.warning('Ops', 'Você não tem permissão para acessar esse módulo, portanto será redirecionado')
    }

    /**
     * Determina se usuario informado tem acesso ao modulo pelo tipo dele.
     */
    static isAvailableByPersonType(personType: PersonTypeEnum): boolean {
        return AppStateUtils.getLoggedUserData()?.user.personType === personType
    }

    /**
     * Determina se usuario informado tem acesso ao modulo informado.
     * TODO: Concluir implantacao quando passar a processar habilitacao de modulos (alem das permissoes)
     */
    static isModuleAvailable(): boolean {
        const userPermissions = AppStateUtils.getLoggedUserData()?.user?.permissions ?? []
        if (!userPermissions.length)
            return false

        return true
    }

    /**
     * Determina se usuario informado possui permissoes informadas (logica 'and').
     * TODO: Concluir implantacao quando passar a processar habilitacao de modulos (alem das permissoes)
     */
    static arePermissionsGranted(permissions: PermissionEnum[], logic: LogicOperationTP = 'or'): boolean {

        const userPermissions = AppStateUtils.getLoggedUserData()?.user?.permissions ?? []
        if (!userPermissions.length)
            return false

        for (const requiredPermission of permissions) {

            let hasPermission = userPermissions.includes(requiredPermission)

            if (hasPermission && requiredPermission === PermissionEnum.ROLE_MARKETING)  // Caso especifico: Marketing
                hasPermission = !!AppStateUtils.getDomainData()?.schema.mktSecret

            if (hasPermission && logic === 'or')
                return true

            if (!hasPermission && logic === 'and')
                return false
        }

        return (logic === 'and')
    }

    /**
     * Determina se usuario informado tem acesso a 01 recurso de acordo com a combinacao de exigencias de
     * habilitacao de modulo & permissoes de acesso.
     */
    static hasAccess(module: OrNullishTP<ModuleEnum>, permissions: OrNullishTP<PermissionEnum[]>, personType: OrNullishTP<PersonTypeEnum>, logic: LogicOperationTP = 'or'): boolean {
        const isModuleOk = (!module || PermissionUtils.isModuleAvailable())
        const isPermissionsOk = (!permissions || PermissionUtils.arePermissionsGranted(permissions, logic))
        const isPersonTypeOk = (!personType || PermissionUtils.isAvailableByPersonType(personType))
        return (isModuleOk && isPermissionsOk && isPersonTypeOk)
    }
}
