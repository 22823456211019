import { List } from 'antd'
import { ColumnProps } from 'antd/lib/table'
import styled from 'config/theme/styledWithTheme'
import React from 'react'

interface IListItemMobileICPProps<RowTP> {
    columns: Array<ColumnProps<RowTP>>
    item: RowTP
}

/**
 * COMPONENTE
 *
 * @author Lucas Rosa
 */
export function ListItemMobileICP<RowTP>(props: IListItemMobileICPProps<RowTP>): JSX.Element {
    return (
        <List.Item>
            <ColumnSCP>
                {props.columns.map((col: ColumnProps<RowTP>, index: number) => (
                    <RowSCP key={col.key}>
                        {col.title &&
                            <TitleWrapperSCP>
                                {col.title}
                            </TitleWrapperSCP>
                        }
                        {col.render ? col.render('', props.item, index) : props.item[col.dataIndex!]}
                    </RowSCP>
                ))}
            </ColumnSCP>
        </List.Item>
    )
}

const RowSCP = styled.div`
    display: flex;
    flex-direction: row;
`
const ColumnSCP = styled.div`
    display: flex;
    flex-direction: column;
`
const TitleWrapperSCP = styled.div`
    margin-right: 15px;
    font-weight: 600;
`