import { Popover } from 'antd'
import styled from 'config/theme/styledWithTheme'
import React, { useState } from 'react'
import { RoutingHelper } from 'common/helpers/RoutingHelper'
import { HelpIconCP } from 'common/components/icons/HelpIconCP'
import { ThemeConfig } from 'config/theme/ThemeConfig'
import { MainMenuItemTP } from 'modules/app/components/user-main-menu/inner/MainMenuItemTP'
import { PermissionUtils } from 'modules/auth/utils/PermissionUtils'
import { MENU_OPTIONS_TOP } from 'modules/app/components/user-main-menu/inner/MenuOptionsTop'
import { IdeaIconCP } from 'common/components/icons/IdeaIconCP'
import { MENU_OPTIONS_BOTTOM } from 'modules/app/components/user-main-menu/inner/MenuOptionsBottom'
import { SystemConfig } from 'config/SystemConfig'
import { EnvironmentEnum } from 'common/enums/EnvironmentEnum'
import { useScreenSize } from 'common/responsiveness/use-screen-size/UseScreenSize'

/**
 * Opções de ajuda dos modulos.
 *
 * @author renatofs
 */
export function HelpMenuItemICP(): JSX.Element {

    const screenSize = useScreenSize()

    const [isVisible, setIsVisible] = useState(false)

    function renderMenuItemsWithTour(options: MainMenuItemTP[]): JSX.Element[] {
        return options
            .filter(option => PermissionUtils.hasAccess(option.module, option.permissions, option.personType) && option.tourId)
            .map(option => (
                <OptionSCP
                    onClick={() => {
                        setIsVisible(false)
                        RoutingHelper.historyPush(`${option.route}?product_tour_id=${option.tourId!}`)
                    }}
                    key={option.title}
                >
                    <IdeaIconCP size={14}/>
                    {option.title}
                </OptionSCP>
            ))
    }

    // Em DEV nao mostra intercom, sendo assim nao deve mostrar ajuda
    if (SystemConfig.getInstance().environment !== EnvironmentEnum.PROD)
        return <></>

    if (screenSize.smd)
        return <></>

    return (
        <Popover
            trigger={'click'}
            placement={'rightTop'}
            visible={isVisible}
            onVisibleChange={setIsVisible}
            title={<TitleSCP>Ajuda</TitleSCP>}
            content={
                <OptionsWrapperSCP>
                    { renderMenuItemsWithTour(MENU_OPTIONS_TOP) }
                    { renderMenuItemsWithTour(MENU_OPTIONS_BOTTOM) }
                </OptionsWrapperSCP>
            }
        >
            <IconWrapperSCP data-tour={'menu-help'}>
                <HelpIconCP color={ThemeConfig.primaryColor}/>
            </IconWrapperSCP>
        </Popover>
    )
}

const TitleSCP = styled.div`
    font-weight: 700;
    padding: 0.6rem 0;
    color: ${props => props.theme.primaryColor};
`

const OptionSCP = styled.p`
    cursor: pointer;
    display: flex;
    
    .anticon {
      margin-right: 5px;
    }
    
    i {
      margin-left: 5px;
    }
`

const OptionsWrapperSCP = styled.div`
    box-sizing: border-box;
`

const IconWrapperSCP = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin: 4px 0;
    padding: 20px 0;
    cursor: pointer;
`
