import React, { useEffect, useState } from 'react'
import { RowCP } from 'common/components/grid/RowCP'
import { InputCP } from 'common/components/form-fields/input/InputCP'
import { InputMaskTypeEnum } from 'common/enums/InputMaskTypeEnum'
import { ModalCP } from 'common/components/modal/ModalCP'
import { ColumnCP } from 'common/components/grid/ColumnCP'
import { AlertCP } from 'common/components/alert/AlertCP'
import { useFormStateManager } from 'common/form-state-manager/UseFormStateManager'
import { PersonInviteFormValidator } from 'modules/person/components/person-invite-modal/inner/PersonInviteFormValidator'
import { useRequest } from 'common/request-manager/use-request/UseRequest'
import { RequestUtils } from 'common/request-manager/RequestUtils'
import { IPersonSaveRequestDTO } from 'modules/person/services/dtos/request/IPersonSaveRequestDTO'
import { PersonRequests } from 'modules/person/services/PersonRequests'
import { IPersonResponseDTO } from 'modules/person/services/dtos/response/IPersonResponseDTO'
import { NotificationHelper } from 'common/helpers/NotificationHelper'
import { MaskUtils } from 'common/utils/MaskUtils'
import { PersonUtils } from 'modules/person/utils/PersonUtils'

interface IPersonInviteModalCPProps {
    visible: boolean
    enteredCpf?: string
    onClose: (person?: IPersonResponseDTO) => void
}

/**
 * COMPONENTE Modal para enviar convite a uma pessoa.
 */
export function PersonInviteModalCP(props: IPersonInviteModalCPProps): JSX.Element | null {

    const [formValidator, setFormValidator] = useState<PersonInviteFormValidator>((new PersonInviteFormValidator()))
    const formStateManager = useFormStateManager<PersonInviteFormValidator>(formValidator)

    useEffect(initialize, [props.visible])

    const savePersonRequest = useRequest<IPersonResponseDTO>()
    useEffect(onSavePersonRequestChange, [savePersonRequest.isAwaiting])

    /**
     * Inicializa a tela
     */
    function initialize(): void {

        if (!props.visible)
            return

        if (props.enteredCpf) {
            setFormValidator(new PersonInviteFormValidator({
                'cpf': props.enteredCpf,
                'name': '',
                'email': ''
            }))
            return
        }

    }

    /**
     * Salva Pessoa
     */
    async function savePerson(): Promise<void> {

        formStateManager.setConsiderAllErrors(true)
        if (!await formStateManager.validate())
            return

        const formValues = formStateManager.getFormValues()
        if (!formValues)
            return

        const dto: IPersonSaveRequestDTO = {
            cpfCnpj: MaskUtils.removeMask(formValues.cpf),
            name: formValues.name,
            email: formValues.email,
            ipAddress: await PersonUtils.getPersonIP()
        }

        savePersonRequest.runRequest(PersonRequests.getPersonInviteConfig(dto))
    }

    /**
     * Apos reotorno da api de salvar pessoa
     */
    function onSavePersonRequestChange(): void {

        if (!RequestUtils.isValidRequestReturn(savePersonRequest, 'Ocorreu algun erro ao adicionar signatário'))
            return

        NotificationHelper.success('Pessoa adicionada com sucesso!')
        props.onClose(savePersonRequest.responseData)
    }

    return (
        <ModalCP
            title={'Convite Dokfy'}
            visible={props.visible}
            onOk={savePerson}
            loading={savePersonRequest.isAwaiting}
            onCancel={() => props.onClose()}
        >
            <AlertCP
                message={'O CPF informado ainda não possui um Dokfy ID, complete as informações abaixo para enviarmos o convite'}
                type={'info'}
            />
            <RowCP>
                <ColumnCP size={24}>
                    <InputCP
                        fontSize={'extraLarge'}
                        label={'Name'}
                        required={true}
                        formStateManager={formStateManager}
                        fieldName={'name'}
                    />
                </ColumnCP>
            </RowCP>

            <RowCP spaceBetweenCols={16}>
                <ColumnCP size={12}>
                    <InputCP
                        label={'CPF'}
                        mask={InputMaskTypeEnum.CPF}
                        required={true}
                        formStateManager={formStateManager}
                        fieldName={'cpf'}
                        disabled={!!props.enteredCpf}
                    />
                </ColumnCP>

                <ColumnCP size={12}>
                    <InputCP
                        label={'E-mail'}
                        required={true}
                        formStateManager={formStateManager}
                        fieldName={'email'}
                    />
                </ColumnCP>
            </RowCP>

        </ModalCP>
    )
}
