import * as _ from 'lodash'
import { IDocumentAssigneeResponseDTO } from 'modules/document/services/dtos/response/IDocumentAssigneeResponseDTO'
import { AppStateUtils } from 'common/utils/AppStateUtils'
import { DocumentStatusEnum } from '../enums/DocumentStatusEnum'

/**
 * UTILITARIOS documentos.
 */
export const DocumentUtils = {

    /**
     * Verifica se usuario logado esta na lista de signatarios e se assinou. Se nao assinou, significa que esta aguardando a assinatura do usuario logado
     */
    isAwaitngLoggedUserSignature(assignees: IDocumentAssigneeResponseDTO[]): boolean {

        const foundPerson = DocumentUtils.getLoggedUserDocumentAssignee(assignees)

        if (!foundPerson)
            return false

        if (foundPerson.signed === DocumentStatusEnum.AWAITING_SIGNATURE)
            return true

        return false
    },

    /**
     * Obtem objeto signatario do usuario logado, caso usuario logado nao esteja na lista de signatarios retorna undefined.
     */
    getLoggedUserDocumentAssignee(assignees: IDocumentAssigneeResponseDTO[]): IDocumentAssigneeResponseDTO | undefined {

        // Filtra lista de signatarios para obter o usuario logado
        const foundPersons = _.filter(assignees, { person: { code: AppStateUtils.getLoggedUserData()!.user.code }})

        // Se encontrou a pessoa, retorna objeto
        if (!_.isEmpty(foundPersons))
            return foundPersons[0]

        return undefined
    },

    /**
     * Obtem o total de pessoas que assinaram ou nao um documento.
     */
    getDocumentTotalPersonSignedOrNot(assignees: IDocumentAssigneeResponseDTO[], signed: DocumentStatusEnum): number {

        const foundAssignees = _.filter(assignees, { signed })

        return !foundAssignees ? 0 : foundAssignees.length
    },

}
