import React from 'react'
import moment from 'moment'
import dateInputLocalePtBR from 'common/components/form-fields/input/inner/components/date/date-locale-pt-br.json'
import { DatePicker } from 'antd'
import { InputDateModeTP } from 'common/components/form-fields/input/inner/types/InputDateModeTP'
import { DatePickerProps } from 'antd/lib/date-picker/interface'
import { OrNullTP } from 'common/types/OrNullTP'

interface IInputDateICPProps {
    suffixIcon: JSX.Element
    mode?: InputDateModeTP

    onChange?: (date?: Date) => void
    onFocus?: () => void
    onBlur?: () => void
    value?: Date
}

/**
 * COMPONENTE
 * Componente seletor de datas aninhado ao componente de input generico.
 *
 * @see InputCP
 */
export function InputDateICP(props: IInputDateICPProps): JSX.Element {

    const pickerProps: DatePickerProps = {
        locale: dateInputLocalePtBR,
        suffixIcon: props.suffixIcon,
        placeholder: undefined,
        value: props.value ? moment(props.value) : undefined,
        onFocus: props.onFocus,
        onBlur: props.onBlur,
        onChange: (date: OrNullTP<moment.Moment>) => props.onChange?.(date?.toDate()),
    }

    return (props.mode === 'month') ? <DatePicker.MonthPicker {...pickerProps}/> : <DatePicker {...pickerProps}/> // eslint-disable-line react/jsx-props-no-spreading
}
