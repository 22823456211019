import React from 'react'
import { Tag } from 'antd'
import { styled } from 'config/theme/styledWithTheme'
import { FontStyleTP } from 'common/types/FontStyleTP'
import { TagCPColorTP } from './inner/TagCPColorTP'

const CLASS_SMALL = 'tag_small'

/** Labels para cores utilizados pelo ant. */
type _AntTagColorTP = 'magenta' | 'red' | 'volcano' | 'orange' | 'gold' | 'lime' | 'green' | 'cyan' | 'blue' | 'geekblue' | 'purple'

interface ITagCPProps {
    content: string | JSX.Element
    color?: TagCPColorTP
    onClose?: () => void
    small?: boolean
    fontStyle?: FontStyleTP
    minWidth?: number
}

/**
 * Componente de tag.
 *
 * @author renatofs
 * @author hjcostabr
 */
export function TagCP(props: ITagCPProps): JSX.Element {

    function getColor(): _AntTagColorTP {
        switch (props.color) {

            case 'pink':
                return 'magenta'

            case 'darkorange':
                return 'volcano'

            case 'yellow':
                return 'gold'

            case 'lightgreen':
                return 'lime'

            case 'lightblue':
                return 'cyan'

            case 'navyblue':
                return 'geekblue'

            case 'red':
            case 'orange':
            case 'green':
            case 'purple':
            case 'blue':
                return props.color

            default:
                return 'blue'
        }
    }

    return (
        <WrapperSCP
            className={!!props.small ? CLASS_SMALL : ''}
            fontStyle={props.fontStyle ?? 'normal'}
            minWidth={props.minWidth}
        >
            <Tag
                closable={!!props.onClose}
                onClose={props.onClose}
                color={getColor()}
            >
                {props.content}
            </Tag>
        </WrapperSCP>
    )
}

const WrapperSCP = styled.div<{ fontStyle: FontStyleTP, minWidth?: number }>`

    display: flex;
    align-items: center;
    text-align: center;

    .ant-tag {
        font-style: ${props => props.fontStyle};
        min-width: ${props => (!!props.minWidth ? props.minWidth : 0)}px;
    }

    &.${CLASS_SMALL} {
        .ant-tag {
            font-size: .65rem;
            padding: 0 4px;
            margin: 1px 2px;
            line-height: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            height: 18px;

            svg {
                height: .7rem;
                width: .7rem;
            }
        }
    }
`
