import { Layout } from 'antd'
import { ButtonCP } from 'common/components/button/ButtonCP'
import { SystemConfig } from 'config/SystemConfig'
import styled, { css } from 'config/theme/styledWithTheme'
import { FontSizeTP } from 'config/theme/ThemeTypes'
import React from 'react'
import { useScreenSize } from 'common/responsiveness/use-screen-size/UseScreenSize'
import { HeaderTitleICP } from 'common/components/screen-layout/header/inner/HeaderTitleICP'

type WrapperSCPPropsTP = {
    noBackground?: boolean,
    titleUnderlineColor?: string,
    titleFlexProp?: number,
}

type CustomPropsTP = {
    title?: string | JSX.Element,
    titleOnSmallDevice?: string | JSX.Element,
    onClickBack?: () => void,
    arrowBack?: boolean,
    titleSize?: FontSizeTP,
    style?: React.CSSProperties,
    tourId?: number,
    showLogoBar?: boolean,
}

interface IHeaderCPProps extends React.PropsWithChildren<WrapperSCPPropsTP & CustomPropsTP> {
}

/**
 * Componente de cabeçalho do sistema.
 *
 * @author Lucas Rosa
 * @author Stella
 * @author hjcostabr
 */
export function HeaderCP(props: IHeaderCPProps): JSX.Element {

    const screenSize = useScreenSize()

    return (
        <>
            {
                !!props.showLogoBar &&
                <LogoMobileWrapperSCP>
                    <LogoMobileImgSCP src={`${SystemConfig.getInstance().uiBaseUrl}/logos/icone-preto.png`}/>
                </LogoMobileWrapperSCP>
            }

            <WrapperSCP
                noBackground={props.noBackground}
                titleUnderlineColor={props.titleUnderlineColor}
                data-tour={'header-cp'}
            >
                <Layout.Header style={props.style}>
                    <LeftContainerSCP titleFlexProp={props.titleFlexProp}>
                        {
                            props.arrowBack &&
                            <ButtonCP
                                onClick={props.onClickBack}
                                ghost={true}
                                type={'primary'}
                                shape={'circle'}
                                icon={'arrow-left'}
                                marginRight={10}
                            />
                        }

                        {
                            !!props.title &&
                            <>
                                {
                                    screenSize.smd &&
                                    <IconMobileImgSCP src={`${SystemConfig.getInstance().uiBaseUrl}/logos/icone-preto.png`}/>
                                }
                                <HeaderTitleICP
                                    title={screenSize.smd ? (props.titleOnSmallDevice ?? props.title) : props.title}
                                    noBackground={props.noBackground}
                                    titleSize={props.titleSize}
                                />
                            </>
                        }
                    </LeftContainerSCP>

                    {props.children}
                </Layout.Header>
            </WrapperSCP>
        </>
    )
}

const LogoMobileWrapperSCP = styled.div`
    display: flex;
    background-color: ${props => props.theme.primaryColor};
    justify-content: center;
    padding: 10px;
`

const LogoMobileImgSCP = styled.img`
    height: 50px;
`

const IconMobileImgSCP = styled.img`
    height: 26px;
    margin: 0 20px;
`

const underlineCSS = css<{ titleUnderlineColor?: string }>`
    ::after{
        content: '';
        width: 58px;
        height: 4px;
        position: absolute;
        background: ${props => props.titleUnderlineColor ?? props.theme.primaryColor};
        bottom: 0;
        left: 25px;
    }
`

const WrapperSCP = styled.div<WrapperSCPPropsTP>`

    .ant-layout-header {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        padding: 0 25px;
        line-height: normal;
        background: ${props => (!!props.noBackground ? 'transparent' : props.theme.layoutHeaderBackground)};
        height: 65px;
    }

    ${props => (!props.noBackground ? 'position: relative;' : '')}
    ${props => (!props.noBackground ? underlineCSS : '')}
`

const LeftContainerSCP = styled.div<WrapperSCPPropsTP>`
    display: flex;
    justify-content: space-between;
    flex: ${props => props.titleFlexProp ?? 'unset'}
`
