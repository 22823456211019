import React from 'react'
import { FlexCP } from 'common/components/flex/FlexCP'
import { LockIconCP } from 'common/components/icons/LockIconCP'
import { TextCP } from 'common/components/text/TextCP'

/**
 * COMPONENTE: Texto de ambiente seguro
 */
export function SafeFooterCP(): JSX.Element {

    return (
        <FlexCP justifyContent={'center'} alignItems={'center'}>
            <LockIconCP size={14} style={{ marginRight: '5px' }}/>
            <TextCP text={'Ambiente Seguro Dokfy'}/>
        </FlexCP>
    )
}
