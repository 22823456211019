import React from 'react'
import { SelectOptionTP } from 'common/components/form-fields/select/inner/types/SelectOptionTP'
import { SelectCP } from 'common/components/form-fields/select/SelectCP'
import { IFormItemCommonProps } from 'common/components/form-fields/inner/interfaces/IFormItemCommonProps'
import { AddressTypeEnum } from './inner/AddressTypeEnum'
import { AddressUtils } from 'common/utils/AddressUtils'

const OPTIONS: SelectOptionTP[] = Object.values(AddressTypeEnum).map((type, i) => ({
    key: `key-address-type-${i}`,
    label: AddressUtils.getAddressTypeLabel(type),
    value: type
}))

interface IAddressTypeSelectCPProps extends IFormItemCommonProps {
    loading?: boolean
}

/**
 * Select tipos de endereço
 *
 * @author Nathan Duarte
 */
export function AddressTypeSelectCP(props: IAddressTypeSelectCPProps): JSX.Element {

    return (
        <SelectCP
            options={OPTIONS}
            label={'Tipo de Endereço'}
            required={props.required}
            formStateManager={props.formStateManager}
            fieldName={props.fieldName}
            errorMessage={props.errorMessage}
            loading={props.loading}
        />
    )
}
