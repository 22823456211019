import React from 'react'
import { AccessControlCP } from 'common/components/access-control/AccessControlCP'
import { RoutingHelper } from 'common/helpers/RoutingHelper'
import { RouteConfigTP } from 'common/types/RouteConfigTP'
import { StringUtils } from 'common/utils/StringUtils'
import { NewDocumentScreen } from 'modules/document-signature/screens/NewDocumentScreen'
import { PersonTypeEnum } from 'modules/person/enums/PersonTypeEnum'

DocumentSignatureRoutes.ROOT = '/novo-documento'

DocumentSignatureRoutes.SIGN = '/assinar-documento'
DocumentSignatureRoutes.SIGN_WITH_QUERY = `${DocumentSignatureRoutes.SIGN}/:signToken`

DocumentSignatureRoutes.CERTIFICATE = '/certificado-documento'
DocumentSignatureRoutes.CERTIFICATE_WITH_QUERY = `${DocumentSignatureRoutes.CERTIFICATE}/:documentToken`

const ROUTES_CONFIG: RouteConfigTP[] = [
    {
        key: StringUtils.getSlugStyleString(DocumentSignatureRoutes.ROOT),
        path: DocumentSignatureRoutes.ROOT,
        component: NewDocumentScreen,
    },
]

/**
 * ROTEADOR para telas de assinatura de documentos.
 */
export function DocumentSignatureRoutes(): JSX.Element {
    return (
        <AccessControlCP personType={PersonTypeEnum.PJ}>
            {RoutingHelper.renderRoutingSwitch(ROUTES_CONFIG)}
        </AccessControlCP>
    )
}
