import { ContentCP } from 'common/components/screen-layout/content/ContentCP'
import { LayoutCP } from 'common/components/screen-layout/layout/LayoutCP'
import { SiderCP } from 'common/components/screen-layout/sider/SiderCP'
import { ThemeConfig } from 'config/theme/ThemeConfig'
import { UserMainMenuCP } from 'modules/app/components/user-main-menu/UserMainMenuCP'
import { PrivateRouter } from 'modules/app/routers/PrivateRouter'
import React, { useState } from 'react'
import { UserSystemMessageCP } from 'modules/app/components/user-system-message/UserSystemMessageCP'
import { useScreenSize } from 'common/responsiveness/use-screen-size/UseScreenSize'
import { ChatBalloonCP } from 'common/components/chat-balloon/ChatBalloonCP'

/**
 * MAIN
 * Template global da area privada do sistema (restrita para usuario logado).
 *
 * @author Lucas Rosa
 * @author Stella
 * @author hjcostabr
 */
export function UserMainLayoutCP(): JSX.Element {

    const screenSize = useScreenSize()
    const [isSiderCollapsed, setIsSiderCollapsed] = useState<boolean>(screenSize.smd)

    function onSelectMenuItem(): void {
        // Se estiver em tela de pequenas, ao clicar no menu encolhe
        if (screenSize.smd)
            setIsSiderCollapsed(true)
    }

    return (
        <LayoutCP
            mainLayout={true}
            content={
                <ContentCP>
                    <UserSystemMessageCP/>

                    <PrivateRouter/>

                    <ChatBalloonCP/>
                </ContentCP>
            }
            sider={
                <SiderCP
                    isMainSider={true}
                    collapsedWidth={0}
                    breakpoint={'sm'}
                    collapsed={isSiderCollapsed}
                    onCollapse={() => setIsSiderCollapsed(!isSiderCollapsed)}
                    zeroWidthTriggerStyle={{
                        backgroundColor: ThemeConfig.primaryColor,
                        left: isSiderCollapsed ? 0 : 67,
                        top: 10,
                        zIndex: 10
                    }}
                >
                    <UserMainMenuCP onSelectMenuItem={onSelectMenuItem}/>
                </SiderCP>
            }
        />
    )
}
