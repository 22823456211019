import * as CPFValidator from '@fnando/cpf'
import { registerDecorator, ValidationOptions, ValidatorConstraint, ValidatorConstraintInterface } from 'class-validator'

import { ValidationTypes } from '../ValidationTypes'

/**
 * VALIDATOR
 * Valida Cpf
 *
 * @author guilherme.diniz
 */
@ValidatorConstraint({ name: ValidationTypes.IS_VALID_CPF })
class IsValidCpfConstraint implements ValidatorConstraintInterface {

    validate(value: string/*, args: ValidationArguments*/): boolean {
        return !value || CPFValidator.isValid(value)
    }

    defaultMessage(/*args: ValidationArguments*/): string {
        return 'Cpf Inválido'
    }
}

/**
 * DECORATOR
 * @param {ValidationOptions} validationOptions
 * @return {(object: Object, propertyName: string) => void}
 * @constructor
 */
export function IsValidCpf(validationOptions?: ValidationOptions) {
    return (object: {}, propertyName: string) => {
        registerDecorator({
            target: object.constructor,
            propertyName,
            options: validationOptions,
            constraints: [],
            validator: IsValidCpfConstraint,
        })
    }
}
