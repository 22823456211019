import React from 'react'
import { RowCP } from 'common/components/grid/RowCP'
import { InputCP } from 'common/components/form-fields/input/InputCP'
import { IFormStateManager } from 'common/form-state-manager/interfaces/IFormStateManager'
import { TextAreaCP } from 'common/components/form-fields/textarea/TextAreaCP'
import { SignatureSecLevelSelectorCP } from 'modules/document-signature/components/signature-sec-level-selector/SignatureSecLevelSelectorCP'
import { DocumentContentFormValidator } from 'modules/document-signature/components/new-document-wizard/inner/DocumentContentFormValidator'

interface IDocumentContentStepICPProps {
    formStateManager: IFormStateManager<DocumentContentFormValidator>
}

/**
 * COMPONENTE da etapa de dados do documento.
 */
export function DocumentContentStepICP(props: IDocumentContentStepICPProps): JSX.Element | null {

    return (
        <>
            <RowCP>
                <InputCP
                    label={'Nome do Documento'}
                    formStateManager={props.formStateManager}
                    required={true}
                    fieldName={'name'}
                />
            </RowCP>
            <RowCP>
                <TextAreaCP
                    label={'Mensagem para Signatários'}
                    formStateManager={props.formStateManager}
                    required={true}
                    fieldName={'message'}
                />
            </RowCP>
            <RowCP>
                <SignatureSecLevelSelectorCP
                    formStateManager={props.formStateManager}
                />
            </RowCP>
        </>
    )
}
