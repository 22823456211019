import { Layout } from 'antd'
import styled from 'config/theme/styledWithTheme'
import { ThemeConfig } from 'config/theme/ThemeConfig'
import React from 'react'

type _ScpPropsTP = {
    isMainSider?: boolean,
    bgColor?: string,
    width?: number | string,
    scrollable?: boolean,
}

type _AntPropsTP = {
    collapsedWidth?: number | string,
    breakpoint?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl',
    zeroWidthTriggerStyle?: React.CSSProperties,
    onCollapse?: () => void,
    trigger?: JSX.Element | null,
    collapsed?: boolean,
    collapsible?: boolean,
}

interface ISiderCPProps extends React.PropsWithChildren<_ScpPropsTP & _AntPropsTP> { }

/**
 * COMPONENTE
 * Barra lateral esquerda generica para compor layouts.
 *
 * @author Lucas Rosa
 * @author hjcostabr
 */
export function SiderCP(props: ISiderCPProps): JSX.Element {

    return (
        <WrapperSCP
            isMainSider={props.isMainSider}
            width={props.width}
            bgColor={props.bgColor}
            scrollable={props.scrollable}
        >
            <Layout.Sider
                collapsedWidth={props.collapsedWidth}
                breakpoint={props.breakpoint}
                zeroWidthTriggerStyle={props.zeroWidthTriggerStyle}
                onCollapse={props.onCollapse}
                trigger={props.trigger}
                collapsed={props.collapsed}
                collapsible={props.collapsible}
                defaultCollapsed={props.collapsed}
                width={'100%'}
            >
                {props.children}
            </Layout.Sider>
        </WrapperSCP>
    )
}

const WrapperSCP = styled.div<_ScpPropsTP>`

    height: ${props => (!!props.isMainSider ? '100vh' : '100%')};
    height: ${props => (!!props.isMainSider ? 'calc(var(--vh, 1vh) * 100)' : '100%')};

    width: ${props => ((typeof props.width === 'string') ? props.width : (!!props.width ? `${props.width}px` : 'unset'))};

    overflow-y: ${props => props.scrollable ? 'scroll' : ''};

    .ant-layout-sider {

        position: static;
        min-height: ${props => props.scrollable ? '100%' : ''};
        height: ${props => props.scrollable ? '' : '100%'};
        background: ${props => (props.bgColor ? props.bgColor : ThemeConfig.menuBg)};

        .ant-layout-sider-children {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
        }
    }
`
