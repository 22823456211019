import * as React from 'react'
import { MainMenuItemTP } from 'modules/app/components/user-main-menu/inner/MainMenuItemTP'
import { FileIconCP } from 'common/components/icons/FileIconCP'
import { ThemeConfig } from 'config/theme/ThemeConfig'
import { FolderIconCP } from 'common/components/icons/FolderIconCP'
import { DocumentSignatureRoutes } from 'modules/document-signature/routers/DocumentSignatureRoutes'
import { DocumentRoutes } from 'modules/document/routers/DocumentRoutes'
import { PersonTypeEnum } from 'modules/person/enums/PersonTypeEnum'

/**
 * MENU PRINCIPAL: Itens.
 *
 * @author renatofs
 */
export const MENU_OPTIONS_TOP: MainMenuItemTP[] = [
    {
        route: DocumentSignatureRoutes.ROOT,
        title: 'Novo Documento',
        icon: <FileIconCP type={'add'} color={ThemeConfig.menuTextColor}/>,
        personType: PersonTypeEnum.PJ
    }, {
        route: DocumentRoutes.ROOT,
        title: 'Meus Documentos',
        icon: <FolderIconCP color={ThemeConfig.menuTextColor}/>,
    },
]
