import { EnvironmentEnum } from 'common/enums/EnvironmentEnum'

/**
 * Classe de configuracao do sistema.
 */
export class SystemConfig {

    readonly appName = 'Dokfy'
    readonly defaultTimeZone = 'America/Fortaleza'
    readonly environment = process.env.REACT_APP_STAGE as EnvironmentEnum

    readonly reduxEncryptKey = 'bmVyaXRzZWNyZXRrZXk='
    readonly intercomId = 'izng5h6p'

    readonly apiBaseUrl: string
    readonly uiBaseUrl: string
    readonly anonymousUserToken: string

    private static _instance: SystemConfig

    private constructor() {
        switch (this.environment) {
            case EnvironmentEnum.DEV:
                this.uiBaseUrl = 'http://localhost:3000'
                this.apiBaseUrl = 'http://localhost:3009'
                this.anonymousUserToken = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyQ29kZSI6MSwiaWF0IjoxNTg2ODMxMDYwfQ.RuklhpX6nucZoFIvshxQoFBTfTO3iULdSD7KsfxeG-k'
                break

            case EnvironmentEnum.PROD:
                this.uiBaseUrl = 'https://dokfy.lumiacrm.com.br'
                this.apiBaseUrl = 'https://lumiacrm.com.br/dokfy'
                this.anonymousUserToken = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyQ29kZSI6MSwiaWF0IjoxNTg2ODEwMTcyfQ.Ji5saV2AnTGGnPT9VWjGUEDI8yI7qm8g5RztcXwRH48'
                break

            case EnvironmentEnum.HOM:
            case EnvironmentEnum.TEST:
            default:
                throw new Error('Ambiente não configurado!')
        }
    }

    static getInstance(): SystemConfig {
        if (!this._instance)
            this._instance = new SystemConfig()
        return this._instance
    }
}
