import React from 'react'
import { RadioGroupCP } from 'common/components/form-fields/radio-group/RadioGroupCP'
import { FileIconCP } from 'common/components/icons/FileIconCP'
import { IFormStateManager } from 'common/form-state-manager/interfaces/IFormStateManager'
import { styled } from 'config/theme/styledWithTheme'
import { DocumentContentFormValidator } from 'modules/document-signature/components/new-document-wizard/inner/DocumentContentFormValidator'
import { SignatureSecurityLevelEnum } from 'modules/document-signature/enums/SignatureSecurityLevelEnum'
import { SignatureSecurityLevelUtils } from 'modules/document-signature/utils/SignatureSecurityLevelUtils'

const ICON_SIZE = 28

interface ISignatureSecLevelSelectorCPProps {
    formStateManager?: IFormStateManager<DocumentContentFormValidator>
    disabled?: boolean
}

/**
 * COMPONENTE
 * Seletor de nivel de seguranca de assinatura.
 */
export function SignatureSecLevelSelectorCP(props: ISignatureSecLevelSelectorCPProps): JSX.Element {

    return (
        <>
            <LabelSCP>
                Nível de Segurança *
            </LabelSCP>

            <RadioGroupCP
                formStateManager={props.formStateManager}
                fieldName={'signatureSecLevel'}
                selected={props.formStateManager?.getFormValues()?.signatureSecLevel}
                disabled={props.disabled}
                buttonWidth={60}
                buttonHeight={42}
                options={[
                    {
                        value: SignatureSecurityLevelEnum.SIMPLE_REGISTER,
                        content: <FileIconCP type={'check'} size={ICON_SIZE}/>,
                        tooltip: SignatureSecurityLevelUtils.getSignatureSecurityLevelDescription(SignatureSecurityLevelEnum.SIMPLE_REGISTER),
                    }, {
                        value: SignatureSecurityLevelEnum.FULL_REGISTER,
                        content: <FileIconCP type={'verified'} size={ICON_SIZE}/>,
                        tooltip: SignatureSecurityLevelUtils.getSignatureSecurityLevelDescription(SignatureSecurityLevelEnum.FULL_REGISTER),
                    }
                ]}
            />
        </>
    )
}

const LabelSCP = styled.div`
    color: #285688;
    margin-bottom: 10px;
`
