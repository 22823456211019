import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import { Error404Screen } from 'common/screens/Error404Screen'
import { DocumentSignatureRoutes } from 'modules/document-signature/routers/DocumentSignatureRoutes'
import { DocumentRoutes } from 'modules/document/routers/DocumentRoutes'
import { NotificationRoutes } from 'modules/notification/routers/NotificationRoutes'
import { PersonRoutes } from 'modules/person/routers/PersonRoutes'

/**
 * ROTEADOR
 * Define rotas que so podem ser acessadas apos realizar login.
 */
export function PrivateRouter(): JSX.Element {
    return (
        <Switch>
            <Route path={'/'} exact={true} render={() => <Redirect to={DocumentSignatureRoutes.ROOT}/>}/>

            <Route path={DocumentRoutes.ROOT} component={DocumentRoutes}/>
            <Route path={NotificationRoutes.ROOT} component={NotificationRoutes}/>
            <Route path={DocumentSignatureRoutes.ROOT} component={DocumentSignatureRoutes}/>

            <Route path={PersonRoutes.PROFILE} component={PersonRoutes}/>

            <Route component={Error404Screen}/>
        </Switch>
    )
}
