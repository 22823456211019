import React, { SyntheticEvent, useEffect, useState } from 'react'
import { Form } from 'antd'
import { css, styled } from 'config/theme/styledWithTheme'
import { IFormItemCommonProps } from 'common/components/form-fields/inner/interfaces/IFormItemCommonProps'
import { MaskUtils } from 'common/utils/MaskUtils'
import { FontSizeTP } from 'config/theme/ThemeTypes'
import { IFormItemInputCommonProps } from 'common/components/form-fields/inner/interfaces/IFormItemInputCommonProps'
import moment from 'moment'
import { SystemUtils } from 'common/utils/SystemUtils'
import { FormModel } from 'common/form-state-manager/classes/FormModel'
import { OrArrayTP } from 'common/types/OrArrayTP'
import { OrUndefTP } from 'common/types/OrUndefTP'

type InputValueTP = OrUndefTP<OrArrayTP<number | string> | Date>

const CLASS_RISE_LABEL = 'rise-label'
const CLASS_ERROR = 'has-error'
const CLASS_ANIMATE = 'animate-label'

/**
 * NOTE: Neste caso, excepcionalmente, eh necessario definir as props como 'type' ao invez de
 * 'interface' para possbilitar a tipagem correta.
 */
type FormItemICPPropsTP<FModelTP extends FormModel, PropsTP extends IFormItemCommonProps<FModelTP>> = PropsTP & {
    children: JSX.Element,
}

/**
 * COMPONENTE
 * Wrapper generico a ser utilizado por inputs de formulario.
 *
 * NOTE: Caso este componente seja controlado via 'form state manager' seu valor nao eh determinado diretamente pela prop 'value'.
 *
 * @see FormStateManager
 */
export function FormItemICP<FModelTP extends FormModel, PropsTP extends IFormItemCommonProps<FModelTP>>(props: FormItemICPPropsTP<FModelTP, PropsTP>): JSX.Element {

    const [value, setValue] = useState<InputValueTP>(props.value)
    const [hasFocus, setHasFocus] = useState<boolean>(false)
    const [mustRiseLabel, setMustRiseLabel] = useState<boolean>(false)
    const [errorMessage, setErrorMessage] = useState<string>()
    const [validationErrMsg, setValidationErrMsg] = useState<string>()

    useEffect(setValueByProps, [props.value])
    useEffect(parseValidation, [props.formStateManager?.validationsCount])
    useEffect(onFormStateManagerChange, [props.formStateManager])
    useEffect(handleErrMsgUpdate, [props.errorMessage, validationErrMsg])
    useEffect(() => setMustRiseLabel(hasFocus || !SystemUtils.isEmpty(value)), [value, hasFocus])

    const hasMask = !!(props as IFormItemInputCommonProps).mask
    const hasStateManager = (!!props.fieldName && !!props.formStateManager)

    function setValueByProps(): void {
        if (![null, undefined].includes(props.value))
            setValue(props.value)
    }

    function parseValidation(): void {

        if (!!props.noValidation)
            return

        if (!hasStateManager || !!props.formStateManager?.isValid)
            return setValidationErrMsg(undefined)

        const fieldErrors = props.formStateManager?.getFieldError(props.fieldName as keyof FModelTP)
        const errMessages = Object.values(fieldErrors?.constraints ?? {}) || []
        setValidationErrMsg(errMessages[0])
    }

    function onFormStateManagerChange(): void {
        if (hasStateManager) {
            const stateValue = props.formStateManager?.getFieldValue(props.fieldName!)
            const isValidValue = (['number', 'string'].includes(typeof stateValue) || Array.isArray(stateValue) || stateValue instanceof Date)
            if (isValidValue)
                handleChange((stateValue instanceof Date) ? moment(stateValue) : stateValue)
        }
    }

    function handleChange(eventOrValue: SyntheticEvent | string): void {

        const rawValue = ((eventOrValue as SyntheticEvent).target as any)?.value ?? eventOrValue
        const mustApplyMask = (hasMask && !!rawValue && !Array.isArray(rawValue) && !(rawValue instanceof Date))

        const newValue = mustApplyMask ? MaskUtils.applyMask(rawValue, (props as IFormItemInputCommonProps).mask!) : rawValue
        if (newValue === value)
            return

        setValue(newValue)

        if (!!props.onChange)
            props.onChange(newValue)

        if (hasStateManager)
            props.formStateManager?.changeFieldValue(props.fieldName!, newValue)
    }

    function handleErrMsgUpdate(): void {
        if (!props.noValidation)
            setErrorMessage(props.errorMessage ?? validationErrMsg)
    }

    function handleBlur(): void {
        setHasFocus(false)
        props.onBlur?.(value)
        if (hasStateManager && !props.noValidation)
            props.formStateManager?.validate()
    }

    function handleFocus(): void {
        setHasFocus(true)
        if (hasStateManager)
            props.formStateManager?.setFieldDirty(props.fieldName!)
    }

    function handleKeyPress(event: KeyboardEvent): void {
        if (event.key === 'Enter' && !!props.onFormSubmit)
            props.onFormSubmit()
    }

    return (
        <WrapperSCP fontSize={props.fontSize ?? 'normal'}>
            <Form.Item
                colon={false}
                label={props.label}
                required={props.required}
                style={{ width: props.width }}
                help={props.help}
                className={`${CLASS_ANIMATE} ${mustRiseLabel ? CLASS_RISE_LABEL : ''} ${!!errorMessage ? CLASS_ERROR : ''}`}
            >
                {
                    React.cloneElement(
                        props.children,
                        {
                            onFocus: handleFocus,
                            onBlur: handleBlur,
                            onChange: handleChange,
                            onKeyPress: handleKeyPress,
                            value,
                        }
                    )
                }

                {
                    !props.noValidation && errorMessage &&
                    <div className={'ant-form-explain'} style={{ opacity: !!errorMessage ? 1 : 0 }}>
                        {errorMessage}
                    </div>
                }
            </Form.Item>
        </WrapperSCP>
    )
}

type ScpPropsTP = { fontSize: FontSizeTP }

const FormElementsCSS = css<ScpPropsTP>`
    border-radius: 0;
    border: 0 none;
    background: transparent;
    cursor: text;
    padding-right: 8px;
    border-bottom: 1px solid ${props => props.theme.normalColor};
    font-size: ${props => props.theme.fontSizes[props.fontSize]};
`

const InputFocusCSS = css`
    box-shadow: none;
    border-color: transparent;
    border-bottom: 1px solid ${props => props.theme.primaryColor};
`

const WrapperSCP = styled.div<ScpPropsTP>`

    /*ESTILOS GERAIS*/
    margin-bottom: 20px;
    width: 100%;

    .ant-col:focus {
        outline: 0
    }
    
    .ant-row,
    .${CLASS_ANIMATE},
    .${CLASS_ERROR} {

        margin: 5px 0 5px 0;
        line-height: 0;

        &:focus,
        .ant-form-item-control:focus,
        .ant-form-item-children:focus {
            outline: 0
        }

        .ant-form-item-label {

            margin-bottom: 5px;
            line-height: 35px;

            label {

                color: #adadad;

                &.ant-form-item-required {
                    &:before {
                        content: '';
                    }
                    &:after {
                        display: inline-block;
                        margin-right: 4px;
                        font-size: 10px;
                        font-family: SimSun, sans-serif;
                        line-height: 1;
                        content: '*';
                    }
                }
            }
        }

        .ant-form-explain {
            transition: opacity .3s;
            opacity: 1;
            font-size: 12px;
            line-height: 1.8;
        }
    }

    .${CLASS_ANIMATE} {

        position: relative;

        .ant-col {
            position: initial;
        }

        input {
            outline: 0;
        }

        label {
            position: absolute;
            transition: transform 0.5s ease, color 1s ease, font-size 1s ease;
        }

        &.${CLASS_RISE_LABEL} {
            label {
                transform: translate(-4px, -15px) scale(1);
                color: ${props => props.theme.primaryColor};
            }
            .ant-input-suffix {
                color: ${props => props.theme.primaryColor};
            }
        }
        &:not(.${CLASS_RISE_LABEL}) {
            label {
                font-size: ${props => props.theme.fontSizes[props.fontSize || 'normal']};
                transform: translate(0, 5px) scale(1);
            }
        }
    }

    .${CLASS_ERROR} {
        .ant-input:focus {
            ${InputFocusCSS}
        }

        &:not(.${CLASS_RISE_LABEL}) {
            .ant-select-arrow {
                color: transparent;
            }
        }
    }

    /*SELECT*/
    .ant-select {

        width: 100%;

        .ant-select-selection {

            ${FormElementsCSS}

            .ant-select-enable {
                height: 0;
            }

            &.ant-select-selection--multiple {
                .ant-select-selection__rendered {
                    margin: 0;
                }
            }

            .ant-select-selection__rendered:focus,
            &:focus,
            &:active {
                box-shadow: none;
                outline: 0;
            }

            .ant-select-selection__rendered {
                ul {

                    &:focus {
                        outline: 0;
                    }

                    li:not(.ant-select-search) {
                        height: 20px;
                        margin: 2px;
                        padding: 0 18px 0 6px;
                        font-size: 12px;
                        display: flex;
                        line-height: 18px;
                    }
                }
            }
        }
    }

    /*INPUT*/
    .ant-input,
    .ant-input:not(:last-child) {

        padding-right: 10px !important;
        ${FormElementsCSS}
        
        &:focus {
            ${InputFocusCSS}
        }
    }
`
