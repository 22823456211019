import { IsNotEmpty } from 'common/validation/decorators/IsNotEmpty'
import { FormModel } from 'common/form-state-manager/classes/FormModel'
import { SignatureSecurityLevelEnum } from 'modules/document-signature/enums/SignatureSecurityLevelEnum'

export class DocumentContentFormValidator extends FormModel {

    @IsNotEmpty()
    name: string

    @IsNotEmpty()
    message: string

    @IsNotEmpty()
    signatureSecLevel: SignatureSecurityLevelEnum

    constructor(initialData?: any) {
        super(initialData)
        Object.assign(this, initialData)
    }

}
