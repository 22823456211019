import React from 'react'
import { LoadingIconCP } from 'common/components/icons/LoadingIconCP'
import { SearchIconCP } from 'common/components/icons/SearchIconCP'
import { InputTypeTP } from 'common/components/form-fields/input/inner/types/InputTypeTP'

const SIZE_DEFAULT = 14

interface IInputSuffixIconICPProps {
    type?: InputTypeTP
    isLoading?: boolean
    icon?: JSX.Element
}

/**
 * COMPONENTE: Icone 'sufixo' para 01 input generico.
 * @see InputCP
 */
export function InputSuffixIconICP(props: IInputSuffixIconICPProps): JSX.Element | null {

    if (!!props.isLoading)
        return <LoadingIconCP size={SIZE_DEFAULT}/>

    if (!!props.icon)
        return props.icon

    if (props.type === 'search')
        return <SearchIconCP size={SIZE_DEFAULT}/>

    return null
}
