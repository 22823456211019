import React from 'react'
import { FormModel } from '../../../form-state-manager/classes/FormModel'
import { InputMaskTypeEnum } from '../../../enums/InputMaskTypeEnum'
import { IInputCPProps, InputCP } from '../input/InputCP'

interface IInputPhoneCPProps<FModelTP extends FormModel> extends Omit<IInputCPProps<FModelTP>, 'type' | 'min' | 'max' | 'mask'> {}

/**
 * COMPONENTE: Input para telefone.
 *
 * NOTE: Quando controlado via 'form state manager' valor nao eh determinado diretamente pela prop 'value'
 * TODO: Simplificar / Melhorar isso
 */
export function InputPhoneCP<FModelTP extends FormModel = any>(props: IInputPhoneCPProps<FModelTP>): JSX.Element {
    return (
        <InputCP
            type={'tel'}
            mask={InputMaskTypeEnum.PHONE}
            maxlength={15}
            value={props.value}
            onChange={props.onChange}
            required={props.required}
            label={props.label}
            disabled={props.disabled}
            width={props.width}
            fontSize={props.fontSize}
            errorMessage={props.errorMessage}
            noValidation={props.noValidation}
            fieldName={props.fieldName}
            formStateManager={props.formStateManager}
            onFormSubmit={props.onFormSubmit}
            debug={props.debug}
            icon={props.icon}
            loading={props.loading}
            autoFocus={props.autoFocus}
        />
    )
}
