import React from 'react'
import { ButtonCP } from 'common/components/button/ButtonCP'
import { TextCP } from 'common/components/text/TextCP'
import { useScreenSize } from 'common/responsiveness/use-screen-size/UseScreenSize'

interface IHeaderButtonICPProps {
    icon: string
    label: string
    onClick: () => void
}

/**
 * COMPONENTE
 * Botao principal dos headers das paginas
 */
export function HeaderButtonICP(props: IHeaderButtonICPProps): JSX.Element {

    const screenSize = useScreenSize()

    return (
        <ButtonCP
            onClick={props.onClick}
            icon={props.icon}
            type={'primary'}
        >
            {
                !screenSize.smd &&
                <TextCP text={props.label}/>
            }
        </ButtonCP>
    )
}
