import React from 'react'

import { SelectCP } from 'common/components/form-fields/select/SelectCP'
import { SelectOptionTP } from 'common/components/form-fields/select/inner/types/SelectOptionTP'
import { IFormItemCommonProps } from 'common/components/form-fields/inner/interfaces/IFormItemCommonProps'
import { GenderEnum } from 'modules/person/enums/GenderEnum'
import { PersonUtils } from 'modules/person/utils/PersonUtils'
import { InputValueCallbackTP } from 'common/components/form-fields/inner/types/InputValueCallbackTP'
import { FormModel } from 'common/form-state-manager/classes/FormModel'
import { NacionalityEnum } from 'modules/person/enums/NacionalityEnum'

const OPTIONS: SelectOptionTP[] = Object.values(NacionalityEnum).map((nacionality, i) => ({
    key: `key-nacionality-${i}`,
    label: PersonUtils.getNacionalityLabel(nacionality),
    value: nacionality
}))

interface INacionalitySelectorCPProps<FModelTP extends FormModel = any> extends IFormItemCommonProps<FModelTP> {
    onChange?: InputValueCallbackTP<GenderEnum>
}

/**
 * COMPONENTE
 * Seletor unitario de nacionalidade.
 */
export function NacionalitySelectorCP<FModelTP extends FormModel = any>(props: INacionalitySelectorCPProps<FModelTP>): JSX.Element {
    return (
        <SelectCP<FModelTP>
            label={'Nacionalidade'}
            options={OPTIONS}
            onChange={props.onChange}
            formStateManager={props.formStateManager}
            fieldName={props.fieldName}
            required={props.required}
            errorMessage={props.errorMessage}
        />
    )
}
