import React, { useEffect, useState } from 'react'
import { ContentCP } from 'common/components/screen-layout/content/ContentCP'
import { LayoutCP } from 'common/components/screen-layout/layout/LayoutCP'
import styled from 'config/theme/styledWithTheme'
import { useParams } from 'react-router-dom'
import { useRequest } from 'common/request-manager/use-request/UseRequest'
import { DocumentRequests } from 'modules/document/services/DocumentRequests'
import { IDocumentForSignResponseDTO } from 'modules/document/services/dtos/response/IDocumentForSignResponseDTO'
import { LoadingOverlayCP } from 'common/components/loaders/loading-overlay/LoadingOverlayCP'
import { SignDocumentScreenContentCP } from 'modules/document-signature/components/sign-document-screen-content/SignDocumentScreenContentCP'
import { LogoBarCP } from 'modules/document-signature/components/logo-bar/LogoBarCP'
import { FallbackScreenContentCP } from 'common/components/fallback-screen-content/FallbackScreenContent'

/**
 * TELA: Tela para assinatura de um documento.
 */
export function SignDocumentScreen(): JSX.Element | null {

    const routeParams = useParams<{signToken: string}>()

    const [isTokenInvalid, setIsTokenInvalid] = useState<boolean>(false)

    useEffect(init, [])

    const [documentLoaded, setDocumentLoaded] = useState<IDocumentForSignResponseDTO>()
    const getDocumentRequest = useRequest<IDocumentForSignResponseDTO>()
    useEffect(onGetDocumentRequestChange, [getDocumentRequest.isAwaiting])

    /**
     * Inicializa a tela.
     */
    function init(): void {

        const signToken = routeParams.signToken
        if (!signToken)
            setIsTokenInvalid(true)

        getDocumentRequest.runRequest(DocumentRequests.getDocumentBySignTokenConfig(signToken))
    }

    /**
     * Retorno da requisicao para pegar documento.
     */
    function onGetDocumentRequestChange(): void {

        if (getDocumentRequest.isAwaiting || !getDocumentRequest.wasTried)
            return

        const isSuccess = (getDocumentRequest.isSuccess && !!getDocumentRequest.responseData)
        if (!isSuccess) {
            setIsTokenInvalid(true)
            return
        }

        setDocumentLoaded(getDocumentRequest.responseData)
    }

    return (
        <>
            <LoadingOverlayCP show={getDocumentRequest.isAwaiting}/>
            <LayoutCP
                content={
                    <ContentWrapperSCP>
                        <ContentCP>

                            <LogoBarCP/>

                            {
                                isTokenInvalid &&
                                <FallbackScreenContentCP
                                    status={'error'}
                                    title={'Documento inválido'}
                                />
                            }

                            {
                                documentLoaded &&
                                <SignDocumentScreenContentCP documentForSign={documentLoaded}/>
                            }
                        </ContentCP>
                    </ContentWrapperSCP>
                }
            />
        </>
    )
}

const ContentWrapperSCP = styled.div`
  min-height: 98vh;
  margin-bottom: 20px;
`
