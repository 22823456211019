import React from 'react'
import { ListItemICP } from 'common/components/list/inner/ListItemICP'
import { styled } from 'config/theme/styledWithTheme'
import { SignatureSecurityLevelIconCP } from 'modules/document-signature/components/signature-security-level-icon/SignatureSecurityLevelIconCP'
import { IPersonResponseDTO } from 'modules/person/services/dtos/response/IPersonResponseDTO'
import { DescriptionItemCP } from 'common/components/description/description-item/DescriptionItemCP'
import { DescriptionCP } from 'common/components/description/description/DescriptionCP'
import { FlexCP } from 'common/components/flex/FlexCP'
import { ButtonCP } from 'common/components/button/ButtonCP'
import { DeleteIconCP } from 'common/components/icons/DeleteIconCP'
import { DateUtils } from 'common/utils/date/DateUtils'
import { MaskUtils } from 'common/utils/MaskUtils'
import { InputMaskTypeEnum } from 'common/enums/InputMaskTypeEnum'
import { DateFormatEnum } from 'common/enums/DateFormatEnum'

interface IAssigneeTableItemICPProps {
    dataSourceItem: IPersonResponseDTO
    itemOrder: number
    canOrder: boolean
    onRemove: () => void
}

/**
 * COMPONENTE da item da tabela de signatarios.
 */
export function AssigneeTableItemICP(props: IAssigneeTableItemICPProps): JSX.Element | null {

    return (
        <ListItemICP
            avatar={<SignatureSecurityLevelIconCP secLevel={props.dataSourceItem.signatureSecurityLevel} showTooltip={true}/>}
            title={
                <FlexCP justifyContent={'space-between'}>
                    <div>{props.dataSourceItem.name}</div>
                    <div>
                        <ButtonCP
                            size={'small'}
                            onClick={props.onRemove}
                            confirmMsg={'Tem certeza que deseja remover este signatário?'}
                        >
                            <DeleteIconCP size={14}/>
                        </ButtonCP>
                    </div>
                </FlexCP>
            }
            content={
                <AssigneeItemSCP>
                    <DescriptionCP>
                        <DescriptionItemCP label={'CPF'}>
                            <>{ MaskUtils.applyMask(props.dataSourceItem.cpfCnpj, InputMaskTypeEnum.CPF) }</>
                        </DescriptionItemCP>
                        <DescriptionItemCP label={'E-mail'}>
                            {props.dataSourceItem.email}
                        </DescriptionItemCP>
                        <DescriptionItemCP>
                            {`Usuário Dokfy há ${DateUtils.getPresentableDiff(DateUtils.toDate(props.dataSourceItem.createdDate, DateFormatEnum.US_WITH_TIME))}`}
                        </DescriptionItemCP>
                    </DescriptionCP>
                    {
                        props.canOrder &&
                        <AssigneeOrderDescSCP>
                            {`${props.itemOrder}ª assinatura`}
                        </AssigneeOrderDescSCP>
                    }
                </AssigneeItemSCP>
            }
        />
    )
}

const AssigneeItemSCP = styled.div`
  .ant-descriptions-row > td {
      padding-bottom: 0px !important;
  }
`

const AssigneeOrderDescSCP = styled.div`
  font-size: 12px;
  color: #adadad;
  text-align: right;
`
