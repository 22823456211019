import React, { useEffect, useState } from 'react'
import { WizardCP } from 'common/components/wizard/WizardCP'
import { AssigneesContentStepICP } from 'modules/document-signature/components/new-document-wizard/inner/AssigneesContentStepICP'
import { DocumentContentStepICP } from 'modules/document-signature/components/new-document-wizard/inner/DocumentContentStepICP'
import { DocumentContentFormValidator } from 'modules/document-signature/components/new-document-wizard/inner/DocumentContentFormValidator'
import { AssigneesContentFormValidator } from 'modules/document-signature/components/new-document-wizard/inner/AssigneesContentFormValidator'
import { useFormStateManager } from 'common/form-state-manager/UseFormStateManager'
import { SignatureSecurityLevelEnum } from 'modules/document-signature/enums/SignatureSecurityLevelEnum'
import { IFormStateManager } from 'common/form-state-manager/interfaces/IFormStateManager'

interface INewDocumentWizardCPProps {
    onDone: (documentFormStateManager: IFormStateManager<DocumentContentFormValidator>, assigneerFormStateManager: IFormStateManager<AssigneesContentFormValidator>) => void
}

/**
 * COMPOENENT Passo a passo para criar novo documento.
 */
export function NewDocumentWizardCP(props: INewDocumentWizardCPProps): JSX.Element {

    const [documentFormValidator, setDocumentFormValidator] = useState<DocumentContentFormValidator>((new DocumentContentFormValidator()))
    const documentFormStateManager = useFormStateManager<DocumentContentFormValidator>(documentFormValidator)

    const [assigneesFormValidator, setAssigneesFormValidator] = useState<AssigneesContentFormValidator>((new AssigneesContentFormValidator()))
    const assigneesFormStateManager = useFormStateManager<AssigneesContentFormValidator>(assigneesFormValidator)

    useEffect(init, [])

    /**
     * Inicializa valores default de atributos do passo-a-passo
     */
    function init(): void {

        setDocumentFormValidator(new DocumentContentFormValidator({
            'signatureSecLevel': SignatureSecurityLevelEnum.SIMPLE_REGISTER
        }))

        setAssigneesFormValidator(new AssigneesContentFormValidator({
            'isOrdered': false
        }))

    }

    /**
     * Validata dados do documento informados
     */
    async function validateDocumentData(): Promise<boolean> {

        documentFormStateManager.setConsiderAllErrors(true)
        if (!await documentFormStateManager.validate())
            return false

        const formValues = documentFormStateManager.getFormValues()
        if (!formValues)
            return false

        return true
    }

    /**
     * Validata dados de assignee
     */
    async function validateAssigneeData(): Promise<boolean> {

        assigneesFormStateManager.setConsiderAllErrors(true)
        if (!await assigneesFormStateManager.validate())
            return false

        const formValues = assigneesFormStateManager.getFormValues()
        if (!formValues)
            return false

        props.onDone(documentFormStateManager, assigneesFormStateManager)
        return true
    }

    return (
        <WizardCP
            loading={false}
            action={validateAssigneeData}
            currentStep={0}
            steps={[
                {
                    key: 'document-data',
                    title: 'Documento',
                    content: <DocumentContentStepICP formStateManager={documentFormStateManager}/>,
                    nextAction: validateDocumentData
                }, {
                    key: 'assignees-data',
                    title: 'Signatários',
                    content: <AssigneesContentStepICP formStateManager={assigneesFormStateManager}/>,
                }
            ]}
        />
    )
}
