import { SignatureSecurityLevelEnum } from 'modules/document-signature/enums/SignatureSecurityLevelEnum'

/**
 * UTILITARIOS para nivel de seguranca de assinatura.
 */
export const SignatureSecurityLevelUtils = {

    getSignatureSecurityLevelLabel(secLevel: SignatureSecurityLevelEnum): string {
        switch (secLevel) {
            case SignatureSecurityLevelEnum.SIMPLE_REGISTER:
                return 'Cadastro Simples'

            case SignatureSecurityLevelEnum.FULL_REGISTER:
                return 'Cadastro completo'

            default:
                return ''
        }
    },

    getSignatureSecurityLevelDescription(secLevel: SignatureSecurityLevelEnum): string {
        switch (secLevel) {
            case SignatureSecurityLevelEnum.SIMPLE_REGISTER:
                return 'Neste nível de segurança o signatário precisa informar apenas: CPF / Nome / E-mail'

            case SignatureSecurityLevelEnum.FULL_REGISTER:
                return 'Neste nível de segurança o signatário inseriu todos os documentos. Além dos dados, os documentos que comprovem as informações'

            default:
                return ''
        }
    },

}
