import React, { useEffect, useState } from 'react'
import { ModalCP } from 'common/components/modal/ModalCP'
import { LoginContentICP } from 'modules/document-signature/components/confirm-signature-modal/inner/LoginContentICP'
import { AppStateUtils } from 'common/utils/AppStateUtils'
import { SafeFooterCP } from 'modules/document-signature/components/safe-footer/SafeFooterCP'
import { IDocumentForSignResponseDTO } from 'modules/document/services/dtos/response/IDocumentForSignResponseDTO'
import { AlertCP } from 'common/components/alert/AlertCP'
import { TextCP } from 'common/components/text/TextCP'
import { StringUtils } from 'common/utils/StringUtils'
import { DocumentDetailsCP } from 'modules/document/components/document-details/DocumentDetailsCP'

type PageViewTP = 'decline' | 'login'

interface IDeclineSignatureModalCPProps {
    visible: boolean
    onCancel: () => void
    onDecline: () => void
    documentToSign?: IDocumentForSignResponseDTO
    loading: boolean
}

/**
 * COMPONENTE: Modal para recusar um documento.
 */
export function DeclineSignatureModalCP(props: IDeclineSignatureModalCPProps): JSX.Element {

    const [pageView, setPageView] = useState<PageViewTP>('login')

    useEffect(defineCanSign, [props.visible])

    function defineCanSign(): void {

        if (!props.visible || !props.documentToSign)
            return

        // Se usuario nao tiver logdado, ou se o usuario logado for diferente do usuario informado no token
        if (!AppStateUtils.getLoggedUserData() || AppStateUtils.getLoggedUserData()?.user.code !== props.documentToSign?.personToSign.person.code) {
            setPageView('login')
            return
        }

        setPageView('decline')
    }

    if (!props.documentToSign)
        return <></>

    return (
        <ModalCP
            visible={props.visible}
            title={'Recusar Documento'}
            okText={'Recusar'}
            onCancel={props.onCancel}
            onOk={props.onDecline}
            width={380}
            top={5}
            loading={props.loading}
            customFooter={
                pageView !== 'decline' ? <SafeFooterCP/> : undefined
            }
        >
            {
                pageView === 'login' &&
                <LoginContentICP
                    personToSign={props.documentToSign.personToSign}
                    onLoginSuccess={defineCanSign}
                />
            }

            {
                pageView === 'decline' &&
                <>
                    <AlertCP
                        message={
                            <>
                                <TextCP
                                    text={`Olá ${StringUtils.getFirstName(AppStateUtils.getLoggedUserData()!.user.name)}, seja bem-vindo ao Dokfy.`}
                                    style={{ marginBottom: '10px' }}
                                />
                                <TextCP
                                    text={'Você tem certeza que deseja recusar esse documento?'}
                                    style={{ marginBottom: '10px' }}
                                />
                                <TextCP text={'Lembre-se que essa ação não pode ser desfeita.'}/>
                            </>
                        }
                        type={'error'}
                    />

                    <DocumentDetailsCP document={props.documentToSign?.document}/>
                </>
            }
        </ModalCP>
    )
}
