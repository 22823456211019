import React, { useState } from 'react'
import { ButtonCP } from 'common/components/button/ButtonCP'
import { DownloadIconCP } from 'common/components/icons/DownloadIconCP'
import { FlexCP } from 'common/components/flex/FlexCP'
import { EyeIconCP } from 'common/components/icons/EyeIconCP'
import { ModalCP } from 'common/components/modal/ModalCP'
import { PdfViewerCP } from 'common/components/pdf-viewer/PdfViewerCP'
import { LinkHtmlCP } from 'common/components/link-html/LinkHtmlCP'
import { SafeFooterCP } from 'modules/document-signature/components/safe-footer/SafeFooterCP'

interface IDocumentDrawerButtonsCPProps {
    originalFile?: string
    signedFile?: string
}

/**
 * Botoes de um documento, para visualizar e/ou baixar os arquivo.
 */
export function DocumentDrawerButtonsCP(props: IDocumentDrawerButtonsCPProps): JSX.Element {

    const [isPdfModalVisible, setIsPdfModalVisible] = useState<boolean>(false)

    return (
        <>
            <FlexCP justifyContent={'space-between'}>
                <>
                    {
                        props.signedFile &&
                        <LinkHtmlCP
                            type={'primary'}
                            href={props.signedFile}
                        >
                            <DownloadIconCP style={{ marginRight: '10px' }}/>
                            <>Assinado</>
                        </LinkHtmlCP>
                    }

                    {
                        props.originalFile &&
                        <FlexCP justifyContent={'flex-end'}>
                            <ButtonCP
                                type={'ghost'}
                                marginRight={5}
                                size={'small'}
                                onClick={() => setIsPdfModalVisible(true)}
                            >
                                <EyeIconCP style={{ marginRight: '10px' }}/>
                                Visualizar
                            </ButtonCP>
                            <LinkHtmlCP
                                type={'ghost'}
                                href={props.originalFile}
                            >
                                <DownloadIconCP style={{ marginRight: '10px' }}/>
                                <>Original</>
                            </LinkHtmlCP>
                        </FlexCP>
                    }
                </>
            </FlexCP>

            <ModalCP
                visible={isPdfModalVisible}
                onClose={() => setIsPdfModalVisible(false)}
                noFooter={true}
                customFooter={<SafeFooterCP/>}
                top={5}
                width={800}
            >
                <PdfViewerCP
                    file={props.originalFile}
                    paginationType={'scroll'}
                />
            </ModalCP>
        </>
    )
}
