import React from 'react'
import { DragAndDropListCP } from 'common/components/drag-and-drop-list/DragAndDropListCP'
import { DragAndDropListItemICP } from 'common/components/drag-and-drop-list/inner/DragAndDropListItemICP'
import { ListCP } from 'common/components/list/ListCP'
import { IPersonResponseDTO } from 'modules/person/services/dtos/response/IPersonResponseDTO'
import { AssigneeTableItemICP } from 'modules/document-signature/components/assignee-table-dnd/inner/AssigneeTableItemICP'

interface IAssigneeTableCPDnDProps {
    assignees: IPersonResponseDTO[]
    canOrder: boolean
    onRemoveAssignee: (index: number) => void
    onChangeOrder: (assignees: IPersonResponseDTO[]) => void
}

/**
 * COMPONENTE da tabela de signatarios.
 */
export function AssigneeTableDnDCP(props: IAssigneeTableCPDnDProps): JSX.Element {

    return (
        <>
            {
                props.canOrder
                    ?
                    <DragAndDropListCP
                        dataSource={props.assignees}
                        renderContent={(item: IPersonResponseDTO, index) => (
                            <DragAndDropListItemICP id={item.code} index={index}>
                                <AssigneeTableItemICP
                                    dataSourceItem={item}
                                    itemOrder={index + 1}
                                    canOrder={props.canOrder}
                                    onRemove={() => props.onRemoveAssignee(index)}
                                />
                            </DragAndDropListItemICP>
                        )}
                        onListOrderChange={(list) => props.onChangeOrder(list.map(dnd => dnd.item))}
                    />
                    :
                    <ListCP<IPersonResponseDTO>
                        paddingLeft={5}
                        paddingBottom={5}
                        paddingRight={5}
                        paddingTop={5}
                        dataSource={props.assignees}
                        renderItem={(item, index) => (
                            <AssigneeTableItemICP
                                dataSourceItem={item}
                                itemOrder={index + 1}
                                canOrder={props.canOrder}
                                onRemove={() => props.onRemoveAssignee(index)}
                            />
                        )}
                    />
            }
        </>
    )
}
