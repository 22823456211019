import React from 'react'
import { UfEnum } from 'common/components/form-fields/uf-select/inner/UfEnum'
import { SelectOptionTP } from 'common/components/form-fields/select/inner/types/SelectOptionTP'
import { SelectCP } from 'common/components/form-fields/select/SelectCP'
import { IFormItemCommonProps } from 'common/components/form-fields/inner/interfaces/IFormItemCommonProps'

const OPTIONS: SelectOptionTP[] = Object.values(UfEnum).map((uf, i) => ({
    key: `key-uf-${i}`,
    label: uf,
    value: uf
}))

interface IUfSelectCPProps extends IFormItemCommonProps {
    loading?: boolean
}

/**
 * Select para estados brasileiros
 *
 * @author renatofs
 */
export function UfSelectCP(props: IUfSelectCPProps): JSX.Element {

    return (
        <SelectCP
            options={OPTIONS}
            label={'UF'}
            required={props.required}
            formStateManager={props.formStateManager}
            fieldName={props.fieldName}
            errorMessage={props.errorMessage}
            loading={props.loading}
        />
    )
}
