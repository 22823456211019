import { SelectOptionTP } from 'common/components/form-fields/select/inner/types/SelectOptionTP'
import { OrUndefTP } from 'common/types/OrUndefTP'

/**
 * UTILITARIOS auxiliares para componente de selecao.
 *
 * @see SelectCPU
 * @author hjcostabr
 */
export const SelectCPUtils = {

    /** Retorna definicao de 01 opcao da lista de opcoes informada identificada pelo seu valor (se houver). */
    getOptionByValue(options: SelectOptionTP[], singleValue?: number): OrUndefTP<SelectOptionTP> {
        return options.find(option => (typeof option === 'object' && option.value === singleValue))
    },

    getOptionKey(option: SelectOptionTP): string | number {
        return (typeof option === 'string') ? option : (option.key ?? option.value)
    },

    getOptionValue(option: SelectOptionTP): any {
        return (typeof option === 'string') ? option : option.value
    },

    getOptionLabel(option: SelectOptionTP): any {
        return (typeof option === 'string') ? option : (option.label ?? option.value)
    },
}
