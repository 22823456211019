import React from 'react'
import { ContentCP } from 'common/components/screen-layout/content/ContentCP'
import { HeaderCP } from 'common/components/screen-layout/header/HeaderCP'
import { NotificationTableCP } from 'modules/notification/components/notification-table/NotificationTableCP'

/**
 * TELA de listagem de notificacoes.
 */
export function NotificationListScreen(): JSX.Element {

    return (
        <ContentCP>

            <HeaderCP
                title={'Minha caixa de entrada'}
            />

            <NotificationTableCP/>
        </ContentCP>
    )
}
