import * as React from 'react'
import styled from 'styled-components'
import { LogoCP } from 'common/components/logo/LogoCP'

/**
 * COMPONENTE
 * Logo no cabeçalho do menu.
 *
 * @author Lucas Rosa
 * @author hjcostabr
 */
export function MenuLogoCP(): JSX.Element {
    return (
        <MenuLogoSCP>
            <LogoCP
                marginTop={17}
                marginBottom={17}
                height={30}
                type={'icon'}
            />
        </MenuLogoSCP>
    )
}

const MenuLogoSCP = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-bottom: solid 1px #28B9CF;
`
