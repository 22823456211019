import { Spin, Upload } from 'antd'
import { RcFile } from 'antd/lib/upload'
import { RcCustomRequestOptions } from 'antd/lib/upload/interface'
import { UploadCloudIconCP } from 'common/components/icons/UploadCloudIconCP'
import { MimeTypeEnum } from 'common/enums/MimeTypeEnum'
import { NotificationHelper } from 'common/helpers/NotificationHelper'
import { FileUtils } from 'common/utils/FileUtils'
import { MimeTypeUtils } from 'common/utils/MimeTypeUtils'
import styled from 'config/theme/styledWithTheme'
import { ThemeConfig } from 'config/theme/ThemeConfig'
import React from 'react'
import { FontSizeTP } from 'config/theme/ThemeTypes'
import { CheckIconCP } from '../icons/CheckIconCP'

const MAX_FILE_SIZE_MB = 50

interface IFileUploadCPProps {

    uploadFiles: (file: File) => void

    loading?: boolean
    multiple?: boolean
    fileTypes?: MimeTypeEnum[]
    loadingMsg?: string
    disabled?: boolean
    imgSrc?: string
    hintSize?: FontSizeTP
    isUploaded?: boolean

    previewImgHeight?: string
    previewImgWidth?: string

    onProgress?: (percent: number, file: File) => void
    onError?: (error: Error) => void
    onSuccess?: (response: {}, file: File) => void
}

/**
 * Componente responsável por fazer o upload de arquivos para o sitema.
 * Utiliza handlers customizados para fazer este upload.
 */
export function FileUploadCP(props: IFileUploadCPProps): JSX.Element {

    function validateFile(file: RcFile): boolean {

        // Valida se há algum arquivo
        if (!file) {
            NotificationHelper.error('Nenhum arquivo foi encontrado')
            return false
        }

        // Valida tipo do arquivo
        if (!!props.fileTypes) {
            if (!(props.fileTypes as string[]).includes(file.type)) {
                const hasCsv = props.fileTypes?.some(type => MimeTypeUtils.isCsvMimeType(type))
                if (!hasCsv || !FileUtils.isCsv(file, file.name)) {
                    NotificationHelper.error(`Apenas ${props.fileTypes.join(', ').replace(/\w*\//gm, '')} são permitidos`)
                    return false
                }
            }
        }

        // Verifica se arquivo possui no maximo 50mb
        if (file.size / 1024 / 1024 > MAX_FILE_SIZE_MB) {
            NotificationHelper.error(`Somente é permitido arquivos menores que ${MAX_FILE_SIZE_MB}MB`)
            return false
        }

        return true
    }

    function customRequest(_requestData: RcCustomRequestOptions): void {

        if (!!props.onProgress)
            _requestData.onProgress = (event, file) => props.onProgress!(event.percent, file)

        if (!!props.onError)
            _requestData.onError = props.onError

        if (!!props.onSuccess)
            _requestData.onSuccess = props.onSuccess

        props.uploadFiles(_requestData.file)
    }

    const hintSize = props.hintSize ?? 'normal'

    return (
        <WrapperSCP>
            <Spin
                spinning={!!props.loading}
                tip={props.loadingMsg ? props.loadingMsg : 'Fazendo upload ...'}
            >
                <Upload.Dragger
                    accept={props.fileTypes?.join(',')}
                    showUploadList={false}
                    listType={'picture-card'}
                    beforeUpload={validateFile}
                    customRequest={customRequest}
                    multiple={!!props.multiple}
                    disabled={props.disabled}
                >
                    {
                        !!props.imgSrc ?
                            <ImgSCP
                                imgHeight={props.previewImgHeight}
                                imgWidth={props.previewImgWidth}
                                src={props.imgSrc}
                            />
                            :
                            <>
                                {
                                    !!props.isUploaded ?
                                        <CheckIconCP size={25} type={'circle'} color={ThemeConfig.successColor}/>
                                        :
                                        <UploadCloudIconCP size={25} color={ThemeConfig.primaryColor}/>
                                }

                                <HintSCP size={hintSize}>
                                    Clique ou arraste o arquivo
                                </HintSCP>

                                {
                                    props.fileTypes?.length &&
                                    <HintSCP size={hintSize}>{`Apenas arquivos ${props.fileTypes.join(', ').replace(/\w*\//gm, '')} são aceitos`}</HintSCP>
                                }
                            </>
                    }
                </Upload.Dragger>
            </Spin>
        </WrapperSCP>
    )
}

const WrapperSCP = styled.div`

    .ant-upload.ant-upload-drag {
        
        .ant-upload {
            padding: 5px;
        }

        .ant-upload-drag-container {
            display: flex;
            flex-direction: column;
            align-items: center;
            opacity: .95;
            transition: opacity .3s, color .3s;
            height: 100%;
            justify-content: center;
        }
    }
    .ant-upload.ant-upload-drag:hover {
        .ant-upload-drag-container {
            opacity: 1;
            color: ${props => props.theme.black}
        }
    }

    .ant-upload.ant-upload-drag:not(.ant-upload-disabled):hover  {
        border-color: ${props => props.theme.primaryColor}
    }
    .ant-upload.ant-upload-drag.ant-upload-drag-hover:not(.ant-upload-disabled) {
        border-color: ${props => props.theme.primaryColor}
    }
    .ant-spin-blur {
        opacity: 0.2;
    }
`

const ImgSCP = styled.img<{ imgHeight?: string, imgWidth?: string }>`
    height: ${props => props.imgHeight ?? ''};
    width: ${props => props.imgWidth ?? '100%'};
`

const HintSCP = styled.p<{ size: FontSizeTP }>`
    font-size: ${props => ThemeConfig.fontSizes[props.size]};
`
