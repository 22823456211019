import React from 'react'
import { ThemeConfig } from 'config/theme/ThemeConfig'
import { CheckIconCP } from 'common/components/icons/CheckIconCP'
import { ICreateDocumentRequestDTO } from 'modules/document/services/dtos/request/ICreateDocumentRequestDTO'
import { SignatureSecurityLevelUtils } from 'modules/document-signature/utils/SignatureSecurityLevelUtils'
import { TextCP } from 'common/components/text/TextCP'

type ChecklistItemTP = {
    avatar: JSX.Element,
    value: string | JSX.Element,
    title?: string,
}

/**
 * UTILITARIOS para modal de confirmacao de submissao de documento para assinatura.
 */
export class NewDocumentConfirmModalUtils {

    private static readonly _CHECK_ICON = <CheckIconCP type={'circle'} size={28} theme={'twoTone'} color={ThemeConfig.successColor}/>

    static getConfirmData(
        document: ICreateDocumentRequestDTO
    ): ChecklistItemTP[] {
        return [
            {
                avatar: this._CHECK_ICON,
                title: 'Nome do Documento',
                value: document.documentName
            }, {
                avatar: this._CHECK_ICON,
                title: 'Segurança das Assinaturas',
                value: (
                    <>
                        <TextCP text={SignatureSecurityLevelUtils.getSignatureSecurityLevelLabel(document.securityLevel)}/>
                        <i>{ SignatureSecurityLevelUtils.getSignatureSecurityLevelDescription(document.securityLevel) }</i>
                    </>
                )
            }, {
                avatar: this._CHECK_ICON,
                title: 'Enivar para signatários em ordem?',
                value: document.hasSignOrder ? 'Sim' : 'Não'
            }, {
                avatar: this._CHECK_ICON,
                title: 'Mensagem para signatários',
                value: document.signatureMessage ?? ''
            },
        ]
    }
}
