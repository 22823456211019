import React from 'react'
import { Switch } from 'antd'
import { TextCP } from '../text/TextCP'
import styled from 'styled-components'
import { CheckIconCP } from 'common/components/icons/CheckIconCP'
import { CloseIconCP } from 'common/components/icons/CloseIconCP'

interface ISwitchCPProps {

    isTextInside: boolean
    isChecked: boolean
    onChange?: (checked: boolean) => void

    textOutside?: string
    textInsideChecked?: string
    textInsideUnchecked?: string
    disabled?: boolean
}

/**
 * COMPONENTE
 * Elemento de selecao unica do tipo 'Switch'.
 */
export function SwitchCP(props: ISwitchCPProps): JSX.Element {
    return (
        <WrapperSCP>
            <Switch
                checkedChildren={props.isTextInside ? props.textInsideChecked : <CheckIconCP/>}
                unCheckedChildren={props.isTextInside ? props.textInsideUnchecked : <CloseIconCP/>}
                checked={props.isChecked}
                disabled={props.disabled}
                onChange={props.onChange}
                defaultChecked={true}
            />

            {
                (!props.isTextInside && props.textOutside) &&
                <TextCP text={props.textOutside}/>
            }
        </WrapperSCP>
    )

}

const WrapperSCP = styled.div`

   display: flex;
   margin: .5rem 0;

   .ant-switch {
        margin: 0 .5rem 0 0;
   }
`
