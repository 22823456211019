import { ButtonCP } from 'common/components/button/ButtonCP'
import { CardCP } from 'common/components/card/CardCP'
import { LoadingOverlayCP } from 'common/components/loaders/loading-overlay/LoadingOverlayCP'
import { TitleCP } from 'common/components/title/TitleCP'
import styled from 'config/theme/styledWithTheme'
import { AddressPanelCP } from 'modules/person/components/address-panel/AddressPanelCP'
import React, { useEffect, useState } from 'react'
import { useRequest } from 'common/request-manager/use-request/UseRequest'
import { PersonDataContentICP } from 'modules/person/components/person-register-wizard/inner/PersonDataContentICP'
import { PersonRequests } from 'modules/person/services/PersonRequests'
import { useFormStateManager } from 'common/form-state-manager/UseFormStateManager'
import { PersonFormModel } from 'modules/person/models/PersonFormModel'
import { RequestUtils } from 'common/request-manager/RequestUtils'
import { IPersonResponseDTO } from 'modules/person/services/dtos/response/IPersonResponseDTO'
import { PersonFormUtils } from 'modules/person/utils/PersonFormUtils'
import { PersonSaveRequestDTO } from 'modules/person/services/dtos/request/PersonSaveRequestDTO'
import { NotificationHelper } from 'common/helpers/NotificationHelper'
import { PersonDocumentsContentICP } from '../person-register-wizard/inner/PersonDocumentsContentICP'
import { PersonTypeEnum } from 'modules/person/enums/PersonTypeEnum'

interface ILoggedUserDataCardCPProps {
    userCpfCnpj: string
}

/**
 * COMPONENTE
 * Card com detalhes do usuario logado.
 *
 * @param {ILoggedUserDataCardCPProps} props
 */
export function LoggedUserDataCardCP(props: ILoggedUserDataCardCPProps): JSX.Element {

    const getPersonRequest = useRequest<IPersonResponseDTO>()
    const savePersonRequest = useRequest<PersonSaveRequestDTO>()
    const [formModelValidator, setFormValidator] = useState<PersonFormModel>()
    const formStateManager = useFormStateManager<PersonFormModel>(formModelValidator)
    const [isLoading, setIsLoading] = useState<boolean>(true)
    const [personData, setPersonData] = useState<IPersonResponseDTO>()

    useEffect(initialize, [])
    useEffect(onGetPersonRequestChange, [getPersonRequest.isAwaiting])
    useEffect(onSavePersonRequestChange, [savePersonRequest.isAwaiting])

    /**
     * Inicializa a tela com dados do usuario logado
     */
    function initialize(): void {
        if (!props.userCpfCnpj)
            return

        getPersonRequest.runRequest(PersonRequests.getPersonByCpf(props.userCpfCnpj))
    }

    /**
     * Retorno da requisicao para pegar todos os dados do usuario logado
     */
    function onGetPersonRequestChange(): void {

        if (!RequestUtils.isValidRequestReturn(getPersonRequest, 'Não encontramos o usuário logado'))
            return

        const returnPerson = getPersonRequest.responseData!
        setPersonData(returnPerson)
        setFormValidator(new PersonFormModel(PersonFormUtils.getFormDataFromResponseDto(returnPerson)))
        setIsLoading(false)
    }

    /**
     * Salva pessoa
     */
    async function savePerson(): Promise<void> {

        if (!personData)
            return

        formStateManager.setConsiderAllErrors(true)
        if (!await formStateManager.validate())
            return

        const formValues = formStateManager.getFormValues()
        if (!formValues)
            return

        setIsLoading(true)

        // Dados do Formulário
        const dto = new PersonSaveRequestDTO(formValues)

        // Dados para requisição
        const formatedDTO = PersonFormUtils.getFormDataToRequestDTO(dto)

        // Atualiza usuario
        savePersonRequest.runRequest(PersonRequests.getPersonUpdateConfig(personData.code, formatedDTO))
    }

    /**
     * Apos retorno da api de salvar calendario
     */
    function onSavePersonRequestChange(): void {

        if (!RequestUtils.isValidRequestReturn(savePersonRequest, 'Ocorreu algum erro ao salvar seus dados'))
            return

        NotificationHelper.success('Sucesso', 'Seus dados foram atualizados com sucesso!')
        setIsLoading(false)
    }

    return (
        <CardCP title={'Meus dados'}>
            <LoadingOverlayCP show={isLoading}/>

            <PersonDataContentICP formStateManager={formStateManager} personType={personData?.personType} hidePassword={true}/>

            <ContentWrapperSCP>
                <TitleCP textSize={'small'} underLine={true}>
                    Endereço
                </TitleCP>
                <AddressPanelCP formStateManager={formStateManager}/>
            </ContentWrapperSCP>

            {
                (!!personData && personData.personType === PersonTypeEnum.PF) &&
                <ContentWrapperSCP>
                    <TitleCP textSize={'small'} underLine={true}>
                        Documentos
                    </TitleCP>
                    <PersonDocumentsContentICP personFiles={personData.files}/>
                </ContentWrapperSCP>
            }

            <BtnContainerSCP>
                <ButtonCP
                    icon={'save'}
                    type={'primary'}
                    onClick={savePerson}
                    loading={isLoading}
                >
                    Salvar
                </ButtonCP>
            </BtnContainerSCP>
        </CardCP>
    )
}

const BtnContainerSCP = styled.div`
    display: flex;
    justify-content: flex-end;
    margin-top: 30px;
`

const ContentWrapperSCP = styled.div`
    margin-top: 30px;

    h3 {
        margin: 0 0 25px 5px;
    }
`
