import * as React from 'react'
import { Menu, Badge } from 'antd'
import styled from 'config/theme/styledWithTheme'
import { ObjectUtils } from 'common/utils/ObjectUtils'
import { TooltipCP } from 'common/components/tooltip/TooltipCP'

type _AlignItemTP = 'right' | 'left' | 'center'

type _AntPropsTP = {
    onClick: () => void,
}

type _ScpPropsTP = {
    marginVertical?: number,
    dataTour?: string,
}

type _CustomPropsTP = {
    isActive?: boolean,
    tooltipTitle?: string,
    align?: _AlignItemTP,
    badge?: number,
}

const CLASS_ACTIVE_ANT = 'ant-menu-item-selected'

interface IMenuItemICPProps extends React.PropsWithChildren<_AntPropsTP & _ScpPropsTP & _CustomPropsTP> {}

/**
 * COMPONENTE: Item de 01 menu.
 * OBSERVACAO: Este componente so funciona perfeitamente se for FILHO DIRETO
 * de 01 componente de 'menu' ou 'submenu'
 *
 * @see MenuCP
 * @see SubMenuCP
 *
 * @author Lucas Rosa
 * @author hjcostabr
 */
export function MenuItemCP(props: IMenuItemICPProps): JSX.Element {

    function getAntProps(): {} {

        type _TypesToRemoveTP = _ScpPropsTP & _CustomPropsTP

        const cpProps: Array<keyof _TypesToRemoveTP> = [
            'isActive',
            'tooltipTitle',
            'align',
            'marginVertical',
            'badge'
        ]

        return ObjectUtils.getObjectsWithoutSomeProps<_TypesToRemoveTP>(props, cpProps)
    }

    function getClassName(): string {
        const isSelectable = (props as any).parentMenu?.props?.selectable ?? true
        return (!!props.isActive && isSelectable) ? CLASS_ACTIVE_ANT : ''
    }

    const justifyContent = (props.align === 'left')
        ? 'flex-start'
        : (props.align === 'right' ? 'flex-end' : 'center')

    return (
        <TooltipCP
            placement={'right'}
            text={props.tooltipTitle}
        >
            <MenuItemWrapperSCP
                marginVertical={props.marginVertical}
                justifyContent={justifyContent}
                data-tour={props.dataTour}
            >
                <Menu.Item
                    className={getClassName()}
                    {...getAntProps() /*eslint-disable-line react/jsx-props-no-spreading*/}
                >
                    {
                        props.badge
                            ?
                            <Badge count={props.badge}>
                                {props.children}
                            </Badge>
                            :
                            props.children
                    }
                </Menu.Item>

                {
                    !!props.isActive &&
                    <ActiveItemBarSCP/>
                }
            </MenuItemWrapperSCP>
        </TooltipCP>
    )
}

const MenuItemWrapperSCP = styled.div<_ScpPropsTP & { justifyContent: string }>`

    position: relative;
    margin: ${props => (!!props.marginVertical ? `${props.marginVertical}px 0` : 'unset')};

    .ant-menu-item {

        display: flex;
        justify-content: ${props => props.justifyContent};
        align-items: center;

        i {
            margin: 0;
        }
    }
    
    .ant-badge-count {
      box-shadow: none;
    }
`

const ActiveItemBarSCP = styled.div`
    background-color: ${props => props.theme.secondaryColor};
    width: 3px;
    position: absolute;
    right: 0;
    height: 100%;
    top: 0;
`
