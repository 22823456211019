import { HttpMethodEnum } from 'common/enums/HttpMethodEnum'
import { IUserLoginRequestDTO } from 'modules/auth/dtos/request/IUserLoginRequestDTO'
import { IPasswordResetRequestDTO } from 'modules/auth/dtos/request/IPasswordResetRequestDTO'
import { RequestConfigTP } from 'common/request-manager/types/RequestConfigTP'
import { SystemConfig } from 'config/SystemConfig'

/**
 * REQUISICOES
 * Define parametrizacao para requisicoes relacionadas ao modulo
 * de AUTENTICACAO.
 *
 * @author hjcostabr
 */
export class AuthRequests {

    private static readonly _MODULE_BASE = `${SystemConfig.getInstance().apiBaseUrl}/auth`

    private constructor() {}

    /**
     * Gera & retorna parametros para 01 requisicao de verificacao de validade de
     * token de autenticacao.
     */
    static getTokenVerificationReqConfig = (): RequestConfigTP<null> => ({
        url: `${AuthRequests._MODULE_BASE}/verify`,
        method: HttpMethodEnum.GET,
    })

    /**
     * Gera & retorna parametros para 01 requisicao de LOGIN de usuario de sistema
     */
    static getUserLoginReqConfig = (dto: IUserLoginRequestDTO): RequestConfigTP<IUserLoginRequestDTO> => ({
        url: `${AuthRequests._MODULE_BASE}/login`,
        method: HttpMethodEnum.POST,
        params: dto,
    })

    /**
     * Gera & retorna parametros para 01 requisicao de RECUPERAR SENHA.
     * */
    static getRecoverPwdReqConfig = (dto: IPasswordResetRequestDTO): RequestConfigTP<IPasswordResetRequestDTO> => ({
        url: `${AuthRequests._MODULE_BASE}/forgot-password`,
        method: HttpMethodEnum.PUT,
        params: dto,
    })

}
