import React, { useState } from 'react'
import { Document, Page, pdfjs } from 'react-pdf'
import { styled } from 'config/theme/styledWithTheme'
import { CaretIconCP } from 'common/components/icons/CaretIconCP'
import { CardCP } from 'common/components/card/CardCP'
import loadingDocImg from 'common/components/pdf-viewer/inner/loading_doc.gif'

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`

interface IPdfViewerCPProps {
    file: any
    paginationType: 'scroll' | 'paginator'
    onLoadDocument?: () => void
}

/**
 * COMPONENTE: Visualizador de PDF
 */
export function PdfViewerCP(props: IPdfViewerCPProps): JSX.Element | null {

    const [totalPages, setTotalPages] = useState<number>(0)
    const [showPage, setShowPage] = useState<number>(1)
    const [showPageIndicator, setShowPageIndicator] = useState<boolean>(false)

    function renderPages(): JSX.Element | JSX.Element[] {

        // Se tiver paginacao, retonarna apenas uma pagina
        if (props.paginationType === 'paginator')
            return <Page pageNumber={showPage}/>

        // Senao retorna todas as paginas e tera scroll
        const jsxPages: JSX.Element[] = []

        for (let i = 0; i < totalPages; i++) {
            jsxPages.push(
                <PageWrapperSCP>
                    <Page pageNumber={i + 1}/>
                </PageWrapperSCP>
            )
        }

        return jsxPages
    }

    function onLoadDocument(numPages: number): void {

        setTotalPages(numPages)
        setShowPageIndicator(props.paginationType === 'paginator')

        if (props.onLoadDocument)
            props.onLoadDocument()

    }

    return (
        <ViewerWrapperSCP>
            <DocumentSCP isFixedSize={props.paginationType === 'paginator'}>
                <Document
                    file={props.file}
                    loading={
                        <CardCP>
                            <LoadingWrapperSCP>
                                <img src={loadingDocImg}/>
                            </LoadingWrapperSCP>
                        </CardCP>
                    }
                    onLoadSuccess={({ numPages }) => onLoadDocument(numPages)}
                >
                    { renderPages() }
                </Document>
            </DocumentSCP>
            {
                showPageIndicator &&
                <NavWrapperSCP>
                    {
                        showPage > 1 &&
                        <ArrowSCP onClick={() => setShowPage(showPage - 1)}>
                            <CaretIconCP direction={'left'}/>
                        </ArrowSCP>
                    }

                    { `Página ${showPage} de ${totalPages}` }

                    {
                        showPage < totalPages &&
                        <ArrowSCP onClick={() => setShowPage(showPage + 1)}>
                            <CaretIconCP direction={'right'}/>
                        </ArrowSCP>
                    }
                </NavWrapperSCP>
            }
        </ViewerWrapperSCP>
    )
}

const DocumentSCP = styled.div<{ isFixedSize: boolean }>`
    
    width: ${props => props.isFixedSize ? '' : '90%'};
    max-width: ${props => props.isFixedSize ? '' : '1000px'};
    
    canvas {
        height: ${props => props.isFixedSize ? '80vh !important' : 'auto !important'};
        width: ${props => props.isFixedSize ? 'auto !important' : '100% !important'};
    }
`

const LoadingWrapperSCP = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`

const ViewerWrapperSCP = styled.div`
    background: none;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
`

const NavWrapperSCP = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
`

const ArrowSCP = styled.div`
    margin: 4px;
    padding: 4px;
    cursor: pointer;
`

const PageWrapperSCP = styled.div`
  margin-bottom: 10px;
`
