import React from 'react'
import Menu from 'antd/lib/menu'
import styled from 'config/theme/styledWithTheme'

type _ScpPropsTP = {
    width?: number,
    dataTour?: string,
}

type _AntPropsTP = {
    defaultOpenKeys?: string[],
    selectable?: boolean,
    inlineCollapsed?: boolean,
    onOpenChange?: (openKeys: string[]) => void,
}

interface IMenuCPProps extends React.PropsWithChildren<_ScpPropsTP & _AntPropsTP> {}

/**
 * COMPONENTE: Menu.
 *
 * @author Lucas Rosa
 * @author hjcostabr
 */
export function MenuCP(props: IMenuCPProps): JSX.Element {
    return (
        <WrapperSCP width={props.width} data-tour={props.dataTour}>
            <Menu
                defaultOpenKeys={props.defaultOpenKeys}
                inlineCollapsed={props.inlineCollapsed}
                selectable={props.selectable}
                mode={'inline'}
                onOpenChange={props.onOpenChange}
            >
                {props.children}
            </Menu>
        </WrapperSCP>
    )
}

const WrapperSCP = styled.div<_ScpPropsTP>`
    .ant-menu {
        width: ${props => (props.width ? `${props.width.toString()}px` : 'unset')};
        background: none;
        border-right: 0;
    }
`
