import React from 'react'
import styled from 'styled-components'
import { ButtonCP } from 'common/components/button/ButtonCP'

interface ILoginFormICPProps {
    onFormSubmit: () => void
    showRegister: boolean
    onRecoverPassword?: () => void
    onUserRegister?: () => void
    loading: boolean
}

/**
 * COMPONENTE
 * Botoes do Formulario de login de usuario do sistema.
 */
export function LoginFormButtonsICP(props: ILoginFormICPProps): JSX.Element {

    return (
        <>
            <PrimaryBtnsWrapperSCP singleButton={true} noMargin={false}>
                <ButtonCP
                    size={'large'}
                    type={'primary'}
                    isSubmit={true}
                    loading={props.loading}
                    onClick={props.onFormSubmit}
                >
                    Entrar
                </ButtonCP>
            </PrimaryBtnsWrapperSCP>

            <SecondaryBtnsWrapperSCP singleButton={true} noMargin={true}>
                {
                    props.showRegister &&
                    <ButtonCP
                        onClick={props.onUserRegister}
                        type={'ghost'}
                        size={'small'}
                    >
                        Me cadastrar
                    </ButtonCP>
                }

                <ButtonCP
                    onClick={props.onRecoverPassword}
                    type={'link'}
                >
                    Esqueci minha senha
                </ButtonCP>
            </SecondaryBtnsWrapperSCP>
        </>
    )
}

const PrimaryBtnsWrapperSCP = styled.div<{ singleButton: boolean, noMargin: boolean }>`
    display: flex;
    justify-content: ${props => (props.singleButton ? 'flex-end' : 'space-between')};
    margin-bottom: ${props => (!!props.noMargin ? '' : '50px')};
`

const SecondaryBtnsWrapperSCP = styled.div<{ singleButton: boolean, noMargin: boolean }>`
    display: flex;
    justify-content: ${props => (props.singleButton ? 'center' : 'space-between')};
    margin-bottom: ${props => (!!props.noMargin ? '' : '50px')};
`
