import React from 'react'

import { IIconProps } from './inner/IIconProps'
import { IconICP } from 'common/components/icons/inner/IconICP'

/**
 * ICONE: Upload com nuvem
 *
 * @author renatofs
 */
export const UploadCloudIconCP = (props: IIconProps): JSX.Element => {
    return <IconICP iconName={'cloud-upload'} {...props}/>
}
