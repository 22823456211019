import React from 'react'
import { ListCP } from 'common/components/list/ListCP'
import { ListItemICP } from 'common/components/list/inner/ListItemICP'
import { DocumentStatusCP } from 'modules/document/components/document-status/DocumentStatusCP'
import { IDocumentAssigneeResponseDTO } from 'modules/document/services/dtos/response/IDocumentAssigneeResponseDTO'
import { styled } from 'config/theme/styledWithTheme'
import { AssigneeAvatarCP } from 'modules/document/components/assignee-avatar/AssigneeAvatarCP'
import { DescriptionCP } from 'common/components/description/description/DescriptionCP'
import { DescriptionItemCP } from 'common/components/description/description-item/DescriptionItemCP'
import { MaskUtils } from 'common/utils/MaskUtils'
import { InputMaskTypeEnum } from 'common/enums/InputMaskTypeEnum'

interface IAssigneeTableCPProps {
    assignees: IDocumentAssigneeResponseDTO[]
}

/**
 * Tabela de signatarios de um documento.
 */
export function AssigneeTableCP(props: IAssigneeTableCPProps): JSX.Element {

    return (
        <ListCP<IDocumentAssigneeResponseDTO>
            dataSource={props.assignees}
            renderItem={(assignee) => (
                <ListItemICP
                    avatar={<AssigneeAvatarCP assignee={assignee} showTooltip={false}/>}
                    paddingLeft={5}
                    paddingRight={5}
                    title={assignee.person.name}
                    content={
                        <AssigneeItemSCP>
                            <DescriptionCP>
                                <DescriptionItemCP label={'CPF'}>
                                    <>{ MaskUtils.applyMask(assignee.person.cpfCnpj, InputMaskTypeEnum.CPF) }</>
                                </DescriptionItemCP>
                                <DescriptionItemCP label={'E-mail'}>
                                    {assignee.person.email}
                                </DescriptionItemCP>
                            </DescriptionCP>
                            <AssigneeOrderDescSCP>
                                <DocumentStatusCP status={assignee.signed}/>
                            </AssigneeOrderDescSCP>
                        </AssigneeItemSCP>
                    }
                />
            )}
        />
    )
}

const AssigneeItemSCP = styled.div`
  .ant-descriptions-row > td {
      padding-bottom: 0px !important;
  }
`

const AssigneeOrderDescSCP = styled.div`
  font-size: 12px;
  color: #adadad;
  display: flex;
  justify-content: flex-end;
`
