/**
 * ROTEADOR
 * Rotas do modulo de autenticacao.
 *
 * OBSERVACAO: Neste caso a definicao de rotas nao eh 01 componente porque
 * NAO ha roteamento interno, no modulo.
 *
 * @author hjcostabr
 */
export const AuthRoutes = {
    /* eslint-disable @typescript-eslint/naming-convention */
    USER_LOGIN: '/login',
    RECOVER_PWD: '/recover-password',
    /* eslint-enable @typescript-eslint/naming-convention */
}
