import React from 'react'
import { PermissionUtils } from 'modules/auth/utils/PermissionUtils'
import { ModuleEnum } from 'common/enums/ModuleEnum'
import { PermissionEnum } from 'modules/auth/enums/PermissionEnum'
import { LogicOperationTP } from 'common/types/LogicOperationTP'
import { RoutingHelper } from 'common/helpers/RoutingHelper'
import { PersonTypeEnum } from 'modules/person/enums/PersonTypeEnum'

interface IAccessControlCPProps {
    children: React.ReactNode
    module?: ModuleEnum
    personType?: PersonTypeEnum
    permissions?: PermissionEnum[]
    routeToReturn?: string
    logic?: LogicOperationTP
    showInvalidAccessNotification?: boolean
    redirect?: boolean
}

/**
 * COMPONENTE
 * Wrapper que abstrai tratamento generico para restricao de acesso a
 * recursos do sistema dependendo da compatibilidade entre a combinacao
 * de restricoes definidas para o recurso, os modulos habilitados para o
 * schema e as permissoes do usuario atual logado.
 *
 * @author hjcostabr
 */
export function AccessControlCP(props: IAccessControlCPProps): JSX.Element | null {

    if (PermissionUtils.hasAccess(props.module, props.permissions, props.personType, props.logic))
        return <>{props.children}</>

    if (props.redirect === false)
        return null

    if (props.showInvalidAccessNotification !== false)
        PermissionUtils.showDefaultDeniedAccessMsg()

    return RoutingHelper.renderRedirect(props.routeToReturn ?? 'default')
}
