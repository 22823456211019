/* eslint-disable no-console */
import { GenderEnum } from 'modules/person/enums/GenderEnum'
import { NacionalityEnum } from '../enums/NacionalityEnum'
import { PersonTypeEnum } from '../enums/PersonTypeEnum'

/**
 * UTILITARIOS para entidade Pessoa.
 */
export const PersonUtils = {

    getGenderLabel(gender: GenderEnum): string {
        switch (gender) {
            case GenderEnum.MALE:
                return 'Masculino'
            case GenderEnum.FEMALE:
                return 'Feminino'
            default:
                return 'Outro'
        }
    },

    getNacionalityLabel(nacionality: NacionalityEnum): string {
        switch (nacionality) {
            case NacionalityEnum.FOREIGN:
                return 'Estrangeiro'
            default:
                return 'Brasileiro'
        }
    },

    getPersonTypeLabel(type: PersonTypeEnum): string {
        switch (type) {
            case PersonTypeEnum.PJ:
                return 'Jurídica'
            default:
                return 'Física'
        }
    },

    async getPersonIP(): Promise<string> {

        const result = await fetch('https://api.ipify.org/?format=json').then((response) => {
            if (response.status === 200)
                return response.json()

            console.info('> Erro', response.status, response.statusText)
            return

        }).catch((error) => {
            console.info('> Falha ao realizar requisição ', error)
            return
        })

        return result.ip
    },

}
