import { Input } from 'antd'
import { FormItemICP } from 'common/components/form-fields/inner/FormItemICP'
import { IFormItemInputCommonProps } from 'common/components/form-fields/inner/interfaces/IFormItemInputCommonProps'
import { InputTypeTP } from 'common/components/form-fields/input/inner/types/InputTypeTP'
import { styled } from 'config/theme/styledWithTheme'
import { FontSizeTP } from 'config/theme/ThemeTypes'
import React from 'react'
import { InputSuffixIconICP } from 'common/components/form-fields/input/inner/components/InputSuffixIconICP'
import { FormModel } from 'common/form-state-manager/classes/FormModel'
import { InputDateModeTP } from 'common/components/form-fields/input/inner/types/InputDateModeTP'
import { InputDateICP } from 'common/components/form-fields/input/inner/components/date/InputDateICP'
import { CSSProperties } from 'styled-components'

export interface IInputCPProps<FModelTP extends FormModel> extends IFormItemInputCommonProps<FModelTP> {
    type?: InputTypeTP
    dateMode?: InputDateModeTP
    icon?: JSX.Element
    min?: number
    maxlength?: number
    loading?: boolean
    autoFocus?: boolean
    help?: string | JSX.Element
    style?: CSSProperties
}

/**
 * COMPONENTE
 * Input generico para formularios.
 *
 * NOTE: Quando controlado via 'form state manager' valor nao eh determinado diretamente pela prop 'value'
 *
 * TODO: Simplificar / Melhorar isso
 * TODO: Desmembrar componentes em inputs de tipos diferentes
 * TODO: Desmembrar componentes em 'com state manager' / 'sem state manager'
 *
 * @see FormStateManager
 */
export function InputCP<FModelTP extends FormModel = any>(props: IInputCPProps<FModelTP>): JSX.Element {

    const suffixIconCp = <InputSuffixIconICP type={props.type} isLoading={props.loading} icon={props.icon}/>

    return (
        <InputWrapperSCP fontSize={props.fontSize ?? 'normal'}>
            <FormItemICP<FModelTP, IFormItemInputCommonProps<FModelTP>>
                label={props.label}
                width={props.width}
                value={props.value}
                onChange={props.onChange}
                onBlur={props.onBlur}
                required={props.required}
                fontSize={props.fontSize}
                fieldName={props.fieldName}
                onFormSubmit={props.onFormSubmit}
                errorMessage={props.errorMessage}
                noValidation={props.noValidation}
                formStateManager={props.formStateManager}
                mask={props.mask}
                debug={props.debug}
                help={props.help}
            >
                {
                    (props.type === 'date')
                        ? <InputDateICP suffixIcon={suffixIconCp} mode={props.dateMode}/>
                        : <Input
                            type={props.type ?? 'text'}
                            suffix={suffixIconCp}
                            min={props.min}
                            disabled={props.disabled}
                            autoFocus={props.autoFocus}
                            maxLength={props.maxlength}
                            style={props.style}
                        />
                }
            </FormItemICP>
        </InputWrapperSCP>
    )
}

const InputWrapperSCP = styled.div<{ fontSize: FontSizeTP }>`
    width: 100%;
`
