import React from 'react'
import { ColumnProps } from 'antd/lib/table'
import { INotificationResponseDTO } from 'modules/notification/services/dtos/response/INotificationResponseDTO'
import { TextCP } from 'common/components/text/TextCP'
import { DateUtils } from 'common/utils/date/DateUtils'
import { DateFormatEnum } from 'common/enums/DateFormatEnum'
import { SwitchCP } from 'common/components/switch/SwitchCP'
import { ButtonCP } from 'common/components/button/ButtonCP'
import { MoreContentIconCP } from 'common/components/icons/MoreContentIconCP'

/**
 * UTILITARIOS do componente de listagem de notificacoes
 */
export const NotificationTableUtils = {

    getTableColumnsConfig(
        onSelectNotification: (dto: INotificationResponseDTO) => void,
        onMarkAsRead: (code: number) => void
    ): Array<ColumnProps<INotificationResponseDTO>> {

        return [
            {
                title: 'Status',
                dataIndex: 'status',
                key: 'status',
                render: (text, dto) => (
                    <SwitchCP
                        isChecked={dto.wasRead}
                        isTextInside={true}
                        textInsideChecked={'Lida'}
                        textInsideUnchecked={'Não lido'}
                        disabled={dto.wasRead}
                        onChange={() => onMarkAsRead(dto.code)}
                    />
                )
            }, {
                title: 'Título',
                dataIndex: 'title',
                key: 'title',
                render: (text, dto) => (
                    <>
                        <TextCP text={dto.title} strong={!dto.wasRead}/>
                        <i>{dto.description}</i>
                    </>
                )
            }, {
                title: 'Data',
                dataIndex: 'cdate',
                key: 'cdate',
                render: (text, dto) => DateUtils.getFormatted(dto.date, DateFormatEnum.BR_WITH_TIME_H_M)
            }, {
                title: '',
                dataIndex: 'act',
                key: 'act',
                align: 'right',
                render: (text, dto) => (
                    <ButtonCP
                        size={'small'}
                        tooltip={'Mais detalhes'}
                        type={'ghost'}
                        onClick={() => onSelectNotification(dto)}
                    >
                        <MoreContentIconCP size={14}/>
                    </ButtonCP>
                )
            }
        ]
    }

}
