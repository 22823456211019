import React from 'react'
import { SiderCP } from 'common/components/screen-layout/sider/SiderCP'
import { ThemeConfig } from 'config/theme/ThemeConfig'
import { ContentCP } from 'common/components/screen-layout/content/ContentCP'
import { LayoutCP } from 'common/components/screen-layout/layout/LayoutCP'
import { styled } from 'config/theme/styledWithTheme'
import { HeaderCP } from 'common/components/screen-layout/header/HeaderCP'
import { DocumentTableCP } from 'modules/document/components/document-table/DocumentTableCP'
import { DirectoryTreeCP } from 'common/components/directory-tree/DirectoryTreeCP'
import { useScreenSize } from 'common/responsiveness/use-screen-size/UseScreenSize'

// Por enquanto terá apenas a raiz. Futuramente teremos subpastas
const TREE_DATA = [
    {
        title: 'Todos Documentos',
        key: 'root',
        children: [],
    }
]

/**
 * TELA de listagem de documentos.
 */
export function DocumentListScreen(): JSX.Element {

    const screenSize = useScreenSize()

    return (
        <>
            <LayoutCP
                sider={
                    <>
                        {
                            !screenSize.smd &&
                            <SiderCP width={275} bgColor={ThemeConfig.secondMenuBg}>
                                <SiderWrapperSCP>
                                    <HeaderCP
                                        title={'Meus Documentos'}
                                        noBackground={true}
                                        titleSize={'large'}
                                    />

                                    <DirectoryTreeCP
                                        expandedKeys={['root']}
                                        data={TREE_DATA}
                                    />
                                </SiderWrapperSCP>
                            </SiderCP>
                        }
                    </>
                }
                content={
                    <ContentCP>

                        {
                            screenSize.smd &&
                            <HeaderCP title={'Meus Documentos'}/>
                        }

                        <DocumentTableCP
                            showPagination={true}
                        />
                    </ContentCP>
                }
            />
        </>
    )
}

const SiderWrapperSCP = styled.div`
    padding: 5px;
`
