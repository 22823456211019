import React, { useEffect, useState } from 'react'
import { CardCP } from 'common/components/card/CardCP'
import { TableCP } from 'common/components/table/TableCP'
import { DocumentTableUtils } from 'modules/document/components/document-table/inner/DocumentTableUtils'
import { NotificationHelper } from 'common/helpers/NotificationHelper'
import * as _ from 'lodash'
import { IDocumentResponseDTO } from 'modules/document/services/dtos/response/IDocumentResponseDTO'
import { IGenericListResponseDTO } from 'common/dtos/responses/IGenericListResponseDTO'
import { useRequest } from 'common/request-manager/use-request/UseRequest'
import { DocumentRequests } from 'modules/document/services/DocumentRequests'
import { RequestUtils } from 'common/request-manager/RequestUtils'
import { DocumentDrawerCP } from 'modules/document/components/document-drawer/DocumentDrawerCP'

interface IDocumentTableCPProps {
    folder?: string
    tableTitle?: string
    showPagination: boolean
    hideOnEmpty?: boolean
}

/**
 * TELA de listagem de documentos.
 */
export function DocumentTableCP(props: IDocumentTableCPProps): JSX.Element {

    const [selectedDocumentCode, setSelectedDocumentCode] = useState<number>()
    const [isDrawerVisible, setIsDrawerVisible] = useState<boolean>(false)

    useEffect(initialize, [])

    const searchDocumentsRequest = useRequest<IGenericListResponseDTO<IDocumentResponseDTO>>()
    useEffect(onSearchDocumentsRequestChange, [searchDocumentsRequest.isAwaiting])

    /**
     * Inicializa a tela buscando os documentos
     */
    function initialize(): void {
        searchDocumentsRequest.runRequest(DocumentRequests.searchConfig())
    }

    /**
     * Retorno da requisicao que busca os documentos
     */
    function onSearchDocumentsRequestChange(): void {

        if (!RequestUtils.isValidRequestReturn(searchDocumentsRequest, 'Erro obter documentos'))
            return

    }

    /**
     * Prepara para abrir drawer de detalhes
     */
    function prepareToOpenDrawer(_code: number): void {
        setSelectedDocumentCode(_code)
        setIsDrawerVisible(true)
    }

    /**
     * Fechar drawer de detalhes.
     */
    function onCloseDrawer(dataChanged: boolean): void {

        if (dataChanged)
            NotificationHelper.info('recarregar')

        setIsDrawerVisible(false)
    }

    if (!!props.hideOnEmpty && _.isEmpty(searchDocumentsRequest.responseData?.list))
        return <></>

    return (
        <>
            <CardCP
                title={props.tableTitle}
                innerSpacing={'small'}
            >

                <TableCP
                    columns={DocumentTableUtils.getTableColumnsConfig(prepareToOpenDrawer)}
                    data={searchDocumentsRequest.responseData?.list}
                    loading={searchDocumentsRequest.isAwaiting}
                    showHeader={true}
                />

            </CardCP>

            <DocumentDrawerCP
                visible={isDrawerVisible}
                documentCode={selectedDocumentCode}
                onClose={() => onCloseDrawer(false)}
            />
        </>
    )
}
