import React, { useEffect, useState } from 'react'
import { FileUploadCP } from 'common/components/file-upload/FileUploadCP'
import { TextCP } from 'common/components/text/TextCP'
import { MimeTypeEnum } from 'common/enums/MimeTypeEnum'
import { useRequest } from 'common/request-manager/use-request/UseRequest'
import { PersonRequests } from 'modules/person/services/PersonRequests'
import { PersonDocumentEnum } from 'modules/person/enums/PersonDocumentEnum'
import { NotificationHelper } from 'common/helpers/NotificationHelper'
import { RequestUtils } from 'common/request-manager/RequestUtils'
import { IPersonFilesResponseDTO } from 'modules/person/services/dtos/response/IPersonFilesResponseDTO'

interface IPersonDocumentsContentICPProps {
    personFiles?: IPersonFilesResponseDTO
    onAllFilesUploaded?: () => void
}

/**
 * COMPONENTE
 * Painel de upload de documentos.
 */
export function PersonDocumentsContentICP(props: IPersonDocumentsContentICPProps): JSX.Element {
    const [selfieFile, setSelfieFile] = useState<File>()
    const [idFrontFile, setIdFrontFile] = useState<File>()
    const [idBackFile, setIdBackFile] = useState<File>()
    const [holdingIdFile, setHoldingIdFile] = useState<File>()

    const [isSelfieLoading, setIsSelfieLoading] = useState<boolean>(false)
    const [isIdFrontLoading, setIsdFrontLoading] = useState<boolean>(false)
    const [isIdBackLoading, setIsIdBackLoading] = useState<boolean>(false)
    const [isHoldingIdLoading, setIsHoldingIdLoading] = useState<boolean>(false)

    const uploadPersonDocumentRequest = useRequest()

    useEffect(() => uploadFile(PersonDocumentEnum.SELFIE), [selfieFile])
    useEffect(() => uploadFile(PersonDocumentEnum.ID_FRONT), [idFrontFile])
    useEffect(() => uploadFile(PersonDocumentEnum.ID_BACK), [idBackFile])
    useEffect(() => uploadFile(PersonDocumentEnum.PHOTO_HOLDING_ID), [holdingIdFile])

    useEffect(onUploadDocumentRequestChange, [uploadPersonDocumentRequest.isAwaiting])
    useEffect(validateAllFiles, [selfieFile, idFrontFile, idBackFile, holdingIdFile])

    function uploadFile(fileEnum: PersonDocumentEnum): void {

        if (!selfieFile && !idFrontFile && !idBackFile && !holdingIdFile)
            return

        let file
        switch (fileEnum) {
            case PersonDocumentEnum.SELFIE:
                file = selfieFile
                setIsSelfieLoading(true)
                break
            case PersonDocumentEnum.ID_FRONT:
                file = idFrontFile
                setIsdFrontLoading(true)
                break
            case PersonDocumentEnum.ID_BACK:
                file = idBackFile
                setIsIdBackLoading(true)
                break
            case PersonDocumentEnum.PHOTO_HOLDING_ID:
                file = idBackFile
                setIsHoldingIdLoading(true)
                break
            default:
                break
        }

        if (!file)
            return

        uploadPersonDocumentRequest.runRequest(PersonRequests.uploadDocumentConfig(fileEnum, file))
    }

    /**
     * Retorno da api de Upload de Foto
     */
    function onUploadDocumentRequestChange(): void {

        if (!RequestUtils.isValidRequestReturn(uploadPersonDocumentRequest, 'Ocorreu algum erro fazer upload da foto'))
            return

        NotificationHelper.success('Foto enviada com sucesso!')
        setIsSelfieLoading(false)
        setIsdFrontLoading(false)
        setIsIdBackLoading(false)
        setIsHoldingIdLoading(false)
    }

    /**
     * Valida todos os uploads foram realizados com sucesso
     */
    function validateAllFiles(): void {

        if (!selfieFile || !idFrontFile || !idBackFile || !holdingIdFile)
            return

        // Chama callback que para termino da operacao
        if (!!props.onAllFilesUploaded)
            props.onAllFilesUploaded()
    }

    return (
        <>

            <TextCP text={'Foto do Rosto'}/>
            <FileUploadCP
                uploadFiles={setSelfieFile}
                fileTypes={[MimeTypeEnum.PNG, MimeTypeEnum.JPEG]}
                loading={isSelfieLoading}
                isUploaded={!!props.personFiles?.facePhoto || !!selfieFile}
            />

            <TextCP text={'Documento com CPF - FRENTE (ID, CNH, entre outros)'} style={{ marginTop: '20px' }}/>
            <FileUploadCP
                uploadFiles={setIdFrontFile}
                fileTypes={[MimeTypeEnum.PNG, MimeTypeEnum.JPEG]}
                loading={isIdFrontLoading}
                isUploaded={!!props.personFiles?.identityFrontPhoto || !!idFrontFile}
            />

            <TextCP text={'Documento com CPF - VERSO (ID, CNH, entre outros)'} style={{ marginTop: '20px' }}/>
            <FileUploadCP
                uploadFiles={setIdBackFile}
                fileTypes={[MimeTypeEnum.PNG, MimeTypeEnum.JPEG]}
                loading={isIdBackLoading}
                isUploaded={!!props.personFiles?.identityBackPhoto || !!idBackFile}
            />

            <TextCP text={'Foto do rosto segurando o documento ao lado'} style={{ marginTop: '20px' }}/>
            <FileUploadCP
                uploadFiles={setHoldingIdFile}
                fileTypes={[MimeTypeEnum.PNG, MimeTypeEnum.JPEG]}
                loading={isHoldingIdLoading}
                isUploaded={!!props.personFiles?.mirrorPhoto || !!holdingIdFile}
            />

        </>
    )
}
