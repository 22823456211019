import { ReduxUtils } from 'common/redux/ReduxUtils'
import { AuthActions } from 'modules/auth/AuthActions'
import { IReduxState } from 'common/redux/interfaces/IReduxState'
import { UserDataTP } from 'common/redux/types/UserDataTP'
import { ReducersMapObject } from 'redux'
import { DomainDataTP } from 'common/redux/types/DomainDataTP'

/**
 * REDUX
 * Determina mapa de reducers para gestao de estado da aplicacao
 * via redux.
 *
 * @author hjcostabr
 * @author Lucas Rosa
 */
export const Reducers: ReducersMapObject<IReduxState> = {
    userData: ReduxUtils.getPayloadReplacingReducer<UserDataTP>(AuthActions.TYPE_UPDATE_USER),
    domainData: ReduxUtils.getPayloadReplacingReducer<DomainDataTP>(AuthActions.TYPE_UPDATE_DOMAIN),
}
