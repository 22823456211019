import React, { useState } from 'react'
import { IDocumentForSignResponseDTO } from 'modules/document/services/dtos/response/IDocumentForSignResponseDTO'
import { DocumentToSignScreenContentICP } from 'modules/document-signature/components/sign-document-screen-content/inner/DocumentToSignScreenContentICP'
import { DocumentSignedOrDeclinedScreenContentICP } from 'modules/document-signature/components/sign-document-screen-content/inner/DocumentSignedOrDeclinedScreenContentICP'
import { DocumentStatusEnum } from 'modules/document/enums/DocumentStatusEnum'

type PageViewTP = 'sign' | 'result'

interface ISignDocumentScreenContentCPProps {
    documentForSign: IDocumentForSignResponseDTO
}

/**
 * TELA: Tela para assinatura de um documento.
 */
export function SignDocumentScreenContentCP(props: ISignDocumentScreenContentCPProps): JSX.Element {

    const [pageView, setPageView] = useState<PageViewTP>(props.documentForSign.personToSign.signed === DocumentStatusEnum.AWAITING_SIGNATURE ? 'sign' : 'result')
    const [signedOrDecline, setSignedOrDecline] = useState<DocumentStatusEnum>(props.documentForSign.personToSign.signed)

    function onSignOrDecline(_signed: DocumentStatusEnum): void {
        setSignedOrDecline(_signed)
        setPageView('result')
    }

    return (
        <>
            {
                pageView === 'sign' &&
                <DocumentToSignScreenContentICP
                    documentForSign={props.documentForSign}
                    onSignOrDecline={onSignOrDecline}
                />
            }

            {
                pageView === 'result' &&
                <DocumentSignedOrDeclinedScreenContentICP
                    signed={signedOrDecline}
                />
            }
        </>
    )
}
