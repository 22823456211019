import { LoggingManager } from 'common/logging/LoggingManager'
import { ReduxPersistorHelper } from 'common/redux/helpers/ReduxPersistorHelper'
import { IAction } from 'common/redux/interfaces/IAction'
import { IReduxState } from 'common/redux/interfaces/IReduxState'
import { Reducers } from 'common/redux/Reducers'
import { ReduxUtils } from 'common/redux/ReduxUtils'
import { UserDataTP } from 'common/redux/types/UserDataTP'
import { AuthActions } from 'modules/auth/AuthActions'
import { applyMiddleware, combineReducers, createStore, Store, StoreEnhancer } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import { persistReducer, persistStore } from 'redux-persist'
import { Persistor } from 'redux-persist/es/types'
import reduxThunkMiddleware from 'redux-thunk'

/**
 * REDUX
 * Encapsula logica para configuracao do gerenciamento de
 * estado da aplicacao via redux.
 *
 * @author hjcostabr
 */
export class ReduxHelper {

    /** Store (guarda estado global da aplicacao). */
    private readonly _store: Store

    /** Persistor (elemento que garante persistencia de partes do estado global). */
    private readonly _persistor: Persistor

    /** Flag: Habilita OU NAO middleware para debug de actions do redux. */
    private static readonly _ENABLE_LOG = false

    /** Instancia unica da classe. */
    private static _instance: ReduxHelper

    private constructor() {
        const combinedReducers = combineReducers<IReduxState>(Reducers)
        const persistedReducer = persistReducer(ReduxPersistorHelper.getPersistorConfig(), combinedReducers)
        this._store = createStore(persistedReducer, ReduxHelper._getMiddleware())
        this._persistor = (persistStore(this._store) as unknown) as Persistor
    }

    /** Gera & retorna instancia unica da manager. */
    static getInstance(): ReduxHelper {
        if (!this._instance)
            this._instance = new ReduxHelper()
        return this._instance
    }

    /** Gera & retorna lista de middleware a ser aplicado no redux. */
    private static _getMiddleware(): StoreEnhancer {

        const middlewareList: any[] = [
            reduxThunkMiddleware,
            ReduxUtils.createMiddleware(this._rehydrateMWCallback, ReduxPersistorHelper.ACTION_REHYDRATE),  // Rehydrate middleware
        ]

        // Incluir middleware para log/debug do redux (se necessario)
        if (this._ENABLE_LOG)
            middlewareList.push(ReduxUtils.createMiddleware(LoggingManager.logReduxAction))

        const appliedMiddleware = applyMiddleware(...middlewareList)
        if (process.env.REACT_APP_STAGE === 'PROD')
            return appliedMiddleware
        return composeWithDevTools(appliedMiddleware)
    }

    /**
     * Callback para middleware que intercepta action de 'reidratacao' de estado
     * (redux-persist).
     */
    private static _rehydrateMWCallback(action: IAction): void {
        const token = ((action.payload as IReduxState)?.userData as UserDataTP)?.token
        if (!!token)
            AuthActions.refreshLoginData(token)
    }

    get store(): Store<IReduxState> {
        return this._store
    }

    get persistor(): Persistor {
        return this._persistor
    }
}

