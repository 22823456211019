/**
 * UTILITARIOS: Para mansear objetos genericos.
 *
 * @author: hjcostabr
 */
export const ObjectUtils = {

    /** Retorna copia de 01 objeto removendo 01 lista de propriedaades do mesmo. */
    getObjectsWithoutSomeProps<ChildPropsTP>(obj: any, propsToRemove: Array<keyof ChildPropsTP>): {} {

        const clearedObj = { ...obj }

        for (const propToRemove of propsToRemove)
            delete clearedObj[propToRemove]

        return clearedObj
    },
}
