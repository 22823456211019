import { List, Skeleton } from 'antd'
import { ColumnProps, PaginationConfig } from 'antd/lib/table'
import { EmptyCP } from 'common/components/empty/EmptyCP'
import { ListItemMobileICP } from 'common/components/table/inner/ListItemMobileICP'
import React from 'react'

interface IListMobileICPProps<RowTP> {
    data?: RowTP[]
    columns?: Array<ColumnProps<RowTP>>
    pagination?: PaginationConfig | false
    onPaginationChange?: (pagination: PaginationConfig) => void
    bordered?: boolean
    loading?: boolean
}

/**
 * COMPONENTE
 *
 * @author Lucas Rosa
 */
export function ListMobileICP<RowTP>(props: IListMobileICPProps<RowTP>): JSX.Element {
    function onPaginationChange(page: number, pageSize?: number): void {
        if (props.onPaginationChange) {
            props.onPaginationChange({
                ...props.pagination,
                current: page,
                pageSize
            })
        }
    }
    return (
        <List<RowTP>
            dataSource={props.loading ? dummyListData : props.data}
            renderItem={(item: RowTP) => (
                <Skeleton loading={props.loading} active={true}>
                    <ListItemMobileICP item={item} columns={props.columns!}/>
                </Skeleton>
            )}
            loading={false}
            pagination={props.pagination ?
                {
                    ...props.pagination,
                    position: 'bottom',
                    size: 'small',
                    onChange: onPaginationChange
                }
                : false}
            locale={{ emptyText: <EmptyCP description={'Nenhum dado encontrado para os filtros selecionados'}/> }}
            size={'small'}
        />
    )
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const dummyListData: any[] = [{}, {}, {}, {}, {}]