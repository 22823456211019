import React from 'react'
import styled from 'config/theme/styledWithTheme'
import { LoginFormCP } from 'modules/auth/components/user-login-form/LoginFormCP'
import { NotificationHelper } from 'common/helpers/NotificationHelper'
import { AlertCP } from 'common/components/alert/AlertCP'
import { TextCP } from 'common/components/text/TextCP'
import { IDocumentAssigneeResponseDTO } from 'modules/document/services/dtos/response/IDocumentAssigneeResponseDTO'

interface ILoginOrRegisterContentICPProps {
    onLoginSuccess: () => void
    personToSign: IDocumentAssigneeResponseDTO
}

/**
 * COMPONENTE: Conteudo do modal quando usuario ira logar ou se cadastrar.
 */
export function LoginContentICP(props: ILoginOrRegisterContentICPProps): JSX.Element {

    return (
        <>
            <AlertCP
                message={
                    <>
                        <TextCP text={'Para assinar/recusar o documento entre com seus dados de acesso.'} wrap={true} style={{ marginBottom: '10px' }}/>
                        <TextCP text={'Se este for seu primeiro acesso, utilize os dados enviados para o seu e-mail'} wrap={true}/>
                    </>
                }
                type={'info'}
                marginBottom={30}
            />

            <ContentWrapperSCP>
                <LoginFormCP
                    maxWidth={380}
                    showRemember={false}
                    showRegister={false}
                    onRecoverPassword={() => NotificationHelper.info('Recuperar Senha')}
                    onLoginSuccess={props.onLoginSuccess}
                    emailDisabled={true}
                    email={props.personToSign.person.email}
                />
            </ContentWrapperSCP>
        </>
    )
}

const ContentWrapperSCP = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
`
