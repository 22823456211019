import { MaskUtils } from 'common/utils/MaskUtils'
import { AddressTypeEnum } from 'common/components/form-fields/address-type-select/inner/AddressTypeEnum'
import { IAddressResponseDTO } from 'modules/person/services/dtos/response/IAddressResponseDTO'
import { IAddressSaveRequestDTO } from 'modules/person/services/dtos/request/IAddressSaveRequestDTO'

/**
 * UTILITARIOS
 * Enderecos
 *
 * @author hjcostabr
 */
export class AddressUtils {

    /** Avalia & informa SE ha campos nao nulos de endereco em 01 objeto. */
    static isAddressDirty(object: any): boolean {

        const addressFieldNames: Array<keyof IAddressSaveRequestDTO> = [
            'zipCode',
            'street',
            'number',
            'complement',
            'neighborhood',
            'city',
            'state',
        ]

        for (const addressField of addressFieldNames) {
            if (!!object[addressField])
                return true
        }
        return false
    }

    /**
     * Gera & retorna 01 dto de cadastro de endereco a partir de 01
     * objeto qualquer.
     */
    static getAddressDtoFromFormData(object: any): IAddressSaveRequestDTO {
        return {
            zipCode: !!object.zipCode ? MaskUtils.removeMask(object.zipCode) : undefined,
            street: object.street,
            number: object.number,
            complement: object.complement,
            neighborhood: object.neighborhood,
            city: object.city,
            state: object.state,
        }
    }

    /**
     * Formata endereço para uma string
     */
    static formatAddress(addressObj: IAddressResponseDTO): string {

        let addressStr = ''

        addressStr += !!addressObj.street ? addressObj.street : ''
        addressStr += !!addressObj.number ? `, ${addressObj.number}` : ''
        addressStr += !!addressObj.complement ? ` / ${addressObj.complement}` : ''

        if (addressObj.neighborhood)
            addressStr += addressStr ? ` - ${addressObj.neighborhood} ` : addressObj.neighborhood

        if (addressObj.city)
            addressStr += addressStr ? `, ${addressObj.city} ` : addressObj.city

        if (addressObj.state)
            addressStr += addressStr ? ` - ${addressObj.state} ` : addressObj.state

        if (addressObj.zipCode)
            addressStr += addressStr ? `, ${addressObj.zipCode} ` : addressObj.zipCode

        return addressStr
    }

    static getAddressTypeLabel(addressType: AddressTypeEnum): string {
        switch (addressType) {
            case AddressTypeEnum.WORK:
                return 'Comercial'
            default:
                return 'Residencial'
        }
    }

}
