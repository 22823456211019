import { DateUtils } from 'common/utils/date/DateUtils'
import { DateFormatEnum } from 'common/enums/DateFormatEnum'
import { NotificationHelper } from 'common/helpers/NotificationHelper'
import { PersonFormModel } from '../models/PersonFormModel'
import { IFormStateManager } from 'common/form-state-manager/interfaces/IFormStateManager'
import { AddressUtils } from 'common/utils/AddressUtils'
import { IPersonResponseDTO } from '../services/dtos/response/IPersonResponseDTO'
import { PersonSaveRequestDTO } from '../services/dtos/request/PersonSaveRequestDTO'
import { IPersonSaveRequestDTO } from '../services/dtos/request/IPersonSaveRequestDTO'
import { MaskUtils } from 'common/utils/MaskUtils'

/**
 * UTILTARIOS
 * Reune metodos uteis para manipular formulario de cadastro de pessoa.
 */
export const PersonFormUtils = {

    /** Tratamento generico para validacao de formularios de cadsatro de pessoa. */
    async validateForm<FModelTP extends PersonFormModel>(formStateManager: IFormStateManager<FModelTP>): Promise<boolean> {

        const isValid = await formStateManager.validate()

        if (!isValid) {

            const errMessage = !!formStateManager.getFieldError('_atLeastOneRequired' as keyof FModelTP)
                ? 'Informe no mínimo nome ou e-mail ou celular'
                : 'Preencha os campos corretamente para prosseguir'

            NotificationHelper.error('Ops!', errMessage)
            return false
        }

        // Validacao especifica para cadastro de memmbro da equipe
        const formValues = formStateManager.getFormValues()

        // Validacao de campos de endereco
        if (!formValues) {
            console.error('FALHA - PersonUtils.validateForm: ', 'Erro no formulario')
            return false
        }

        if (AddressUtils.isAddressDirty(formValues) && !formValues?.state) {
            NotificationHelper.error('Ops!', 'Informe o estado para prosseguir')
            return false
        }

        return true
    },

    /**
     * Gera & retorna valores para formulario de cadastro de pessoa
     * a partir de 01 dto de dados de pessoa recebido da api.
     */
    getFormDataFromResponseDto(dto: IPersonResponseDTO): Partial<PersonFormModel> {
        return {
            email: dto.email,
            personType: dto.personType,
            name: dto.name,
            cpfCnpj: dto.cpfCnpj,
            birthDate: !!dto.birthDate ? DateUtils.getFormatted(dto.birthDate, DateFormatEnum.BR_WITHOUT_TIME) : undefined,
            nacionality: dto.nacionality,
            gender: dto.gender,
            cellPhone: dto.cellPhone,
            homePhone: dto.homePhone,
            zipCode: dto.address?.zipCode,
            addressType: dto.address?.addressType,
            street: dto.address?.street,
            number: dto.address?.number,
            complement: dto.address?.complement,
            neighborhood: dto.address?.neighborhood,
            city: dto.address?.city,
            state: dto.address?.state
        }
    },

    /**
     * Gera & retorna valores para formulario de cadastro de pessoa
     * a partir de 01 dto de dados de pessoa recebido de um Form Model.
     */
    getFormDataToRequestDTO(dto: PersonSaveRequestDTO): IPersonSaveRequestDTO {
        return {
            email: dto.email,
            name: dto.name,
            cpfCnpj: MaskUtils.removeMask(dto.cpfCnpj),
            personType: dto.personType,
            birthDate: dto.birthDate,
            nacionality: dto.nacionality,
            gender: dto.gender,
            homePhone: !!dto.homePhone ? MaskUtils.removeMask(dto.homePhone) : undefined,
            cellPhone: !!dto.cellPhone ? MaskUtils.removeMask(dto.cellPhone) : undefined,
            address: {
                zipCode: !!dto.zipCode ? MaskUtils.removeMask(dto.zipCode) : undefined,
                addressType: dto.addressType,
                street: dto.street,
                number: dto.number,
                complement: dto.complement,
                neighborhood: dto.neighborhood,
                city: dto.city,
                state: dto.state
            }
        }
    },
}
