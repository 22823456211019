import { Drawer } from 'antd'
import styled from 'config/theme/styledWithTheme'
import React, { useEffect, useState } from 'react'
import { useScreenSize } from 'common/responsiveness/use-screen-size/UseScreenSize'
import { TitleCP } from 'common/components/title/TitleCP'
import { LoadingOverlayCP } from 'common/components/loaders/loading-overlay/LoadingOverlayCP'

type _AntPropsTP = {
    title: string | JSX.Element,
    visible?: boolean,
    footer?: JSX.Element,
    onClose?: () => void,
}

type _ScpPropsTP = {
    rightPadding?: number,
    titleUnderline?: boolean,
    footerSpaced?: boolean,
}

interface IDrawerCPProps extends React.PropsWithChildren<_ScpPropsTP & _AntPropsTP> {
    loading?: boolean
}

/**
 * COMPONENTE: Drawer generico.
 *
 * @author Stella
 * @author hjcostabr
 */
export function DrawerCP(props: IDrawerCPProps): JSX.Element {

    const [showDrawer, setShowDrawer] = useState<boolean>()
    const screenSize = useScreenSize()

    useEffect(handleOpen, [props.visible])

    function handleOpen(): void {
        setShowDrawer(!!props.visible)
    }

    function handleClose(): void {
        if (!!props.onClose)
            props.onClose()
        else
            setShowDrawer(false)
    }

    return (
        <WrapperSCP rightPadding={props.rightPadding}>
            <Drawer
                visible={showDrawer}
                placement={'right'}
                closable={true}
                destroyOnClose={true}
                onClose={handleClose}
                zIndex={10}
                width={screenSize.smd ? 'inherit' : 450}
                title={
                    <TitleWrapperSCP>
                        <TitleCP underLine={props.titleUnderline ?? true}>
                            {props.title}
                        </TitleCP>
                    </TitleWrapperSCP>
                }
            >
                <ContentWrapperSCP>
                    <LoadingOverlayCP show={props.loading ?? false}/>
                    { props.children }
                </ContentWrapperSCP>
                {
                    props.footer &&
                    <>
                        <FooterDividerSCP/>
                        <FooterSCP footerSpaced={props.footerSpaced}>
                            { props.footer }
                        </FooterSCP>
                    </>
                }
            </Drawer>
        </WrapperSCP>
    )
}

const WrapperSCP = styled.div<_ScpPropsTP>`

    .ant-drawer-content {
        height: 100vh;
    }
    .ant-drawer-wrapper-body {
        overflow: hidden !important;
        display: flex;
        flex-direction: column;
    }

    .ant-drawer-header {
        position: relative;
        padding: 16px 24px;
        color: rgba(1, 1, 2, 0.65);
        background: ${props => props.theme.white};
        border-bottom: none;
        border-radius: 4px 4px 0 0;
    }
    .ant-drawer-body {
        margin-right: ${props => (props.rightPadding ? props.rightPadding : 0)}px;
        overflow: hidden;
        height: 100%;
        padding: 0 24px;
    }
    .anticon{
        color: ${props => props.theme.black};
    }
`

const TitleWrapperSCP = styled.div`
    white-space: normal;
    overflow-wrap: normal;
    overflow: hidden;
    text-overflow: ellipsis;
`
const ContentWrapperSCP = styled.div`
    height: 100%;
    width: 100%;
    overflow-y: auto;
    overflow-x: hidden;
`

const FooterDividerSCP = styled.div`
    height: 30px;
`

const FooterSCP = styled.div < {footerSpaced?: boolean} > `
    display: flex;
    background: #fff;
    padding: 15px;
    position: absolute;
    right: 0;
    bottom: 0;
    width: 100%;
    justify-content: ${props => (props.footerSpaced ? 'space-between' : 'flex-end')};
`