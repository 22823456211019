/* eslint-disable no-console */
import React, { useEffect, useState } from 'react'
import { WizardCP } from 'common/components/wizard/WizardCP'
import { IWizardSteps } from 'common/components/wizard/inner/IWizardSteps'
import { PersonRegisterWizardUtils } from 'modules/person/components/person-register-wizard/inner/PersonRegisterWizardUtils'
import { SignatureSecurityLevelEnum } from 'modules/document-signature/enums/SignatureSecurityLevelEnum'
import { RequestUtils } from 'common/request-manager/RequestUtils'
import { useRequest } from 'common/request-manager/use-request/UseRequest'
import { IPersonResponseDTO } from 'modules/person/services/dtos/response/IPersonResponseDTO'
import { PersonRequests } from 'modules/person/services/PersonRequests'
import { NotificationHelper } from 'common/helpers/NotificationHelper'
import { AlertCP } from 'common/components/alert/AlertCP'
import { useFormStateManager } from 'common/form-state-manager/UseFormStateManager'
import { PersonFormModel } from 'modules/person/models/PersonFormModel'
import { PersonSaveRequestDTO } from 'modules/person/services/dtos/request/PersonSaveRequestDTO'
import { PersonFormUtils } from 'modules/person/utils/PersonFormUtils'
import { RequestHelper } from 'common/request-manager/RequestHelper'
import { IApiReturn } from 'common/interfaces/IApiReturn'

interface IPersonRegisterWizardCPProps {
    onDone: () => void
    signatureSecLevel: SignatureSecurityLevelEnum
    personCpf?: string
}

/**
 * COMPOENENT Passo a passo para criar novo usuário.
 */
export function PersonRegisterWizardCP(props: IPersonRegisterWizardCPProps): JSX.Element {

    const [personData, setPersonData] = useState<IPersonResponseDTO>()
    const [formModelValidator, setFormValidator] = useState<PersonFormModel>()
    const formStateManager = useFormStateManager<PersonFormModel>(formModelValidator)
    const getPersonRequest = useRequest<IPersonResponseDTO>()
    const [isFilesUploaded, setIsFilesUploaded] = useState<boolean>(false)

    useEffect(initialize, [props.personCpf])
    useEffect(onGetPersonRequestChange, [getPersonRequest.isAwaiting])

    /**
     * Inicializa a tela.
     */
    function initialize(): void {

        if (!props.personCpf)
            return

        getPersonRequest.runRequest(PersonRequests.getPersonByCpf(props.personCpf))
    }

    /**
     * Retorno da requisicao para pegar pessoa passada como parametro
     */
    function onGetPersonRequestChange(): void {

        if (!RequestUtils.isValidRequestReturn(getPersonRequest, 'Erro obter pessoa'))
            return

        const returnPerson = getPersonRequest.responseData!
        setPersonData(returnPerson)
        setFormValidator(new PersonFormModel(PersonFormUtils.getFormDataFromResponseDto(returnPerson)))
    }

    /**
     * Salva pessoa
     */
    async function savePerson(): Promise<boolean> {

        if (!personData)
            return false

        formStateManager.setConsiderAllErrors(true)
        if (!await formStateManager.validate())
            return false

        const formValues = formStateManager.getFormValues()
        if (!formValues)
            return false

        // Dados do Formulário
        const dto = new PersonSaveRequestDTO(formValues)

        // Dados para requisição
        const formatedDTO = PersonFormUtils.getFormDataToRequestDTO(dto)

        // Atualiza usuario
        const savedResult = await RequestHelper.runRequest<IApiReturn<IPersonResponseDTO>>(PersonRequests.getPersonUpdateConfig(personData.code, formatedDTO))

        const resultPerson = savedResult?.data.data

        if (resultPerson)
            return true

        NotificationHelper.error('Ops', 'Ocorreu algum erro ao salvar seus dados')
        return false
    }

    /**
     * Neste momento, será informado para o usuário que deu tudo certo
     * Os Steps de cadastro foram concluídos
     * Verifica se os uploads de arquivos foram realizados com sucesso
     * Avança para a assinatura
     */
    function onDone(): void {

        if (!isFilesUploaded) {
            NotificationHelper.error('Ops!', 'Envie todos as fotos antes de continuar!')
            return
        }

        NotificationHelper.success('Sucesso!', 'Cadastro completo realizado')
        props.onDone()
    }

    const steps: IWizardSteps[] = []
    steps.push(
        PersonRegisterWizardUtils.getPersonDataStep(formStateManager),
        PersonRegisterWizardUtils.getAddressDataStep(formStateManager, savePerson),
    )

    if (props.signatureSecLevel === SignatureSecurityLevelEnum.FULL_REGISTER)
        steps.push(PersonRegisterWizardUtils.getDocumentsDataStep(() => setIsFilesUploaded(true)))

    return (
        <>
            {
                props.signatureSecLevel === SignatureSecurityLevelEnum.FULL_REGISTER &&
                <AlertCP
                    message={'Foi solicitado aos signatários um nível maior de segurança, portanto pedidos que complete seu cadastro.'}
                    type={'info'}
                    marginBottom={30}
                />
            }

            <WizardCP
                loading={false}
                action={onDone}
                steps={steps}
            />
        </>
    )
}
