import React from 'react'
import styled from 'config/theme/styledWithTheme'
import { FlexCP } from 'common/components/flex/FlexCP'
import * as _ from 'lodash'
import { IDocumentAssigneeResponseDTO } from 'modules/document/services/dtos/response/IDocumentAssigneeResponseDTO'
import { AssigneeAvatarCP } from 'modules/document/components/assignee-avatar/AssigneeAvatarCP'

interface IMultiAssigneeAvatarCPProps {
    assignees: IDocumentAssigneeResponseDTO[]
}

/**
 * COMPONENTE avatares dos que signatarios.
 */
export function MultiAssigneeAvatarCP(props: IMultiAssigneeAvatarCPProps): JSX.Element {

    function getAssigneeAvatars(): JSX.Element[] {
        return props.assignees.map(assignee => (
            <AvatarSCP key={`assignee-${assignee.person.code}`}>
                <AssigneeAvatarCP
                    assignee={assignee}
                    showTooltip={true}
                />
            </AvatarSCP>
        ))
    }

    if (_.isEmpty(props.assignees))
        return <></>

    return (
        <FlexCP>
            { getAssigneeAvatars() }
        </FlexCP>
    )
}

const AvatarSCP = styled.div`
  margin-left: -10px;
`
