/**
 * Utils de Money
 *
 * @author guilherme.diniz
 */
export class MoneyUtils {

    /**
     * Converte uma string money para number
     * @param {string} numStr string
     * @return {number}
     */
    static convertToFloat(numStr: string): number {
        return +(`${numStr}`.replace('R$', '')
            .replace(/\./g, '')
            .replace(/,/g, '.'))
    }

    /**
      * Formata um numero ou string para money
      * @param value valor
      * @return {string}
      */
    static format(value: number | string): string {
        const regex = new RegExp('0[1-9][1-9]\\d+')
        let v = `${value}`
        v = v.replace(/\D/g, '') // permite digitar apenas numeros
        if (regex.test(v))
            v = v.substr(1)
        v = v.replace(/[0-9]{12}/, '') // limita pra maximo 999.999.999,99
        v = v.replace(/(\d)(\d{8})$/, '$1.$2') // coloca ponto
        v = v.replace(/(\d)(\d{5})$/, '$1.$2') // colocar ponto
        v = v.replace(/(\d)(\d{2})$/, '$1,$2') // coloca virgula antes dos ultimos 2 digitos

        return v
    }
}
