import React from 'react'
import { ButtonCP } from 'common/components/button/ButtonCP'
import { FallbackScreenContentCP } from 'common/components/fallback-screen-content/FallbackScreenContent'
import { RoutingHelper } from 'common/helpers/RoutingHelper'
import { HomeIconCP } from 'common/components/icons/HomeIconCP'
import { TextCP } from 'common/components/text/TextCP'
import { FlexCP } from 'common/components/flex/FlexCP'
import { DocumentRoutes } from 'modules/document/routers/DocumentRoutes'
import { DocumentStatusEnum } from 'modules/document/enums/DocumentStatusEnum'

interface IDocumentSignedOrDeclinedScreenContentICPProps {
    signed: DocumentStatusEnum
}

/**
 * Conteudo da tela que mostra informacoes quando ele assinar ou declinar um documento
 */
export function DocumentSignedOrDeclinedScreenContentICP(props: IDocumentSignedOrDeclinedScreenContentICPProps): JSX.Element {

    if (props.signed === DocumentStatusEnum.AWAITING_SIGNATURE)
        return <></>

    return (
        <FallbackScreenContentCP
            status={props.signed === DocumentStatusEnum.SIGNED ? 'success' : 'error'}
            title={
                props.signed === DocumentStatusEnum.SIGNED ?
                    'Assinatura realizada com sucesso'
                    :
                    'Você recusou o documento'
            }
            bottomContent={
                <FlexCP justifyContent={'center'}>
                    <ButtonCP
                        type={'primary'}
                        onClick={() => RoutingHelper.historyReplace(DocumentRoutes.ROOT)}
                    >
                        <HomeIconCP/>
                        <TextCP text={'Ir para meus documentos'} style={{ marginLeft: 4 }}/>
                    </ButtonCP>
                </FlexCP>
            }
        />
    )
}
