import { HttpMethodEnum } from 'common/enums/HttpMethodEnum'
import { RequestConfigTP } from 'common/request-manager/types/RequestConfigTP'
import { SystemConfig } from 'config/SystemConfig'
import { DocumentTypeEnum } from 'modules/document/enums/DocumentTypeEnum'
import { ICreateDocumentRequestDTO } from 'modules/document/services/dtos/request/ICreateDocumentRequestDTO'
import { IPersonIpRequestDTO } from 'modules/person/services/dtos/request/IPersonIpRequestDTO'

/**
 * REQUISICOES relacionadas ao modulo de Documentos
 */
export class DocumentRequests {

    private static readonly _MODULE_BASE = `${SystemConfig.getInstance().apiBaseUrl}/documents`

    static searchConfig = (): RequestConfigTP => ({
        url: DocumentRequests._MODULE_BASE,
        method: HttpMethodEnum.GET,
    })

    static getDocumentConfig = (code: number): RequestConfigTP => ({
        url: `${DocumentRequests._MODULE_BASE}/${code}`,
        method: HttpMethodEnum.GET,
    })

    static getDocumentBySignTokenConfig = (signToken: string): RequestConfigTP => ({
        url: `${DocumentRequests._MODULE_BASE}/by-sign-token/${signToken}`,
        method: HttpMethodEnum.GET,
    })

    static getDocumentByCertificateTokenConfig = (certificateToken: string): RequestConfigTP => ({
        url: `${DocumentRequests._MODULE_BASE}/by-certificate-token/${certificateToken}`,
        method: HttpMethodEnum.GET,
    })

    static getSignDocumentConfig = (documentCode: number, dto: IPersonIpRequestDTO): RequestConfigTP => ({
        url: `${DocumentRequests._MODULE_BASE}/${documentCode}/sign`,
        method: HttpMethodEnum.PUT,
        params: dto
    })

    static getDeclineDocumentConfig = (documentCode: number, dto: IPersonIpRequestDTO): RequestConfigTP => ({
        url: `${DocumentRequests._MODULE_BASE}/${documentCode}/decline`,
        method: HttpMethodEnum.PUT,
        params: dto
    })

    static createDocumentConfig = (type: DocumentTypeEnum, file: File, dto: ICreateDocumentRequestDTO): RequestConfigTP => {

        const formData = new FormData()
        formData.append('file', file)
        formData.append('data', JSON.stringify(dto))

        return ({
            url: `${DocumentRequests._MODULE_BASE}/${type}`,
            method: HttpMethodEnum.POST,
            params: formData
        })
    }

}
