import * as React from 'react'
import styled from 'config/theme/styledWithTheme'

interface IScpProps {
    alignItems?: 'center' | 'flex-end' | 'space-between'
    justifyContent?: 'center' | 'flex-end' | 'space-between'
    marginRight?: number
    marginLeft?: number
    marginTop?: number
    marginBottom?: number
}

interface IFlexCPProps extends IScpProps{
    children: JSX.Element[] | JSX.Element
}

/**
 * COMPONENTE
 * Para fazer um displex flex
 */
export function FlexCP(props: IFlexCPProps): JSX.Element {
    return (
        <FilterWrapperSCP
            alignItems={props.alignItems}
            justifyContent={props.justifyContent}
            marginTop={props.marginTop ?? 0}
            marginRight={props.marginRight ?? 0}
            marginBottom={props.marginBottom ?? 0}
            marginLeft={props.marginLeft ?? 0}
        >
            {props.children}
        </FilterWrapperSCP>
    )
}

const FilterWrapperSCP = styled.div<IScpProps>`
    display: flex;
    align-items: ${props => props.alignItems ?? ''};
    justify-content: ${props => props.justifyContent ?? ''};
    margin: ${props => `${props.marginTop!}px ${props.marginRight!}px ${props.marginBottom!}px ${props.marginLeft!}px`};
`
