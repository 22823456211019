import * as styledComponents from 'styled-components'
import { ThemedStyledComponentsModule } from 'styled-components'
import { ITheme } from 'config/theme/ITheme'

/**
 * Wrapper do styled components para que o theme esteja tipado
 * @author Lucas Rosa
 */
const {
    default: styled,
    css,
    keyframes,
    ThemeProvider,
    withTheme,
    ThemeContext
} = (styledComponents as any as ThemedStyledComponentsModule<ITheme>)

export { css, keyframes, ThemeProvider, styled, withTheme, ThemeContext }
export default styled
