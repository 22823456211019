import React from 'react'
import { Icon } from 'antd'

import { IIconProps } from 'common/components/icons/inner/IIconProps'

/**
 * ICONE
 * Editar.
 *
 * @author hjcostabr
 */
export function EditIconCP(props: IIconProps): JSX.Element {
    return (
        <Icon
            type={'edit'}
            style={{
                color: props.color ?? 'inherit',
                fontSize: props.size,
                ...props.style
            }}
        />
    )
}
