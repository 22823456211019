import React from 'react'
import { Row } from 'antd'
import { styled } from 'config/theme/styledWithTheme'

interface IRowCPProps extends React.PropsWithChildren<{
    flex?: boolean,
    justify?: 'space-around' | 'space-between' | 'center' | 'end' | 'start',
    align?: 'stretch' | 'bottom' | 'top' | 'middle',
    spaceBetweenCols?: number,
}> {}

/**
 * COMPONENTE
 * Linha para alinhamento de GRID.
 */
export const RowCP = (props: IRowCPProps): JSX.Element => {

    const align = props.align ?? 'middle'

    return (
        <WrapperSCP isFlexCentered={!!props.flex && align === 'middle'}>
            <Row
                gutter={props.spaceBetweenCols}
                type={props.flex ? 'flex' : undefined}
                justify={props.justify ?? 'center'}
                align={align}
            >
                {props.children}
            </Row>
        </WrapperSCP>
    )
}

const WrapperSCP = styled.div<{ isFlexCentered: boolean }>`

    padding: 0 8px;

    .ant-row-flex > .ant-col {
        ${props => (props.isFlexCentered ? 'display: flex;' : '')}
        ${props => (props.isFlexCentered ? 'align-items: center;' : '')}
    }
`
